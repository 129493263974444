import React from "react";
import "./copyright.css";

function Copyright() {
  const currentYear = new Date().getFullYear();
  
  return (
    <div className="copyrightContainer">
      <div className="copyrightInner">
        {currentYear} &#169;&nbsp;<span className="copyrightColored">NoBody Club </span>
        &nbsp;tüm hakları saklıdır.
      </div>
    </div>
  );
}

export default Copyright;