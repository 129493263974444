import FooterPanel from "../../components/footerPanel.js";
import LandingPageDontDo from "./components/landingpagedontdo.js";
import LandingPageHeader from "./components/landingpageheader.js";
import LandingPageLast from "./components/landingpagelast.js";
import LandingPageMember from "./components/landingpagemember.js";
import LandgingPageNavbar from "./components/landingpagenavbar.js";
import LandingPageProgram from "./components/landingpageprogram.js";
import LandingPageProgramTitle from "./components/landingpageprogramtitle.js";
import LandingPageProperty from "./components/landingpageproperty.js";
import LandingPageSSS from "./components/landingpagesss.js";
import LandingPageStudent from "./components/landingpagestudent.js";
import LandingPageVideo from "./components/landingpagevideo.js";
import LandingPageWhatHave from "./components/landingpagewhathave.js";

function LandingPage() {
  return (
    <>
      <LandgingPageNavbar />
      <LandingPageHeader />
      <LandingPageWhatHave />
      <LandingPageProgramTitle />
      <LandingPageProgram />
      <LandingPageProperty />
      <LandingPageStudent />
      <LandingPageVideo />
      <LandingPageLast />
      <LandingPageMember />
      <LandingPageDontDo />
      <LandingPageSSS />
      <FooterPanel />
    </>
  );
}

export default LandingPage;
