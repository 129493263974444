import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Contact from "./components/contact";
import WelcomeNavbar from "../welcome/components/welcomeNavbar";
import LandingPageNavbar from "../landingpage/components/landingpagenavbar";
import config from "../../config/config";
import Footer from "../../components/footer";
import Copyright from "../../components/copyright";

function ContactPage() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const userData = Cookies.get("userData");

        if (!userData) {
          setIsAuthenticated(false);
          return;
        }

        const parsedData = JSON.parse(userData);

        const response = await fetch(`${config.API_URL}session`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            stringsession: parsedData.stringsession,
            username: parsedData.username,
          }),
        });

        const result = await response.json();

        if (result.status === "True") {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (err) {
        console.error("Fetch error:", err);
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, [navigate]);

  return (
    <>
      {isAuthenticated ? <WelcomeNavbar /> : <LandingPageNavbar />}
      <Contact />
      <Footer />
      <Copyright />
    </>
  );
}

export default ContactPage;
