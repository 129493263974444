import React from "react";
import "../styles/welcomeSection.css";
import { useNavigate } from "react-router-dom";

function WelcomeSection() {
  const navigate = useNavigate();

  const goToTutorials = () => {
    navigate("/tutorials");
  };
  return (
    <div className="welcomeSectionContainer">
      <div className="welcomeSectionInner">
        <h1 className="welcomeHeader">
          NOBODY CLUB'A <span className="welcomeHeaderColored">HOŞ GELDİN</span>
        </h1>
        <h4 className="welcomeSubtitle">
          NoBody Club sizin zorlu hayat mücadelenizde ve iş yaşamınızda doğru
          yöntemleri kullanarak başarıya ulaşmanızı sağlayacak bir gelişim
          kulübüdür.
        </h4>
        <p className="welcomeDescription">
          Sürekli olarak gelişecek ve Türkiye’nin en gerçek bilgilerinin size
          aktarıldığı topluluğumuzun tadını çıkarın!
        </p>
        <div className="welcomeButtonArea">
          <button className="welcomeButton" onClick={goToTutorials}>
            Değişime Başla
          </button>
        </div>
      </div>
    </div>
  );
}

export default WelcomeSection;
