import React, { useState } from "react";
import "../styles/forgotpasswordnewpassword.css";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../config/config";
function ForgotPasswordNewPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const email = location.state?.email;

  const goToLogin = () => {
    navigate("/login");
  };

  const handleResetPassword = async () => {
    if (!password || !confirmPassword) {
      toast.error("Lütfen şifreleri giriniz.", {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Şifreler eşleşmiyor!", {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }

    try {
      const response = await fetch(`${config.API_URL}forgotpassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (data.status === "True") {
        toast.success(data.message, {
          position: "top-right",
          autoClose: 5000,
        });
        navigate("/login");
      } else {
        toast.error(data.error || data.message, {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      toast.error("Bir hata oluştu", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleResetPassword();
    }
  };

  return (
    <div className="newPasswordContent">
      <ToastContainer />
      <div className="newPasswordBox">
        <div className="newPasswordLeft">
          <div className="newPasswordForm">
            <div className="newPasswordFormArea">
              <div className="newPasswordUsernameArea fade-in-left-500">
                <div className="newPasswordUsername">Yeni Şifre</div>
                <input
                  className="newPasswordUsernameInput"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleKeyDown}
                  autoFocus
                />

                <input
                  className="newPasswordUsernameInput"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </div>

              <div className="newPasswordButtonArea fade-in-left-1000">
                <input
                  type="button"
                  className="newPasswordButton"
                  value="Devam Et"
                  onClick={handleResetPassword}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordNewPassword;
