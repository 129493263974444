import React, { useState } from "react";
import "../styles/forgotpasswordcode.css";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../config/config";

function ForgotPasswordCode() {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState("");

  const email = location.state?.email;

  const handleChange = (e) => {
    const newValue = e.target.value;
    const regex = /^[0-9]*$/;

    if (regex.test(newValue)) {
      setValue(newValue);
    }
  };

  const handleVerifyCode = async () => {
    if (!value) {
      toast.error("Lütfen doğrulama kodunu giriniz.", {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }

    try {
      const response = await fetch(`${config.API_URL}forgotpassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, code: parseInt(value) }),
      });

      const data = await response.json();

      if (data.status === "True") {
        toast.success(data.message, {
          position: "top-right",
          autoClose: 5000,
        });
        navigate("/forgot-password-newpassword", { state: { email } });
      } else {
        toast.error(data.error || data.message, {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      toast.error("Bir hata oluştu", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleVerifyCode();
    }
  };

  return (
    <div className="forgotPasswordContent">
      <ToastContainer />
      <div className="forgotPasswordBox">
        <div className="forgotPasswordLeft">
          <div className="forgotPasswordForm">
            <div className="forgotPasswordFormArea">
              <div className="forgotPasswordUsernameArea fade-in-left-500">
                <div className="forgotPasswordUsername">
                  6 haneli doğrulama kodu
                </div>
                <input
                  className="forgotPasswordUsernameInput"
                  type="text"
                  maxLength={6}
                  value={value}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  autoFocus
                />
              </div>

              <div className="forgotPasswordButtonArea fade-in-left-1000">
                <input
                  type="button"
                  className="forgotPasswordButton"
                  value="Devam Et"
                  onClick={handleVerifyCode}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordCode;
