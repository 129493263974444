import React from "react";
import TutorialDetail from "./components/tutorialDetail";
import WelcomeNavbar from "../welcome/components/welcomeNavbar";
import FooterPanel from "../../components/footerPanel";
import Copyright from "../../components/copyright";

function TutorialDetailPage() {
  return (
    <>
      <WelcomeNavbar />
      <TutorialDetail />
      <FooterPanel />
      <Copyright />
    </>
  );
}

export default TutorialDetailPage;
