import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import Adminaside from "./adminaside";
import "../styles/egitimsetiniduzenle.css";
import Cookies from "js-cookie";
import config from "../../../config/config";
import Loader from "../../../components/loader";

function EgitimSetiniDuzenle() {
  const { slug, title } = useParams();
  const location = useLocation();
  const { tutorial } = location.state;
  const [loading, setLoading] = useState(true);

  const [formState, setFormState] = useState({
    eduname: tutorial.eduname,
    description: tutorial.description,
  });

  const [firstPhotoPreview, setFirstPhotoPreview] = useState(null);
  const [secondPhotoPreview, setSecondPhotoPreview] = useState(null);
  const [firstPhotoFile, setFirstPhotoFile] = useState(null);
  const [secondPhotoFile, setSecondPhotoFile] = useState(null);

  useEffect(() => {
    if (tutorial) {
      setFirstPhotoPreview(`${config.API_URL}uploads/${tutorial.firstphoto}`);
      setSecondPhotoPreview(`${config.API_URL}uploads/${tutorial.secondphoto}`);
      setLoading(false);
    }
  }, [tutorial]);

  useEffect(() => {
    // Set the document title dynamically
    document.title = `${config.PRODUCT_NAME} | Eğitim Setini Düzenle`;
  }, [title]);

  const handleDrop = (event, setPreview, setFile) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
        setFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileChange = (event, setPreview, setFile) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
        setFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdate = async () => {
    const formData = new FormData();
    const dataa = Cookies.get("userData");
    const ckdata = dataa ? JSON.parse(dataa) : {};

    const sessionToken = ckdata.stringsession;
    const username = ckdata.username;

    formData.append("stringsession", sessionToken);
    formData.append("username", username);
    formData.append("eduid", tutorial.eduid);

    if (formState.eduname !== tutorial.eduname) {
      formData.append("eduname", formState.eduname);
    }
    if (formState.description !== tutorial.description) {
      formData.append("description", formState.description);
    }
    if (firstPhotoFile) {
      formData.append("firstphoto", firstPhotoFile);
    }
    if (secondPhotoFile) {
      formData.append("secondphoto", secondPhotoFile);
    }

    try {
      const response = await fetch(`${config.API_URL}updateedu`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      console.log(data);

      if (data.status === "True") {
        alert("Eğitim başarıyla güncellendi!");
      } else {
        alert(`Hata: ${data.error}`);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };
  if (loading) {
    return <Loader />;
  }

  if (!tutorial) {
    return (
      <div
        style={{
          width: "100%",
          backgroundColor: "#0c0c0c",
          height: "100vh",
          color: "#fffaf0",
          fontSize: 50,
          fontWeight: "500",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Eğitim Bulunamadı
      </div>
    );
  }

  return (
    <div className="adminContainer">
      <Adminaside />
      <div className="egitimolusturContainer">
        <div className="egitimolusturInner">
          <h1 className="egitimolusturHeader">Eğitim Setini Düzenle</h1>
          <div className="formTextInputs">
            <div className="formGroup">
              <h1>Eğitim Başlığı</h1>
              <input
                type="text"
                id="eduname"
                name="eduname"
                value={formState.eduname}
                onChange={handleInputChange}
              />
            </div>
            <div className="formGroup">
              <h2>Eğitim Açıklaması</h2>
              <input
                type="text"
                id="description"
                name="description"
                value={formState.description}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="formFileInputs">
            <div className="formGroup">
              <h3>Eğitim Küçük Resmi</h3>
              <div
                className="dropZone"
                onDrop={(e) =>
                  handleDrop(e, setFirstPhotoPreview, setFirstPhotoFile)
                }
                onDragOver={(e) => e.preventDefault()}
              >
                {firstPhotoPreview ? (
                  <img
                    src={firstPhotoPreview}
                    alt="First Photo"
                    className="uploadedImage"
                  />
                ) : (
                  <p>Sürükle & Bırak veya Dosya Seçmek İçin Tıkla</p>
                )}
                <input
                  type="file"
                  id="firstphoto"
                  name="firstphoto"
                  accept="image/*"
                  onChange={(e) =>
                    handleFileChange(e, setFirstPhotoPreview, setFirstPhotoFile)
                  }
                />
              </div>
            </div>
            <div className="formGroup">
              <h4>Eğitim Banner Resmi</h4>
              <div
                className="dropZone"
                onDrop={(e) =>
                  handleDrop(e, setSecondPhotoPreview, setSecondPhotoFile)
                }
                onDragOver={(e) => e.preventDefault()}
              >
                {secondPhotoPreview ? (
                  <img
                    src={secondPhotoPreview}
                    alt="Second Photo"
                    className="uploadedImage"
                  />
                ) : (
                  <p>Sürükle & Bırak veya Dosya Seçmek İçin Tıkla</p>
                )}
                <input
                  type="file"
                  id="secondphoto"
                  name="secondphoto"
                  accept="image/*"
                  onChange={(e) =>
                    handleFileChange(
                      e,
                      setSecondPhotoPreview,
                      setSecondPhotoFile
                    )
                  }
                />
              </div>
            </div>
          </div>
          <button className="adminUploadButton" onClick={handleUpdate}>
            Eğitimi Güncelle
          </button>
        </div>
      </div>
    </div>
  );
}

export default EgitimSetiniDuzenle;
