import React from "react";
import NotFound from "./components/notfound";
import WelcomeNavbar from "../welcome/components/welcomeNavbar";
import Copyright from "../../components/copyright";
import FooterPanel from "../../components/footerPanel";

function NotFoundPage() {
  return (
    <>
      <WelcomeNavbar />
      <NotFound />
      <FooterPanel />
      <Copyright />
    </>
  );
}

export default NotFoundPage;
