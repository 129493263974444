import React from "react";
import WelcomeNavbar from "../welcome/components/welcomeNavbar";
import FooterPanel from "../../components/footerPanel";
import Copyright from "../../components/copyright";
import Admin from "./components/admin";

function AdminPage() {
  return (
    <>
      <WelcomeNavbar />
      <Admin />
      <FooterPanel />
      <Copyright />
    </>
  );
}

export default AdminPage;
