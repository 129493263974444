import React, { useState } from "react";
import "./dropdown.css";

const countries = ["admin", "user"];

function CountryDropdown({ value, onChange }) {
  return (
    <div className="dropdownContainer">
      <select
        id="country"
        value={value}
        onChange={onChange}
        className="dropdownArea"
        required
      >
        <option value="">Rol Seçiniz</option>
        {countries.map((country) => (
          <option key={country} value={country}>
            {country}
          </option>
        ))}
      </select>
    </div>
  );
}

export default CountryDropdown;