import React, { useState, useEffect } from "react";
import "../styles/profile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import config from "../../../config/config";

const FileUpload = ({ onFileSelect }) => {
  const handleFileChange = (e) => {
    onFileSelect(e.target.files[0]);
  };

  return (
    <input
      type="file"
      id="fileInput"
      accept="image/*"
      onChange={handleFileChange}
      style={{ display: "none" }}
    />
  );
};

function Profile() {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [formData, setFormData] = useState({});
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const data = Cookies.get("userData");
    const ckdata = data ? JSON.parse(data) : {};
    setFormData(ckdata);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileSelect = (file) => {
    setUploadedFile(URL.createObjectURL(file));
  };

  const handlePenClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleSave = async () => {
    const data = Cookies.get("userData");
    const ckdata = data ? JSON.parse(data) : {};

    let updatedFields = {};
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== ckdata[key]) {
        updatedFields[key] = formData[key];
      }
    });

    if (oldPassword || newPassword || confirmNewPassword) {
      if (oldPassword && newPassword && newPassword === confirmNewPassword) {
        const response = await fetch(`${config.API_URL}passwordcheck`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: ckdata.username,
            password: oldPassword,
          }),
        });

        const result = await response.json();
        if (result.status === "True") {
          updatedFields.newpassword = newPassword;
        } else {
          alert("Eski şifre yanlış.");
          return;
        }
      } else {
        alert("Yeni şifreleri doğrulayın ve alanları tekrar doldurun.");
        return;
      }
    }

    updatedFields.username = ckdata.username;
    updatedFields.stringsession = ckdata.stringsession;

    const response = await fetch(`${config.API_URL}updateprofile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedFields),
    });

    const result = await response.json();
    if (result.status === "True") {
      alert("Kullanıcı bilgileri güncellendi");

      // Güncellenen verileri cookie'de yenile
      const updatedCookieData = { ...ckdata, ...updatedFields };
      Cookies.set("userData", JSON.stringify(updatedCookieData), {
        expires: 7,
      });

      // Sayfayı yenile
      window.location.reload();
    } else {
      alert("Güncelleme hatası: " + result.error);
    }
  };
  return (
    <div className="profileContainer">
      <div className="profileInner">
        <div className="profileLeft">
          <div className="profileImage">
            {uploadedFile ? (
              <img
                src={uploadedFile}
                alt="Yüklenen Profil Fotoğrafı"
                className="profileImg"
              />
            ) : (
              <img
                className="profileImg"
                alt="Profil Fotoğrafı"
                src={`${process.env.PUBLIC_URL}/assets/NoBodyClub/nobodyclubsquare.jpg`}
              />
            )}
            {/*<FontAwesomeIcon
              icon={faPen}
              className="profileIcon"
              onClick={handlePenClick}
            />
            <FileUpload onFileSelect={handleFileSelect} />*/}
          </div>

          <div className="profileSoz">
            Şu anda <span className="profileSozColored">milyon dolarlık </span>
            bir iş insanının profilindesin!
          </div>

          <div className="profileContact">
            <div className="profileContactTitleArea">
              <h2 className="profileContactTitle">Kullanıcı Bilgisi</h2>
            </div>
            <div className="profileContactDetails">
              <div className="profileContactDetailsLine first">
                <div className="profileName">
                  <div className="profileNameHeader">İsim</div>
                  <input
                    type="text"
                    className="profileNameInput"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="profileSurname">
                  <div className="profileSurnameHeader">Soyisim</div>
                  <input
                    type="text"
                    className="profileSurnameInput"
                    name="surname"
                    value={formData.surname}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="profileAgency">
                  <div className="profileSurnameHeader">Kullanıcı Adı</div>
                  <input
                    type="text"
                    className="profileSurnameInput"
                    name="newusername"
                    value={formData.username}
                    readOnly
                  />
                </div>
              </div>
              <div className="profileContactDetailsLine">
                <div className="profileName">
                  <div className="profileNameHeader">Telefon Numarası</div>
                  <input
                    type="phone"
                    className="profileNameInput"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="profileSurname">
                  <div className="profileSurnameHeader">E-Mail</div>
                  <input
                    type="email"
                    className="profileSurnameInput"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="profileAgency">
                  <div className="profileDisabledBox"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="profileAlt">
          <div className="profileContact adress">
            <div className="profileContactTitleArea">
              <h2 className="profileContactTitle">Adres Bilgisi</h2>
            </div>
            <div className="profileContactDetails adres">
              <div className="profileContactDetailsLine first">
                <div className="profileName">
                  <div className="profileNameHeader">Ülke</div>
                  <input
                    type="text"
                    className="profileSurnameInput"
                    name="ulke"
                    value={formData.ulke}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="profileSurname">
                  <div className="profileSurnameHeader">İl</div>
                  <input
                    type="text"
                    className="profileSurnameInput"
                    name="il"
                    value={formData.il}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="profileAgency">
                  <div className="profileNameHeader">İlçe</div>
                  <input
                    type="text"
                    className="profileNameInput"
                    name="ilce"
                    value={formData.ilce}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="profileContactDetailsLine two">
                <div className="profileName">
                  <div className="profileNameHeader">Açık Adres</div>
                  <input
                    type="text"
                    className="profileNameInput"
                    name="sokak"
                    value={formData.sokak}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="profileSurname">
                  <div className="profileSurnameHeader">Posta Kodu</div>
                  <input
                    type="text"
                    className="profileSurnameInput"
                    name="postakodu"
                    value={formData.postakodu}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="profileAgency">
                  <div className="profileDisabledBox"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="profilePassword">
          <div className="profileContact password">
            <div className="profileContactTitleArea">
              <h2 className="profileContactTitle">Şifre Değiştir</h2>
            </div>
            <div className="profileContactDetails password">
              <div className="profileContactDetailsLine first">
                <div className="profileName">
                  <div className="profileNameHeader">Eski Şifre</div>
                  <input
                    type={showOldPassword ? "text" : "password"}
                    className="profileNameInput"
                    name="oldpassword"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                  <FontAwesomeIcon
                    icon={showOldPassword ? faEyeSlash : faEye}
                    className="passwordIcon"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                  />
                </div>
                <div className="profileSurname">
                  <div className="profileSurnameHeader">Yeni Şifre</div>
                  <input
                    type={showNewPassword ? "text" : "password"}
                    className="profileSurnameInput"
                    name="newpassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <FontAwesomeIcon
                    icon={showNewPassword ? faEyeSlash : faEye}
                    className="passwordIcon"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  />
                </div>
                <div className="profileAgency">
                  <div className="profileNameHeader">Yeni Şifre Tekrar</div>
                  <input
                    type={showConfirmNewPassword ? "text" : "password"}
                    className="profileNameInput"
                    name="confirmnewpassword"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                  />
                  <FontAwesomeIcon
                    icon={showConfirmNewPassword ? faEyeSlash : faEye}
                    className="passwordIcon"
                    onClick={() =>
                      setShowConfirmNewPassword(!showConfirmNewPassword)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="profileButtonArea">
          <button className="profileButton" onClick={handleSave}>
            Değişiklikleri Kaydet
          </button>
        </div>
      </div>
    </div>
  );
}

export default Profile;
