import React from "react";
import "../styles/landingpagemember.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function LandingPageMember() {
  const navigate = useNavigate();

  const goToRegister = () => {
    navigate("/register");
  };

  return (
    <div className="landingPageMemberContainer">
      <div className="landingPageMemberInner">
        <div className="landingPageMemberBox">
          <div className="landingPageMemberTitle">
            <span className="landingPageMemberTitleColored">NBC</span> Üyelik
          </div>
          <div className="landingPageMemberContent">
            <div className="landingPageMemberLine">
              <FontAwesomeIcon
                icon={faCheck}
                className="landingPageMemberIcon"
              />
              <p className="landingPageMemberText">
                Basit ve anlaşılabilir adım adım eğitimler
              </p>
            </div>
            <div className="landingPageMemberLine">
              <FontAwesomeIcon
                icon={faCheck}
                className="landingPageMemberIcon"
              />
              <p className="landingPageMemberText">
                Milyoner mentorlara erişim
              </p>
            </div>
            <div className="landingPageMemberLine">
              <FontAwesomeIcon
                icon={faCheck}
                className="landingPageMemberIcon"
              />
              <p className="landingPageMemberText">Topluluk sohbet grupları</p>
            </div>
            <div className="landingPageMemberLine">
              <FontAwesomeIcon
                icon={faCheck}
                className="landingPageMemberIcon"
              />
              <p className="landingPageMemberText">100 Adet kitap özeti</p>
            </div>
            <div className="landingPageMemberLine">
              <FontAwesomeIcon
                icon={faCheck}
                className="landingPageMemberIcon"
              />
              <p className="landingPageMemberText">
                Onlarca döküman ve çalışma dosyası
              </p>
            </div>
            <div className="landingPageMemberLine">
              <FontAwesomeIcon
                icon={faCheck}
                className="landingPageMemberIcon"
              />
              <p className="landingPageMemberText">7/24 Soru - Cevap</p>
            </div>
            <div className="landingPageMemberLine">
              <FontAwesomeIcon
                icon={faCheck}
                className="landingPageMemberIcon"
              />
              <p className="landingPageMemberText">1:1 Koçluk görüşmeleri</p>
            </div>
            <div className="landingPageMemberLine">
              <FontAwesomeIcon
                icon={faCheck}
                className="landingPageMemberIcon"
              />
              <p className="landingPageMemberText">
                Ekstra bilgi veya deneyim gerektirmez
              </p>
            </div>
            <div className="landingPageMemberLine">
              <FontAwesomeIcon
                icon={faCheck}
                className="landingPageMemberIcon"
              />
              <p className="landingPageMemberText">
                Gelecekteki tüm eğitimlere erişme imkanı
              </p>
            </div>
          </div>
          <p className="landingPageMemberErisim">1 Yıllık Erişim İmkanı</p>
          <p className="landingPageMemberPrice">
            <del>400$</del>
            <span className="landingPageMemberPriceColored"> / 97$</span>
          </p>
          <button className="landingPageMemberButton" onClick={goToRegister}>
            Kazananların Arasına Katıl{" "}
            <FontAwesomeIcon
              icon={faChevronRight}
              className="landingPageMemberButtonIcon"
            />
          </button>
          <p className="landingPageMemberFiyatArtisi">
            Fiyat artışından önce aramıza katıl. Acele et.
          </p>
        </div>
      </div>
    </div>
  );
}

export default LandingPageMember;
