import React from "react";
import Register from "./components/register";
import LandgingPageNavbar from "../landingpage/components/landingpagenavbar";

function RegisterPage() {
  return (
    <>
      <LandgingPageNavbar />
      <Register />
    </>
  );
}

export default RegisterPage;
