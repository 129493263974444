import React from "react";
import "../styles/welcomeHeader.css";
import WelcomeSection from "./welcomeSection";
import WelcomeContact from "./welcomeContact";

function WelcomeHeader() {
  return (
    <>
      <WelcomeSection />
      <WelcomeContact />
    </>
  );
}

export default WelcomeHeader;
