import React from "react";
import "./footerPanel.css";
import { useNavigate, useLocation } from "react-router-dom";

function FooterPanel() {
  const navigate = useNavigate();
  const location = useLocation();

  const goToHome = () => {
    if (location.pathname === "/") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      navigate("/");
    }
  };
  const goToTelegram = () => {
    window.open("https://t.me/+JosrDJCTgf5jODE0", "_blank");
  };

  const gotToInstagram = () => {
    window.open("https://www.instagram.com/thesametdogan/", "_blank");
  };

  const handleSendEmail = () => {
    const email = "support@nobodyclub.com";
    const subject = "";
    const body = "";

    window.location.href = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  };

  return (
    <footer className="footerPanelContainer">
      <div className="footerPanelInner">
        <div className="footerPanelInfo footerPanelArea">
          <ul className="footerPanelInfoList">
            <li className="footerPanelInfoListItem" onClick={handleSendEmail}>
              support@nobodyclub.com
            </li>
            <li className="footerPanelInfoListItem" onClick={gotToInstagram}>
              Instagram
            </li>

            <li className="footerPanelInfoListItem" onClick={goToTelegram}>
              Telegram Kanalı
            </li>
            <li className="footerPanelInfoListItem">Whatsapp Destek Hattı</li>
          </ul>
        </div>
        <div className="footerPanelLogo footerPanelArea">
          <img
            src={`${process.env.PUBLIC_URL}/assets/NoBodyClub/nobodyclub.png`}
            alt="NoBody Club Logo"
            className="footerPanelImg"
            onClick={goToHome}
          />
          <div className="footerPanelSubtitle">
            Bu websitesi&nbsp;
            <span className="footerPanelBerat">
              <a
                href="https://theberathan.com"
                className="footerPanelBerat"
                target="_blank"
                rel="noreferrer"
              >
                theberathan&nbsp;
              </a>
            </span>
            tarafından tasarlanmıştır.
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterPanel;
