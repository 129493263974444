import React, { useState, useRef, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import "../styles/tutorialDetail.css";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import Cookies from "js-cookie";
import { slugify } from "../../../utils/slugify";
import config from "../../../config/config";

function TutorialDetail() {
  const { title } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const originalTitle =
    location.state?.originalTitle || title.replace(/-/g, " ");
  const secondphoto = location.state?.secondphoto;
  const eduid = location.state?.eduid;
  const description = location.state?.description || "";

  const [cookiedata, setcookiedata] = useState("");
  const [allComments, setAllComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [isContentAccordionOpen, setIsContentAccordionOpen] = useState(true);
  const [isCommentsAccordionOpen, setIsCommentsAccordionOpen] = useState(true);
  const [activePopupIndex, setActivePopupIndex] = useState(null);
  const [videodata, setvideodata] = useState([]);
  const [categoriesOpen, setCategoriesOpen] = useState({
    categoryOne: false,
    categoryTwo: false,
    categoryThree: false,
  });

  const toggleCategory = (category) => {
    setCategoriesOpen((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  useEffect(() => {
    document.title = `${config.PRODUCT_NAME} | ${originalTitle}`;
  }, [originalTitle]);

  const deletecomment = async (commentid) => {
    const response = await fetch(`${config.API_URL}deleteeducomment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stringsession: cookiedata.stringsession,
        username: cookiedata.username,
        eduid: eduid,
        commentid: commentid,
      }),
    });

    const result = await response.json();
    if (result.status === "True") {
      setAllComments(
        allComments.filter((comment) => comment.commentid !== commentid)
      );
      window.location.reload();
    } else {
      alert(result.error);
    }
  };

  const addComment = async () => {
    const response = await fetch(`${config.API_URL}commentedu`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stringsession: cookiedata.stringsession,
        username: cookiedata.username,
        eduid: eduid,
        comment: newComment,
      }),
    });

    const result = await response.json();
    if (result.status === "True") {
      window.location.reload();
    } else {
      alert(result.error);
    }
  };

  useEffect(() => {
    const dataa = Cookies.get("userData");
    const ckdata = JSON.parse(dataa);
    setcookiedata(ckdata);

    const fetchData = async () => {
      const dataa = Cookies.get("userData");
      const ckdata = dataa ? JSON.parse(dataa) : {};

      const sessionToken = ckdata.stringsession;
      const username = ckdata.username;
      if (!sessionToken || !username) {
        alert("Kullanıcı bilgileri bulunamadı. Lütfen tekrar giriş yapın.");
        return;
      }

      const response = await fetch(`${config.API_URL}listedu`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          stringsession: sessionToken,
          username: username,
        }),
      });

      const data = await response.json();
      console.log(data)

      if (data.status === "True") {
        const alldta = data.data.filter((comment) => comment.eduid == eduid);
        if (alldta.length > 0) {
          setvideodata(
            Array.isArray(alldta[0].videodata) ? alldta[0].videodata : []
          );
          setAllComments(
            Array.isArray(alldta[0].educomments) ? alldta[0].educomments : []
          );
        }
      } else {
        alert(`Hata: ${data.error}`);
      }
    };
    fetchData();
  }, [location]);

  const toggleContentAccordion = () => {
    setIsContentAccordionOpen(!isContentAccordionOpen);
  };

  const toggleCommentsAccordion = () => {
    setIsCommentsAccordionOpen(!isCommentsAccordionOpen);
  };

  const togglePopup = (index) => {
    setActivePopupIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const textareaRef = useRef(null);

  const handleInput = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "30px"; // Reset to initial height
    if (textarea.scrollHeight > 30) {
      textarea.style.height = textarea.scrollHeight + "px"; // Adjust height based on content
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        !event.target.closest(".popup") &&
        !event.target.closest(".commentAltGroupIcon")
      ) {
        setActivePopupIndex(null);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const startTutorial = () => {
    if (videodata.length > 0) {
      const firstVideo = videodata[0];
      const videoName = slugify(firstVideo.title);
      navigate(`/watchvideo/${videoName}`, {
        state: { video: firstVideo, videodata, eduid: eduid },
      });
    } else if (videodata.length === 0) {
      alert("Kursa henüz bir eğitim videosu yüklenmemiştir.");
    } else {
      alert("Bir hata oluştu!");
    }
  };

  const handleVideoClick = (video) => {
    const videoName = slugify(video.title);
    navigate(`/watchvideo/${videoName}`, {
      state: { video, videodata, eduid: eduid },
    });
  };

  return (
    <div className="tutorialDetailContainer">
      <div className="tutorialDetailInner">
        <div className="tutorialDetailGridContainer">
          <div className="tutorialDetailLeftColumn tutorialDetailHeader">
            <h1 className="tutorialDetailTitle">{originalTitle}</h1>
            <div className="tutorialDetailImage">
              <img
                className="tutorialDetailImg"
                src={`${config.API_URL}uploads/${secondphoto}`}
                alt={originalTitle}
              />
            </div>
            <h3 className="tutorialDetailDescription">{description}</h3>
            <button className="tutorialDetailButton" onClick={startTutorial}>
              Eğitimi Başlat
            </button>
          </div>

          <div className="tutorialDetailBottomRow">
          {eduid === 534946 ? (<div className="tutorialDetailLeftColumn">
                <h2 className="tutorialDetailContentHeader">Kurs İçerikleri</h2>
                <div className="tutorialContentArea">
                  {videodata.length === 0 ? (
                    <p className="noVideosMessage">Henüz bir video yüklenmemiş.</p>
                  ) : (
                    <>
                      <div
                        className="tutorialContentHeader"
                        onClick={toggleContentAccordion}
                      >
                        <div className="tutorialContentHeaderInner">
                          <div className="tutorialContentHeaderTitle">Videolar</div>
                          <div
                            className={`tutorialContentHeaderIcon ${
                              isContentAccordionOpen ? "rotate" : ""
                            }`}
                          >
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`tutorialContentList ${
                          isContentAccordionOpen && videodata.length > 0
                            ? "expand"
                            : ""
                        }`}
                      >
                        <div
                          className="category"
                          onClick={() => toggleCategory('categoryOne')}
                        >
                          <div className="categoryInner">
                          <div>Satışçının Kafa Yapısı</div>
                          <div className={`categoryIcon ${categoriesOpen.categoryOne ? "rotate" : ""}`}>
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                          </div>
                        </div>
                        <ul
                          className={`categoryContent ${
                            categoriesOpen.categoryOne ? "expand" : ""
                          }`}
                        >
                          {videodata.slice(0, 13).map((item, index) => (
                            <li
                              className="tutorialContentListItem"
                              key={index}
                              onClick={() => handleVideoClick(item)}
                            >
                              <div className="tutorialContentInner">
                                <div className="tutorialContentLeft">
                                  <div className="tutorialContentLeftIcon">
                                    <FontAwesomeIcon icon={faYoutube} />
                                  </div>
                                  {item.title}
                                </div>
                                <div className="tutorialContentRight">
                                  {item.length}
                                  <div className="tutorialContentRightIcon">
                                    <FontAwesomeIcon icon={faEye} />
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>

                        <div
                          className="category"
                          onClick={() => toggleCategory('categoryTwo')}
                        >
                          <div className="categoryInner">
                          <div>Müşterinin Kafa Yapısı</div>
                          <div className={`categoryIcon ${categoriesOpen.categoryTwo ? "rotate" : ""}`}>
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                          </div>
                        </div>
                        <ul
                          className={`categoryContent ${
                            categoriesOpen.categoryTwo ? "expand" : ""
                          }`}
                        >
                          {videodata.slice(13, 28).map((item, index) => (
                            <li
                              className="tutorialContentListItem"
                              key={index}
                              onClick={() => handleVideoClick(item)}
                            >
                              <div className="tutorialContentInner">
                                <div className="tutorialContentLeft">
                                  <div className="tutorialContentLeftIcon">
                                    <FontAwesomeIcon icon={faYoutube} />
                                  </div>
                                  {item.title}
                                </div>
                                <div className="tutorialContentRight">
                                  {item.length}
                                  <div className="tutorialContentRightIcon">
                                    <FontAwesomeIcon icon={faEye} />
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>

                        <div
                          className="category"
                          onClick={() => toggleCategory('categoryThree')}
                        >
                          <div className="categoryInner">
                          <div>Satış Teknikleri</div>
                          <div className={`categoryIcon ${categoriesOpen.categoryThree ? "rotate" : ""}`}>
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                          </div>
                        </div>
                        <ul
                          className={`categoryContent ${
                            categoriesOpen.categoryThree ? "expand" : ""
                          }`}
                        >
                          {videodata.slice(28, 37).map((item, index) => (
                            <li
                              className="tutorialContentListItem"
                              key={index}
                              onClick={() => handleVideoClick(item)}
                            >
                              <div className="tutorialContentInner">
                                <div className="tutorialContentLeft">
                                  <div className="tutorialContentLeftIcon">
                                    <FontAwesomeIcon icon={faYoutube} />
                                  </div>
                                  {item.title}
                                </div>
                                <div className="tutorialContentRight">
                                  {item.length}
                                  <div className="tutorialContentRightIcon">
                                    <FontAwesomeIcon icon={faEye} />
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              </div>) : (
            <div className="tutorialDetailLeftColumn">
              <h2 className="tutorialDetailContentHeader">Kurs İçerikleri</h2>
              <div className="tutorialContentArea">
                {videodata.length === 0 ? (
                  <p className="noVideosMessage">
                    Henüz bir video yüklenmemiş.
                  </p>
                ) : (
                  <div
                    className="tutorialContentHeader"
                    onClick={toggleContentAccordion}
                  >
                    <div className="tutorialContentHeaderInner">
                      <div className="tutorialContentHeaderTitle">Videolar</div>
                      <div
                        className={`tutorialContentHeaderIcon ${
                          isContentAccordionOpen ? "rotate" : ""
                        }`}
                      >
                        <FontAwesomeIcon icon={faChevronRight} />
                      </div>
                    </div>
                  </div>
                )}
                <ul
                  className={`tutorialContentList ${
                    isContentAccordionOpen && videodata.length > 0
                      ? "expand"
                      : ""
                  }`}
                >
                  {videodata.map((item, index) => (
                    <li
                      className="tutorialContentListItem"
                      key={index}
                      onClick={() => handleVideoClick(item)}
                    >
                      <div className="tutorialContentInner">
                        <div className="tutorialContentLeft">
                          <div className="tutorialContentLeftIcon">
                            <FontAwesomeIcon icon={faYoutube} />
                          </div>
                          {item.title}
                        </div>
                        <div className="tutorialContentRight">
                          {item.length}
                          <div className="tutorialContentRightIcon">
                            <FontAwesomeIcon icon={faEye} />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>)}
          
            <div className="tutorialDetailRightColumn">
              <h2 className="tutorialDetailCommentHeader">Yorumlar</h2>
              <div className="tutorialCommentArea">
                {allComments.length === 0 ? (
                  <p className="noCommentsMessage">Henüz bir yorum yok.</p>
                ) : (
                  <>
                    <div
                      className="tutorialContentHeader"
                      onClick={toggleCommentsAccordion}
                    >
                      <div className="tutorialCommentHeaderInner">
                        <div className="tutorialContentHeaderTitle">
                          Yorumlar
                        </div>
                        <div
                          className={`tutorialContentHeaderIcon ${
                            isCommentsAccordionOpen ? "rotate" : ""
                          }`}
                        >
                          <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                      </div>
                    </div>
                    <ul
                      className={`tutorialContentList ${
                        isCommentsAccordionOpen && allComments.length > 0
                          ? "expand"
                          : ""
                      }`}
                    >
                      {allComments.map((comment, index) => (
                        <li className="tutorialCommentItem" key={index}>
                          <div className="tutorialCommentListInner">
                            <img
                              className="tutorialCommentPhoto"
                              src={`${process.env.PUBLIC_URL}/assets/NoBodyClub/nobodyclubsquare.jpg`}
                              alt={comment.name}
                            />
                            <div className="tutorialCommentContent">
                              <div className="tutorialCommentHeader">
                                <span className="tutorialCommentName">
                                  {comment.name}
                                </span>
                                <span className="tutorialCommentDate">
                                  {comment.date}
                                </span>
                              </div>
                              <div className="commentAltGroup">
                                <p className="tutorialCommentText">
                                  {comment.comment}
                                </p>
                                {(cookiedata.role === "admin" ||
                                  comment.username === cookiedata.username) && (
                                  <FontAwesomeIcon
                                    icon={faEllipsisV}
                                    className="commentAltGroupIcon"
                                    onClick={() => togglePopup(index)}
                                  />
                                )}
                                {activePopupIndex === index &&
                                  (cookiedata.role === "admin" ||
                                    comment.username ===
                                      cookiedata.username) && (
                                    <div
                                      onClick={() =>
                                        deletecomment(comment.commentid)
                                      }
                                      className="popup"
                                    >
                                      <span>Sil</span>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
              <div className="tutorialLeaveCommentArea">
                <div className="tutorialLeaveCommentUpArea">
                  <h6 className="tutorialLeaveCommentHeader">
                    Bir Yorum Bırak!
                  </h6>
                </div>
                <div className="tutorialLeaveCommentDownArea">
                  <div className="tutorialLeaveCommentDownInner">
                    <textarea
                      ref={textareaRef}
                      onInput={handleInput}
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      className="commentInput"
                      placeholder="Yorum Yaz!"
                    />
                    <span onClick={addComment}>Gönder</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TutorialDetail;
