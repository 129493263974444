import React, { useState, useEffect } from "react";
import "../styles/users.css";
import Adminaside from "./adminaside";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSearch, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import CountryDropdown from "../../../components/dropdown";
import config from "../../../config/config";
import Loader from "../../../components/loader";

function Users() {
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setusersdata] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAddUserPopup, setShowAddUserPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newUser, setNewUser] = useState({
    name: "",
    surname: "",
    role: "",
    phone: "",
    email: "",
    username: "",
    sirket: "",
    password: "",
    ulke: "",
    il: "",
    ilce: "",
    sokak: "",
    postakodu: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataa = Cookies.get("userData");

        if (!dataa) {
          console.log("No userData cookie found");
          setLoading(false);
          return navigate("/login");
        }

        const ckdata = JSON.parse(dataa);
        console.log("Cookie data:", ckdata);

        const response = await fetch(`${config.API_URL}listusers`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            stringsession: ckdata.stringsession,
            username: ckdata.username,
          }),
        });

        const result = await response.json();
        console.log(result);
        if (result.status === "True") {
          setusersdata(result.data);
          setLoading(false);
        } else {
          alert(result.error);
          setLoading(false);
        }
      } catch (err) {
        console.error("Fetch error:", err);
      }
    };

    fetchData();
  }, [navigate]);

  const banuser = async (username, event) => {
    event.stopPropagation();

    const dataa = Cookies.get("userData");

    if (!dataa) {
      console.log("No userData cookie found");
      return navigate("/login");
    }

    const ckdata = JSON.parse(dataa);
    console.log("Cookie data:", ckdata);

    const response = await fetch(`${config.API_URL}banuser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stringsession: ckdata.stringsession,
        username: ckdata.username,
        banusername: username,
      }),
    });

    const result = await response.json();
    console.log(result);
    if (result.status === "True") {
      window.location.reload();
      alert(result.message);
    } else {
      alert(result.error);
    }
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  const closeModal = () => {
    setSelectedUser(null);
  };

  const closeModal1 = () => {
    setShowAddUserPopup(null);
  };

  const handleModalClick = (e) => {
    if (e.target.classList.contains("usersModal")) {
      closeModal();
    }
  };

  const handleModalClick1 = (e) => {
    if (e.target.classList.contains("usersModal")) {
      closeModal1();
    }
  };

  const filteredUsers = users.filter((user) =>
    `${user.name} ${user.surname}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      const dataa = Cookies.get("userData");

      if (!dataa) {
        console.log("No userData cookie found");
        return navigate("/login");
      }

      const ckdata = JSON.parse(dataa);
      console.log("Cookie data:", ckdata);

      const response = await fetch(`${config.API_URL}register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          stringsession: ckdata.stringsession,
          admin_username: ckdata.username,
          ...newUser,
        }),
      });

      const result = await response.json();
      console.log(result);
      if (result.status === "True") {
        alert(result.message);
        setShowAddUserPopup(false);
        setNewUser({
          name: "",
          surname: "",
          role: "",
          phone: "",
          email: "",
          username: "",
          sirket: "",
          password: "",
          ulke: "",
          il: "",
          ilce: "",
          sokak: "",
          postakodu: "",
        });

        window.location.reload();
      } else {
        alert(result.error);
      }
    } catch (err) {
      console.error("Add user error:", err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRoleChange = (e) => {
    setNewUser((prevState) => ({
      ...prevState,
      role: e.target.value,
    }));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="adminContainer">
      <Adminaside />
      <div className="usersContainer">
        <div className="usersInner">
          <div className="usersHeaderContainer">
            <h1 className="usersHeader">Kullanıcıları Görüntüle</h1>
            <div className="usersSearchContainer">
              <input
                type="text"
                className="usersSearchBar"
                placeholder="Ara..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <FontAwesomeIcon icon={faSearch} className="usersSearchIcon" />
            </div>
          </div>
          <div className="usersListContainer">
            <div className="usersHeader">
              <div className="usersListHeaderContainer">
                <div className="usersListHeaderInner">
                  <div className="usersListTitle isim">İsim - Soyisim</div>
                  <div className="usersListTitle rol">Rol</div>
                  <div className="usersListTitle islem">İşlem</div>
                </div>
              </div>
              <div className="usersList">
                {filteredUsers.length === 0 ? (
                  <p className="videoDuzenleNoVideosMessage">
                    Sistem üzerinde bir kullanıcı görünmüyor.
                  </p>
                ) : (
                  filteredUsers.map((user, index) => (
                    <div
                      key={index}
                      className="usersItemContainer"
                      onClick={() => handleUserClick(user)}
                    >
                      <div className="usersItemInner">
                        <div className="usersItemHeader">
                          {user.name} {user.surname}
                        </div>
                        <div className="usersItemButtons">
                          <div
                            className={
                              user.role === "admin"
                                ? "usersItemButtonSecond"
                                : "usersItemButton"
                            }
                          >
                            {user.role}
                          </div>
                        </div>
                        <div className="usersItemIslem">
                          <button
                            onClick={(event) => banuser(user.username, event)}
                            className="itemBanButton"
                          >
                            Yasakla
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedUser && (
        <div className="usersModal" onClick={handleModalClick}>
          <div className="usersModalContent">
            <div className="usersModalHeader">
              <h2>Profil Bilgileri</h2>
              <FontAwesomeIcon
                icon={faTimes}
                className="usersModalClose"
                onClick={closeModal}
              />
            </div>
            <div className="modalInner">
              <div className="modalSectionLeft">
                <p className="modalSectionLeftHeader">Kullanıcı Bilgisi</p>
                <div className="modalParameter">
                  İsim-Soyisim:{" "}
                  <span className="modalValue capitalize">
                    {selectedUser.name} {selectedUser.surname}
                  </span>
                </div>
                <div className="modalParameter">
                  Rol:{" "}
                  <span className="modalValue capitalize">
                    {selectedUser.role}
                  </span>
                </div>
                <div className="modalParameter">
                  Telefon:{" "}
                  <span className="modalValue">{selectedUser.phone}</span>
                </div>
                <div className="modalParameter">
                  Email:{" "}
                  <span className="modalValue">{selectedUser.email}</span>
                </div>
                <div className="modalParameter">
                  Kullanıcı Adı:{" "}
                  <span className="modalValue">{selectedUser.username}</span>
                </div>
                <div className="modalParameter">
                  Şifre:{" "}
                  <span className="modalValue capitalize">
                    {selectedUser.password}
                  </span>
                </div>
                <div className="modalParameter">
                  Kalan Süre:{" "}
                  <span className="modalValue capitalize">
                    {selectedUser.remaining}
                  </span>
                </div>
              </div>
              <div className="modalSectionRight">
                <p className="modalSectionRightHeader">Adres Bilgisi</p>
                <div className="modalParameter">
                  Ülke:{" "}
                  <span className="modalValue capitalize">
                    {selectedUser.ulke}
                  </span>
                </div>
                <div className="address modalParameter">
                  Adres:{" "}
                  <span className="modalValue capitalize adressSokak">
                    {selectedUser.sokak}, {selectedUser.il}/{selectedUser.ilce}
                  </span>
                </div>
                <div className="modalParameter">
                  Posta Kodu:{" "}
                  <span className="modalValue">{selectedUser.postakodu}</span>
                </div>
                <p className="modalSectionRightHeader">İşlemler</p>
                <button
                  onClick={(event) => banuser(selectedUser.username, event)}
                  className="modalBanButton"
                >
                  Yasakla
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showAddUserPopup && (
        <div className="usersModal" onClick={handleModalClick1}>
          <div className="usersModalContent">
            <div className="usersModalHeader">
              <h2>Kulllanıcı Oluştur</h2>
              <FontAwesomeIcon
                icon={faTimes}
                className="usersModalClose"
                onClick={closeModal1}
              />
            </div>
            <form className="modalInner" onSubmit={handleAddUser}>
              <div className="modalSectionLeft">
                <p className="modalSectionLeftHeader">Kullanıcı Bilgisi</p>
                <div className="addUserModalParameter">
                  <span>
                    İsim:&nbsp;
                    <span className="asterisk-colored">*</span>
                  </span>
                  <input
                    type="text"
                    name="name"
                    className="addUserInput"
                    value={newUser.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="addUserModalParameter">
                  <span>
                    Soyisim:&nbsp;
                    <span className="asterisk-colored">*</span>
                  </span>
                  <input
                    type="text"
                    name="surname"
                    className="addUserInput"
                    value={newUser.surname}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="addUserModalParameter">
                  <span>
                    Rol:&nbsp;
                    <span className="asterisk-colored">*</span>
                  </span>
                  <CountryDropdown
                    value={newUser.role}
                    onChange={handleRoleChange}
                  />
                </div>
                <div className="addUserModalParameter">
                  <span>
                    Telefon:&nbsp;
                    <span className="asterisk-colored">*</span>
                  </span>
                  <input
                    type="text"
                    name="phone"
                    className="addUserInput"
                    value={newUser.phone}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="addUserModalParameter">
                  <span>
                    E-Mail:&nbsp;
                    <span className="asterisk-colored">*</span>
                  </span>
                  <input
                    type="text"
                    name="email"
                    className="addUserInput"
                    value={newUser.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="addUserModalParameter">
                  <span>
                    Kullanıcı Adı:&nbsp;
                    <span className="asterisk-colored">*</span>
                  </span>
                  <input
                    type="text"
                    name="username"
                    className="addUserInput"
                    value={newUser.username}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="addUserModalParameter last">
                  <span>
                    Şifre:&nbsp;
                    <span className="asterisk-colored">*</span>
                  </span>
                  <input
                    type="password"
                    name="password"
                    className="addUserInput"
                    value={newUser.password}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="modalSectionRight">
                <p className="modalSectionRightHeader">Adres Bilgisi</p>
                <div className="addUserModalParameter">
                  <span>
                    Ülke:&nbsp;
                    <span className="asterisk-colored">*</span>
                  </span>
                  <input
                    type="text"
                    name="ulke"
                    className="addUserInput"
                    value={newUser.ulke}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="address addUserModalParameter">
                  <span>
                    İl:&nbsp;
                    <span className="asterisk-colored">*</span>
                  </span>
                  <input
                    type="text"
                    name="il"
                    className="addUserInput"
                    value={newUser.il}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="address addUserModalParameter">
                  <span>
                    İlçe:&nbsp;
                    <span className="asterisk-colored">*</span>
                  </span>
                  <input
                    type="text"
                    name="ilce"
                    className="addUserInput"
                    value={newUser.ilce}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="address addUserModalParameter">
                  <span>
                    Açık Adres:&nbsp;
                    <span className="asterisk-colored">*</span>
                  </span>
                  <input
                    type="text"
                    name="sokak"
                    className="addUserInput"
                    value={newUser.sokak}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="addUserModalParameter">
                  <span>
                    Posta Kodu:&nbsp;
                    <span className="asterisk-colored">*</span>
                  </span>
                  <input
                    type="text"
                    name="postakodu"
                    className="addUserInput"
                    value={newUser.postakodu}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <button type="submit" className="addUserButton">
                  Kullanıcı Oluştur
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div
        className="addUserIconContainer"
        onClick={() => setShowAddUserPopup(true)}
      >
        <div className="addUserIconInner">
          <FontAwesomeIcon icon={faPlus} className="addUserIcon" />
        </div>
      </div>
    </div>
  );
}

export default Users;
