import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import '../styles/landingpageheader.css'
import config from '../../../config/config'
import { useNavigate } from 'react-router-dom'

function LandingPageHeader() {
  const navigate = useNavigate()

  const goToRegister = () => {
    navigate('/register')
  }

  return (
    <header className='landingPageHeaderContainer'>
      <div className='landingPageHeaderInner'>
        <h1 className='landingPageHeaderTitle'>
          <span className='landingPageHeaderTitleWhite'>
            Sizi Bir Hiç Yerine Koyanlara İnat <br />
          </span>
          <span className='landingPageHeaderTitleRed'>
            Yüksek Gelirli Kariyerinizi Başlatın, <br />
          </span>
          <span className='landingPageHeaderTitleWhite'>
            Yeni Nesil Milyonerler Arasına Katılın. <br />
          </span>
        </h1>
        <span className='landingPageHeaderSubtitle'>
          Her zaman aldığınız kötü sonuçlara razı mı olacaksınız yoksa ipleri
          elinize mi alacaksınız?
        </span>

        <iframe
          className='landingPageHeaderVideo'
          width='640'
          height='360'
          src='https://www.youtube.com/embed/xjgc6QOWwCw'
          title="NoBody Club'a katılın!"
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowfullscreen
        ></iframe>

        <button className='landingPageHeaderButton' onClick={goToRegister}>
          {config.PRODUCT_NAME}'a Katılmak İstiyorum
          <FontAwesomeIcon
            icon={faChevronRight}
            className='landingPageHeaderButtonIcon'
          />
        </button>

        <img
          alt='NoBody Club Türkiye Öğrenci'
          src={`${process.env.PUBLIC_URL}/ogrenci.webp`}
          className='landingPageHeaderOgrenci'
        />
        <div className='landingPageHeaderUtilsContainer'>
          <img
            alt='NoBody Club Türkiye Öğrenci'
            src={`${process.env.PUBLIC_URL}/utils_cizgi.png`}
            className='landingPageHeaderUtilsCizgi'
          />
          <div className='landingPageHeaderUtils'>
            <div className='landingPageHeaderUtil'>
              <img
                alt='Anında Erişim'
                src={`${process.env.PUBLIC_URL}/aninda-erisim.webp`}
                className='landingPageHeaderUtilIcon'
              />
              Anında Erişim
            </div>
            <div className='landingPageHeaderUtil'>
              <img
                alt='Anında Erişim'
                src={`${process.env.PUBLIC_URL}/daima-guncel.webp`}
                className='landingPageHeaderUtilIcon'
              />
              Daima Güncel
            </div>
            <div className='landingPageHeaderUtil'>
              <img
                alt='Anında Erişim'
                src={`${process.env.PUBLIC_URL}/siradisi-metod.webp`}
                className='landingPageHeaderUtilIcon'
              />
              Sıradışı Metod
            </div>
            <div className='landingPageHeaderUtil'>
              <img
                alt='Anında Erişim'
                src={`${process.env.PUBLIC_URL}/guvenli-odeme.webp`}
                className='landingPageHeaderUtilIcon'
              />
              Güvenli Ödeme
            </div>
          </div>
          <img
            alt='NoBody Club Türkiye Öğrenci'
            src={`${process.env.PUBLIC_URL}/utils_cizgi.png`}
            className='landingPageHeaderUtilsCizgi'
          />
        </div>
      </div>
    </header>
  )
}

export default LandingPageHeader
