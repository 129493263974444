import React from "react";
import Profile from "./components/profile.js";
import WelcomeNavbar from "../welcome/components/welcomeNavbar.js";
import FooterPanel from "../../components/footerPanel.js";
import Copyright from "../../components/copyright.js";

function ProfilePage() {
  return (
    <>
      <WelcomeNavbar />
      <Profile />
      <FooterPanel />
      <Copyright />
    </>
  );
}

export default ProfilePage;
