import React, { useState, useEffect } from "react";
import "../styles/landingpagenavbar.css";

import { useNavigate } from "react-router-dom";
import config from "../../../config/config";
import Cookies from "js-cookie";

function LandgingPageNavbar() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const navbar = document.getElementById("landingPageNavbar");
  //     const scrollTop = window.scrollY;
  //     const maxScroll = 1000; // Opaklık değişiminin tamamlanacağı kaydırma miktarı
  //     const opacity = Math.min(scrollTop / maxScroll, 1);
  //     navbar.style.background = `linear-gradient(rgba(12, 12, 12, ${opacity}), rgba(12, 12, 12, ${opacity}))`;
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const goToLogin = () => {
    navigate("/login");
  };

  const goToHome = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const userData = Cookies.get("userData");

        if (!userData) {
          setIsAuthenticated(false);
          return;
        }

        const parsedData = JSON.parse(userData);

        const response = await fetch(`${config.API_URL}session`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            stringsession: parsedData.stringsession,
            username: parsedData.username,
          }),
        });

        const result = await response.json();

        if (result.status === "True") {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (err) {
        console.error("Fetch error:", err);
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, [navigate]);

  return (
    <nav className="landingPageNavbarContainer" id="landingPageNavbar">
      <div className="landingPageNavbarInner">
        <div className="landingPageNavbarLogoBanner">
          <div className="landingPageNavbarLogo">
            <img
              src={`${process.env.PUBLIC_URL}/assets/NoBodyClub/nobodyclub.png`}
              className="landingPageNavLogo"
              alt="NoBody Club Logo"
              onClick={goToHome}
            />
          </div>
        </div>
        <div className="landingPageNavbarContent">
          <ul className="landingPageNavbarList landingPage">
            <li className="landingPageNavbarListItem" onClick={goToLogin}>
              {isAuthenticated ? (
                <div className="landingPageNavbarLink last">Panele Git</div>
              ) : (
                <div className="landingPageNavbarLink last">Giriş Yap</div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default LandgingPageNavbar;
