import React from 'react'
import '../styles/landingpagevideo.css'

function LandingPageVideo() {
  const videoData = [
    {
      src: 'https://www.youtube.com/embed/78fAUO2Jaqw',
      title: 'Video 1'
    },
    {
      src: 'https://www.youtube.com/embed/3rDRxvLeh5w',
      title: 'Video 2'
    },
    {
      src: 'https://www.youtube.com/embed/WI6UexF-VM0',
      title: 'Video 3'
    },
    {
      src: 'https://www.youtube.com/embed/x8unfSbSpFU',
      title: 'Video 4'
    },
    {
      src: 'https://www.youtube.com/embed/x3affd1UvQo',
      title: 'Video 5'
    },
    {
      src: 'https://www.youtube.com/embed/lZzhcb1rh4w',
      title: 'Video 6'
    },
    {
      src: 'https://www.youtube.com/embed/aeWzPevQ2XQ',
      title: 'Video 7'
    },
    {
      src: 'https://www.youtube.com/embed/Mk8yDnTDc9k',
      title: 'Video 8'
    },
    {
      src: 'https://www.youtube.com/embed/z-_r_imiRlc',
      title: 'Video 9'
    },
    {
      src: 'https://www.youtube.com/embed/kmA15RMG6eQ',
      title: 'Video 10'
    },
    {
      src: 'https://www.youtube.com/embed/bzgaANaWbRA',
      title: 'Video 11'
    },
    {
      src: 'https://www.youtube.com/embed/rXIxPQ28cxo',
      title: 'Video 12'
    },
    {
      src: 'https://www.youtube.com/embed/eo7Tt8leDds',
      title: 'Video 13'
    },
    {
      src: 'https://www.youtube.com/embed/edtOl0Cck2k',
      title: 'Video 14'
    },
    {
      src: 'https://www.youtube.com/embed/FsIRYJbxoLw',
      title: 'Video 15'
    },
    {
      src: 'https://www.youtube.com/embed/Jm0OTAhcCf0',
      title: 'Video 16'
    }
  ]

  const videoRows = []
  for (let i = 0; i < videoData.length; i += 4) {
    videoRows.push(videoData.slice(i, i + 4))
  }

  return (
    <div className='landingPageVideoContainer'>
      <div className='landingPageVideoInner'>
        <h4 className='landingPageVideoTitle'>
          İşte{' '}
          <span className='landingPageVideoTitleColored'>
            Hayatı Değişenler
          </span>
        </h4>
        <div className='landingPageVideoArea'>
          {videoRows.map((row, rowIndex) => (
            <div key={rowIndex} className='landingPageVideoColumn'>
              {row.map((video, index) => (
                <iframe
                  key={index}
                  className='landingPageVideoPlayer'
                  src={video.src}
                  title={video.title}
                  frameborder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  referrerpolicy='strict-origin-when-cross-origin'
                  allowfullscreen
                ></iframe>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default LandingPageVideo
