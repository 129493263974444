import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import pageTitles from "./data/pageTitles";
import config from "./config/config";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./scripts/landingpage/index.js";
import WelcomePage from "./scripts/welcome/index.js";
import LoginPage from "./scripts/login/index.js";
import ProfilePage from "./scripts/profile/index.js";
import TutorialsPage from "./scripts/tutorials/index.js";
import TutorialDetailPage from "./scripts/tutorialdetail/index.js";
import AdminHome from "./scripts/admin/components/adminhome.js";
import EgitimOlustur from "./scripts/admin/components/egitimolustur.js";
import AdminPage from "./scripts/admin/index.js";
import EgitimDuzenle from "./scripts/admin/components/egitimduzenle.js";
import EgitimSetiniDuzenle from "./scripts/admin/components/egitimsetiniduzenle.js";
import VideolariDuzenle from "./scripts/admin/components/videolariduzenle.js";
import NotFoundPage from "./scripts/notfound/index.js";
import Users from "./scripts/admin/components/users.js";
import WatchVideoPage from "./scripts/watchvideo/index.js";
import ScrollToTop from "./scrolltotop/scrollToTop.js";
import ContactPage from "./scripts/contact/index.js";
import ForgotPassswordMail from "./scripts/forgotpassword/components/forgotpasswordmail.js";
import ForgotPasswordCode from "./scripts/forgotpassword/components/forgotpasswordcode.js";
import ForgotPasswordNewPassword from "./scripts/forgotpassword/components/forgotpasswordnewpassword.js";
import RegisterPage from "./scripts/register/index.js";
import { changeSelectionColor } from "./utils/changeSelectionColor.js";

function TitleUpdater() {
  const location = useLocation();

  useEffect(() => {
    changeSelectionColor();
  });

  useEffect(() => {
    let title = pageTitles[location.pathname];

    // Eğer tam olarak eşleşen bir başlık yoksa, dinamik segmentleri kontrol et
    if (!title) {
      if (location.pathname.startsWith("/tutorials/")) {
        title = `${config.PRODUCT_NAME} | Eğitim Detayı`;
      } else if (location.pathname.startsWith("/watchvideo/")) {
        title = `${config.PRODUCT_NAME} | Video İzle`;
      } else {
        title = `${config.PRODUCT_NAME} | Sayfa Bulunamadı`;
      }
    }

    document.title = title;
  }, [location]);

  return null;
}

function App() {
  return (
    <Router>
      <div>
        <ScrollToTop />
        <TitleUpdater />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route
            path="/forgot-password-mail"
            element={<ForgotPassswordMail />}
          />
          <Route
            path="/forgot-password-code"
            element={<ForgotPasswordCode />}
          />
          <Route
            path="/forgot-password-newpassword"
            element={<ForgotPasswordNewPassword />}
          />
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/tutorials" element={<TutorialsPage />} />
          <Route path="/tutorials/:title" element={<TutorialDetailPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/admin/home" element={<AdminHome />} />
          <Route path="/admin/egitim-olustur" element={<EgitimOlustur />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/admin/egitim-duzenle" element={<EgitimDuzenle />} />
          <Route
            path="/admin/egitim-duzenle/egitim-setini-duzenle/:slug"
            element={<EgitimSetiniDuzenle />}
          />
          <Route
            path="/admin/egitim-duzenle/videolari-duzenle/:slug"
            element={<VideolariDuzenle />}
          />
          <Route path="/admin/users" element={<Users />} />
          <Route path="/watchvideo/:videoname" element={<WatchVideoPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
