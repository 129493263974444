import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WelcomeNavbar from "./components/welcomeNavbar";
import WelcomeHeader from "./components/welcomeHeader";
import Copyright from "../../components/copyright";
import FooterPanel from "../../components/footerPanel";

function WelcomePage() {
  const location = useLocation();
  const navigation = useNavigate();
  const [hasShownToast, setHasShownToast] = useState(false);

  useEffect(() => {
    if (location.state?.loginSuccess && !hasShownToast) {
      toast.success("Giriş başarılı!", {
        position: "top-right",
        autoClose: 5000,
      });
      setHasShownToast(true);

      navigation(location.pathname, { replace: true, state: {} });
    }
  }, [location, hasShownToast, navigation]);

  return (
    <>
      <ToastContainer />
      <WelcomeNavbar />
      <WelcomeHeader />
      <FooterPanel />
      <Copyright />
    </>
  );
}

export default WelcomePage;
