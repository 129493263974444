import React from "react";
import "../styles/landingpagelast.css";
import { Autoplay, Navigation } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "swiper/css/scrollbar";

function LandingPageLast() {
  return (
    <div className="landingPageLastContainer">
      <div className="landingPageLastInner">
        <div className="landingPageLastLeft">
          <div className="landingPageLastTitle">
            Son&nbsp;
            <span className="landingPageLastTitleColored">Olarak:</span>
          </div>
          <img
            alt="Grup Çizgi"
            src={`${process.env.PUBLIC_URL}/grup-cizgi.png`}
            className="landingPageLastTitlePhoto"
          />
          <p className="landingPageLastText">Girişimcilik bir beceridir.</p>
          <p className="landingPageLastText">
            Türkiye’de hayatta kalmak ise bir zorunluluk.
          </p>
          <p className="landingPageLastText">
            Altta kalanın canı çıksın oyunu vardır. Hatırladınız mı?
          </p>
          <p className="landingPageLastText">
            İşte biz o oyunda en üstteki kişi olmanızı sağlıyoruz.
          </p>
          <p className="landingPageLastText">
            Hayatınızın gerçekleri her geçen gün daha fazla belirginleşiyor ve
            kendi hayatınızı nasıl değiştirebileceğiniz, finansal özgürlüğe
            nasıl ulaşabileceğinize dair hiçbir fikriniz yok.
          </p>
          <p className="landingPageLastText">
            Bugün hayatınızı sıfırdan nasıl inşa edebileceğinizi öğrenmek için
            Türkiye’de bundan daha iyi bir yer yok.
          </p>
          <p className="landingPageLastText">Kulübe Hoşgeldiniz.</p>
        </div>

        <div className="landingPageLastRight">
          <Swiper
            modules={[Navigation, Autoplay]}
            navigation
            loop={true}
            spaceBetween={50}
            slidesPerView={1}
            autoplay={{ delay: 5000 }}
          >
            <SwiperSlide className="slide">
              <img
                alt="NoBody Club Top 100"
                src={`${process.env.PUBLIC_URL}/top-100-toplu.webp`}
                className="slide-content"
              />
            </SwiperSlide>
            <SwiperSlide className="slide">
              <img
                alt="NoBody Club Top 100"
                src={`${process.env.PUBLIC_URL}/sat-toplu.webp`}
                className="slide-content"
              />
            </SwiperSlide>
            <SwiperSlide className="slide">
              <img
                alt="NoBody Club Top 100"
                src={`${process.env.PUBLIC_URL}/kisisell-toplu.webp`}
                className="slide-content"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default LandingPageLast;
