import React from "react";
import "../styles/welcomeContact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faTelegram } from "@fortawesome/free-brands-svg-icons";

function WelcomeContact() {
  return (
    <div className="welcomeContactContainer">
      <div className="welcomeContactContent">
        <div className="welcomeContactTitleArea">
          <h1 className="welcomeContactTitle">İletişim ve Destek</h1>
        </div>
        <div className="welcomeContactInner">
          <div className="welcomeWhatsapp">
            <div className="welcomeWhatsappText">
              Aklınıza takılan soruları 7/24 sorabileceğiniz Whatsapp hattımızın
              ucundaki başarı ekibimiz sizin için hazır.
            </div>

            <div className="welcomeWhatsappIcon">
              <a
                href="https://api.whatsapp.com/send/?phone=5010662582&text&type=phone_number&app_absent=0"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faWhatsapp} className="whatsappIcon" />
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=5010662582&text&type=phone_number&app_absent=0"
                target="_blank"
                rel="noreferrer"
                className="welcomeWhatsappIconText"
              >
                WhatsApp Destek Hattı
              </a>
            </div>
          </div>
          <div className="welcomeTelegram">
            <div className="welcomeTelegramText">
              Yeni gelişmeler ve ekstra bilgi paylaşımları için Telegram
              kanalına hemen katılın ve topluluğumuzun keyfini çıkarın.
            </div>
            <div className="welcomeTelegramIcon">
              <a
                href="https://t.me/+JosrDJCTgf5jODE0"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTelegram} className="telegramIcon" />
              </a>
              <a
                className="welcomeTelegramIconText"
                href="https://t.me/+JosrDJCTgf5jODE0"
                target="_blank"
                rel="noreferrer"
              >
                Telegram Kanalı
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomeContact;
