import React, { useState, useEffect, useRef } from 'react'
import '../styles/adminaside.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  faHome,
  faPlus,
  faCog,
  faSignOutAlt,
  faBars,
  faUser
} from '@fortawesome/free-solid-svg-icons'

function Adminaside() {
  const [isOpen, setIsOpen] = useState(false)
  const asideRef = useRef(null)
  const location = useLocation()

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const navigate = useNavigate()

  const goToHome = () => {
    navigate('/admin/home')
  }
  const goToUsers = () => {
    navigate('/admin/users')
  }

  const goToEgitimOlustur = () => {
    navigate('/admin/egitim-olustur')
  }

  const goToEgitimDuzenle = () => {
    navigate('/admin/egitim-duzenle')
  }

  const handleClickOutside = event => {
    if (asideRef.current && !asideRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  /*useEffect(() => {
    // Location değiştiğinde menüyü kapat
    setIsOpen(false);
  }, [location]);*/

  return (
    <div
      ref={asideRef}
      className={`adminAsideContainer ${isOpen ? 'open' : 'closed'}`}
    >
      <div className='adminAsideInner'>
        <div className='adminAsideHeader'>
          {isOpen && (
            <div className='adminAsideHeaderInner'>
              <div className='adminProfileImage'>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/NoBodyClub/nobodyclubsquare.jpg`}
                  className='adminProfileImg'
                  alt='Profil Fotoğrafı'
                />
              </div>
              <div className='adminName'>NoBodyClub</div>
            </div>
          )}
          <button className='toggleButton' onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
        <ul className='adminMenu'>
          <li className='adminMenuItem' onClick={goToHome}>
            <FontAwesomeIcon icon={faHome} className='adminAsideIcon' />
            {isOpen && <span>Ana Sayfa</span>}
          </li>
          <li className='adminMenuItem' onClick={goToUsers}>
            <FontAwesomeIcon icon={faUser} className='adminAsideIcon' />
            {isOpen && <span>Üye İşlem</span>}
          </li>
          <li className='adminMenuItem' onClick={goToEgitimOlustur}>
            <FontAwesomeIcon icon={faPlus} className='adminAsideIcon' />
            {isOpen && <span>Eğitim Oluştur</span>}
          </li>
          <li className='adminMenuItem' onClick={goToEgitimDuzenle}>
            <FontAwesomeIcon icon={faCog} className='adminAsideIcon' />
            {isOpen && <span>Eğitim Düzenle</span>}
          </li>
          <li onClick={() => navigate('/welcome')} className='adminMenuItem'>
            <FontAwesomeIcon icon={faSignOutAlt} className='adminAsideIcon' />
            {isOpen && <span>Ana Menüye Dön</span>}
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Adminaside
