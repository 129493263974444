import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../styles/tutorials.css";
import TutorialCard from "../../../components/tutorialcard";
import Cookies from "js-cookie";
import config from "../../../config/config";
import Loader from "../../../components/loader";

function Tutorials() {
  const [tutorialData, setTutorialData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const removeSpecialChars = (str) => {
    return str
      .replace(/[öÖ]/g, "o")
      .replace(/[çÇ]/g, "c")
      .replace(/[şŞ]/g, "s")
      .replace(/[ıİ]/g, "i")
      .replace(/[üÜ]/g, "u")
      .replace(/[ğĞ]/g, "g")
      .replace(/[^a-zA-Z0-9 ]/g, "") // Remove all special characters except alphanumeric and spaces
      .replace(/\s+/g, "-") // Replace spaces with dashes
      .toLowerCase();
  };

  const handleCardClick = (tutorial) => {
    const formattedTitle = removeSpecialChars(tutorial.eduname);
    navigate(`/tutorials/${formattedTitle}`, {
      state: {
        ...tutorial,
        originalTitle: tutorial.eduname,
        originalImage: tutorial.secondPhoto,
      },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const dataa = Cookies.get("userData");
      const ckdata = dataa ? JSON.parse(dataa) : {};

      const sessionToken = ckdata.stringsession;
      const username = ckdata.username;
      if (!sessionToken || !username) {
        alert("Kullanıcı bilgileri bulunamadı. Lütfen tekrar giriş yapın.");
        setLoading(false);
        return;
      }

      const response = await fetch(`${config.API_URL}listedu`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          stringsession: sessionToken,
          username: username,
        }),
      });

      const data = await response.json();

      if (data.status === "True") {
        setTutorialData(data.data);
        setLoading(false);
      } else {
        alert(`Hata: ${data.error}`);
        setLoading(false);
      }
    };

    fetchData();
  }, [location]);

  return (
    <>
      {loading && <Loader />}
      <div className="tutorialsContainer">
        <div className="tutorialsInner">
          {tutorialData.length === 0 ? (
            <p className="tutorialsNotFound">Henüz eğitim bulunmamaktadır.</p>
          ) : (
            <>
              <div className="tutorialsHeaderArea">
                <h1 className="tutorialsHeader">
                  Hayatınızı Değiştirecek Eğitimler
                </h1>
              </div>
              <div className="tutorialsCardArea">
                {tutorialData.map((tutorial, index) => (
                  <TutorialCard
                    key={index}
                    image={`${config.API_URL}uploads/${tutorial.firstphoto}`} // assuming firstphoto is the main image
                    title={tutorial.eduname}
                    onClick={() => handleCardClick(tutorial)}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Tutorials;
