import React, { useState, useRef, useEffect } from "react";
import "../styles/egitimduzenle.css";
import Adminaside from "./adminaside";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { slugify } from "../../../utils/slugify";
import Cookies from "js-cookie";
import config from "../../../config/config";
import Loader from "../../../components/loader";
import PopupLoader from "../../../components/popuploader";

function EgitimDuzenle() {
  const [tutorialData, setTutorialData] = useState([]);
  const [openPopup, setOpenPopup] = useState(null);
  const popupRefs = useRef([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [loaderVisible, setLoaderVisible] = useState(false);

  const deleteedu = async (eduid) => {
    setLoaderVisible(true);
    const dataa = Cookies.get("userData");

    if (!dataa) {
      console.log("No userData cookie found");
      return navigate("/login");
    }

    const ckdata = JSON.parse(dataa);

    const response = await fetch(`${config.API_URL}deleteedu`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stringsession: ckdata.stringsession,
        username: ckdata.username,
        eduid: eduid,
      }),
    });

    const result = await response.json();

    console.log(result);
    if (result.status === "True") {
      setLoaderVisible(false);
      window.location.reload();
      alert(result.message);
    } else {
      setLoaderVisible(false);
      alert(result.error);
    }
  };
  const fetchData = async () => {
    const dataa = Cookies.get("userData");
    const ckdata = dataa ? JSON.parse(dataa) : {};

    const sessionToken = ckdata.stringsession;
    const username = ckdata.username;
    if (!sessionToken || !username) {
      alert("Kullanıcı bilgileri bulunamadı. Lütfen tekrar giriş yapın.");
      setLoading(false);
      return;
    }

    const response = await fetch(`${config.API_URL}listedu`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stringsession: sessionToken,
        username: username,
      }),
    });

    const data = await response.json();

    if (data.status === "True") {
      setTutorialData(data.data);
      setLoading(false);
    } else {
      alert(`Hata: ${data.error}`);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [location]);

  const handleTogglePopup = (index) => {
    if (openPopup === index) {
      setOpenPopup(null);
    } else {
      setOpenPopup(index);
    }
  };

  const handleClickOutside = (event) => {
    if (popupRefs.current) {
      let outsideClick = true;
      popupRefs.current.forEach((ref) => {
        if (ref && ref.contains(event.target)) {
          outsideClick = false;
        }
      });
      if (outsideClick) {
        setOpenPopup(null);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleEditSet = (tutorial) => {
    const slug = slugify(tutorial.eduname);
    navigate(`/admin/egitim-duzenle/egitim-setini-duzenle/${slug}`, {
      state: { tutorial },
    });
  };

  const handleEditVideos = (tutorial) => {
    const slug = slugify(tutorial.eduname);
    navigate(`/admin/egitim-duzenle/videolari-duzenle/${slug}`, {
      state: { tutorial },
    });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="adminContainer">
      <Adminaside />
      {loaderVisible && <PopupLoader />}
      <div className="egitimDuzenleContainer">
        <div className="egitimDuzenleInner">
          <h1 className="egitimDuzenleHeader">Eğitimleri Düzenle</h1>
          <div className="egitimListContainer">
            <div className="egitimListHeader">
              <div className="egitimListHeaderContainer">
                <div className="egitimListHeaderInner">
                  <div className="egitimListTitle">Eğitim Adı</div>
                  <div className="egitimListTitle">İşlemler</div>
                </div>
              </div>
              <div className="egitimList">
                {tutorialData.length === 0 ? (
                  <div className="videoDuzenleNoVideosMessage">
                    Sitende bir eğitim seti görünmüyor. Eğitim oluşturmak için
                    menüdeki artı ikonuna tıkla!
                  </div>
                ) : (
                  tutorialData.map((tutorial, index) => (
                    <div key={index} className="egitimItemContainer">
                      <div className="egitimItemInner">
                        <div className="egitimItemHeader">
                          {tutorial.eduname}
                        </div>
                        <div className="egitimItemButtons">
                          <button
                            className="egitimItemButton"
                            onClick={() => handleEditSet(tutorial)}
                          >
                            Eğitim Setini Düzenle
                          </button>
                          <button
                            className="egitimItemButtonSecond"
                            onClick={() => handleEditVideos(tutorial)}
                          >
                            Videoları Düzenle
                          </button>
                          <button
                            onClick={() => deleteedu(tutorial.eduid)}
                            className="egitimItemButtonThird"
                          >
                            Sil
                          </button>
                          <div
                            ref={(el) => (popupRefs.current[index] = el)}
                            className="egitimInnerIconContainer"
                          >
                            <FontAwesomeIcon
                              icon={faEllipsisV}
                              className="egitimInnerIcon"
                              onClick={() => handleTogglePopup(index)}
                            />
                            <div
                              className={`popupMenu ${
                                openPopup === index ? "open" : ""
                              }`}
                            >
                              <div
                                className="popupMenuItem"
                                onClick={() => handleEditSet(tutorial)}
                              >
                                Eğitim Setini Düzenle
                              </div>
                              <div
                                className="popupMenuItem"
                                onClick={() => handleEditVideos(tutorial)}
                              >
                                Videoları Düzenle
                              </div>
                              <div
                                onClick={() => deleteedu(tutorial.eduid)}
                                className="popupMenuItem"
                              >
                                Sil
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EgitimDuzenle;
