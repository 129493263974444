// src/utils/changeSelectionColor.js

export const changeSelectionColor = () => {
  document.querySelectorAll("*").forEach((el) => {
    const style = getComputedStyle(el);
    if (style.color === "rgb(255, 1, 0)") {
      // Kırmızı rengi kontrol et (rgb formatında)
      el.classList.add("red-selection");
    }
  });
};
