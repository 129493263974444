import React, { useState } from 'react'
import { useEffect } from 'react'
import '../styles/register.css'
import 'react-toastify/dist/ReactToastify.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'

function Register() {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    phone: '',
    email: '',
    country: '',
    il: '',
    ilce: '',
    adres: '',
    postakodu: ''
  })

  const [isModalOpen, setIsModalOpen] = useState(false) // State to manage modal visibility

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
  }, [isModalOpen])

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const response = await fetch('https://api.nobodyclub.net/createpayment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })

    const data = await response.json()

    if (data.status === 'success') {
      window.location.href = data.link // Redirect
    } else {
      alert(data.error || 'Bir hata oluştu.')
    }
  }

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      closeModal()
    }
  }

  return (
    <div className='registerContainer'>
      <h1 className='registerTitle'>
        No<span className='registerTitleColored'>Body Club'a</span> Hoşgeldiniz!
      </h1>
      <form className='registerInner' onSubmit={handleSubmit}>
        <div className='registerInnerTitleContainer'>
          <h3 className='registerInnerTitle'>Fatura detayları</h3>
        </div>

        <div className='registerNameSurname'>
          <div className='registerName'>
            <label htmlFor='name' className='formLabel'>
              İsim<span className='registerAsterisks'>*</span>
            </label>
            <input
              name='name'
              id='name'
              type='text'
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className='registerSurname'>
            <label htmlFor='surname' className='formLabel'>
              Soyisim<span className='registerAsterisks'>*</span>
            </label>
            <input
              name='surname'
              id='surname'
              type='text'
              value={formData.surname}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className='registerPhoneMail'>
          <div className='registerPhone'>
            <label htmlFor='phone' className='formLabel'>
              Telefon Numarası<span className='registerAsterisks'>*</span>
            </label>
            <input
              name='phone'
              id='phone'
              type='text'
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className='registerMail'>
            <label htmlFor='email' className='formLabel'>
              E-Mail<span className='registerAsterisks'>*</span>
            </label>
            <input
              name='email'
              id='email'
              type='email'
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className='registerCountryCity'>
          <div className='registerCountry'>
            <label htmlFor='country' className='formLabel'>
              Ülke<span className='registerAsterisks'>*</span>
            </label>
            <input
              name='country'
              id='country'
              type='text'
              value={formData.country}
              onChange={handleChange}
              required
            />
          </div>
          <div className='registerCity'>
            <label htmlFor='il' className='formLabel'>
              İl<span className='registerAsterisks'>*</span>
            </label>
            <input
              name='il'
              id='il'
              type='text'
              value={formData.il}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className='registerDistrictAdress'>
          <div className='registerDistrict'>
            <label htmlFor='ilce' className='formLabel'>
              İlçe<span className='registerAsterisks'>*</span>
            </label>
            <input
              name='ilce'
              id='ilce'
              type='text'
              value={formData.ilce}
              onChange={handleChange}
              required
            />
          </div>
          <div className='registerAdress'>
            <label htmlFor='adres' className='formLabel'>
              Açık Adres<span className='registerAsterisks'>*</span>
            </label>
            <input
              name='adres'
              id='adres'
              type='text'
              value={formData.adres}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className='registerPostCodeContainer'>
          <div className='registerPostCode'>
            <label htmlFor='postakodu' className='formLabel'>
              Posta Kodu<span className='registerAsterisks'>*</span>
            </label>
            <input
              name='postakodu'
              id='postakodu'
              type='text'
              value={formData.postakodu}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <hr className='registerLine' />
        <h3 className='registerInnerTitle'>Şipariş bilgisi</h3>

        <div className='registerProductPriceHeader'>
          <div className='registerProductHeader'>Ürün</div>
          <div className='registerPriceHeader'>Fiyat</div>
        </div>
        <hr className='registerProductPriceHeaderLine' />
        <div className='registerProductPrice'>
          <div className='registerProduct'>
            NoBody Club Üyeliği&nbsp;x&nbsp;1
          </div>
          <div className='registerPrice'>97,00$</div>
        </div>
        <hr className='registerProductPriceLine' />
        <div className='registerTotalPriceContainer'>
          <div className='registerTotal'>Toplam</div>
          <div className='registerTotalPrice'>97,00$</div>
        </div>
        <hr className='registerLine' />

        <p className='registerInformation red'>
          Ödeme yapıldıktan sonra girmiş olduğunuz mail adresine kullanıcı
          adınız ve şifreniz e-posta yoluyla gönderilecektir. Dilerseniz bu
          bilgileri daha sonra değiştirebilirsiniz.
        </p>
        <p className='registerInformation'>
          Kişisel verileriniz, siparişinizi işlemek, bu web sitesindeki
          deneyiminizi desteklemek ve web sitemizde açıklanan diğer amaçlar için
          kullanılacaktır.{' '}
          <span className='registerPrivacyPolicy' onClick={openModal}>
            KVKK metni ve gizlilik politikası
          </span>
        </p>

        <p className='registerInformation'>
          Kayıt olunduğu an itibari ile NoBody Club web sitesinin{' '}
          <span className='termsAndConditions' onClick={openModal}>
            KVKK metni ve gizlilik politikası
          </span>{' '}
          okunmuş ve kabul edilmiş sayılacaktır.
        </p>

        <div className='checkboxArea'>
          <input type='checkbox' id='check' name='check' required />
          <label htmlFor='check' className='checkboxLabel'>
            Yukarıda yazılanları okudum ve kabul ediyorum.
          </label>
        </div>

        <div className='registerButtonArea'>
          <button className='registerButton' type='submit'>
            Siparişi Tamamla
          </button>
        </div>
      </form>

      {isModalOpen && (
        <div className='registerModalOverlay' onClick={handleOverlayClick}>
          <div className='registerModalContent'>
            <button className='registerModalCloseButton' onClick={closeModal}>
              <FontAwesomeIcon icon={faX} />
            </button>
            <h2>KVKK Metni ve Gizlilik Politikası</h2>
            <p className='p1'>Kanun Numarası 6698</p>
            <p>Kabul Tarihi : 24/3/2016</p>
            <p>
              Yayımlandığı R.Gazete : Tarih: 7/4/2016 Sayı : 29677 Yayımlandığı
              Düstur : Tertip : 5 Cilt : 57
            </p>
            <p>BİRİNCİ BÖLÜM</p>
            <p>Amaç, Kapsam ve Tanımlar</p>
            <p>Amaç</p>
            <p>
              MADDE 1- (1) Bu Kanunun amacı, kişisel verilerin işlenmesinde
              başta özel hayatın gizliliği olmak üzere kişilerin temel hak ve
              özgürlüklerini korumak ve kişisel verileri işleyen gerçek ve tüzel
              kişilerin yükümlülükleri ile uyacakları usul ve esasları
              düzenlemektir.
            </p>
            <p>Kapsam</p>
            <p>
              MADDE 2- (1) Bu Kanun hükümleri, kişisel verileri işlenen gerçek
              kişiler ile bu verileri tamamen veya kısmen otomatik olan ya da
              herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik
              olmayan yollarla işleyen gerçek ve tüzel kişiler hakkında
              uygulanır.
            </p>
            <p>Tanımlar</p>
            <p>MADDE 3- (1) Bu Kanunun uygulanmasında;</p>
            <p>
              a) Açık rıza: Belirli bir konuya ilişkin, bilgilendirilmeye
              dayanan ve özgür iradeyle açıklanan rızayı,
            </p>
            <p>
              b) Anonim hâle getirme: Kişisel verilerin, başka verilerle
              eşleştirilerek dahi hiçbir surette kimliği belirli veya
              belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hâle
              getirilmesini,
            </p>
            <p>
              c) Başkan: Kişisel Verileri Koruma Kurumu Başkanını, ç) İlgili
              kişi: Kişisel verisi işlenen gerçek kişiyi,
            </p>
            <p>
              d) Kişisel veri: Kimliği belirli veya belirlenebilir gerçek kişiye
              ilişkin her türlü bilgiyi,
            </p>
            <p>
              e) Kişisel verilerin işlenmesi: Kişisel verilerin tamamen veya
              kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin
              parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi,
              kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi,
              yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde
              edilebilir hâle getirilmesi, sınıflandırılması ya da
              kullanılmasının engellenmesi gibi veriler üzerinde
              gerçekleştirilen her türlü işlemi,
            </p>
            <p>f) Kurul: Kişisel Verileri Koruma Kurulunu,</p>
            <p>g) Kurum: Kişisel Verileri Koruma Kurumunu,</p>
            <p>
              ğ) Veri işleyen: Veri sorumlusunun verdiği yetkiye dayanarak onun
              adına kişisel verileri işleyen gerçek veya tüzel kişiyi,
            </p>
            <p>
              h) Veri kayıt sistemi: Kişisel verilerin belirli kriterlere göre
              yapılandırılarak işlendiği kayıt sistemini,
            </p>
            <p>
              ı) Veri sorumlusu: Kişisel verilerin işleme amaçlarını ve
              vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve
              yönetilmesinden sorumlu olan gerçek veya tüzel kişiyi,
            </p>
            <p>ifade eder.</p>
            <p>İKİNCİ BÖLÜM</p>
            <p>Kişisel Verilerin İşlenmesi</p>
            <p>Genel ilkeler</p>
            <p>
              MADDE 4- (1) Kişisel veriler, ancak bu Kanunda ve diğer kanunlarda
              öngörülen usul ve esaslara uygun olarak işlenebilir.
            </p>
            <p>
              (2) Kişisel verilerin işlenmesinde aşağıdaki ilkelere uyulması
              zorunludur:
            </p>
            <p>a) Hukuka ve dürüstlük kurallarına uygun olma.</p>
            <p>b) Doğru ve gerektiğinde güncel olma.</p>
            <p>c) Belirli, açık ve meşru amaçlar için işlenme.</p>
            <p>ç) İşlendikleri amaçla bağlantılı, sınırlı ve ölçülü olma.</p>
            <p>
              d) İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli
              olan süre kadar muhafaza
            </p>
            <p>edilme.</p>
            <p>Kişisel verilerin işlenme şartları</p>
            <p>
              MADDE 5- (1) Kişisel veriler ilgili kişinin açık rızası olmaksızın
              işlenemez.
            </p>
            <p>
              (2) Aşağıdaki şartlardan birinin varlığı hâlinde, ilgili kişinin
              açık rızası aranmaksızın
            </p>
            <p>kişisel verilerinin işlenmesi mümkündür:</p>
            <p>a) Kanunlarda açıkça öngörülmesi.</p>
            <p>
              b) Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda
              bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin
              kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün
              korunması için zorunlu olması.
            </p>
            <p>
              c) Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
              olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
              işlenmesinin gerekli olması.
            </p>
            <p>
              ç) Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi
              için zorunlu olması.
            </p>
            <p>d) İlgili kişinin kendisi tarafından alenileştirilmiş olması.</p>
            <p>
              e) Bir hakkın tesisi, kullanılması veya korunması için veri
              işlemenin zorunlu olması.
            </p>
            <p>
              f) İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
              kaydıyla, veri sorumlusunun meşru menfaatleri için veri
              işlenmesinin zorunlu olması.
            </p>
            <p>Özel nitelikli kişisel verilerin işlenme şartları</p>
            <p>
              MADDE 6- (1) Kişilerin ırkı, etnik kökeni, siyasi düşüncesi,
              felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve
              kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel
              hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri
              ile biyometrik ve genetik verileri özel nitelikli kişisel veridir.
            </p>
            <p>
              (2) Özel nitelikli kişisel verilerin, ilgilinin açık rızası
              olmaksızın işlenmesi yasaktır.
            </p>
            <p>
              (3) Birinci fıkrada sayılan sağlık ve cinsel hayat dışındaki
              kişisel veriler, kanunlarda öngörülen hâllerde ilgili kişinin açık
              rızası aranmaksızın işlenebilir. Sağlık ve cinsel hayata ilişkin
              kişisel veriler ise ancak kamu sağlığının korunması, koruyucu
              hekimlik, tıbbî teşhis, tedavi ve bakım hizmetlerinin yürütülmesi,
              sağlık hizmetleri ile finansmanının planlanması ve yönetimi
              amacıyla, sır saklama yükümlülüğü altında bulunan kişiler veya
              yetkili kurum ve kuruluşlar tarafından ilgilinin açık rızası
              aranmaksızın işlenebilir.
            </p>
            <p>
              (4) Özel nitelikli kişisel verilerin işlenmesinde, ayrıca Kurul
              tarafından belirlenen yeterli önlemlerin alınması şarttır.
            </p>
            <p>
              Kişisel verilerin silinmesi, yok edilmesi veya anonim hâle
              getirilmesi
            </p>
            <p>
              MADDE 7- (1) Bu Kanun ve ilgili diğer kanun hükümlerine uygun
              olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin
              ortadan kalkması hâlinde kişisel veriler resen veya ilgili kişinin
              talebi üzerine veri sorumlusu tarafından silinir, yok edilir veya
              anonim hâle getirilir.
            </p>
            <p>
              (2) Kişisel verilerin silinmesi, yok edilmesi veya anonim hâle
              getirilmesine ilişkin diğer kanunlarda yer alan hükümler saklıdır.
            </p>
            <p>
              (3) Kişisel verilerin silinmesine, yok edilmesine veya anonim hâle
              getirilmesine ilişkin usul ve esaslar yönetmelikle düzenlenir.
            </p>
            <p>Kişisel verilerin aktarılması</p>
            <p>
              MADDE 8- (1) Kişisel veriler, ilgili kişinin açık rızası
              olmaksızın aktarılamaz.
            </p>
            <p>(2) Kişisel veriler;</p>
            <p>a) 5 inci maddenin ikinci fıkrasında,</p>
            <p>
              b) Yeterli önlemler alınmak kaydıyla, 6 ncı maddenin üçüncü
              fıkrasında,
            </p>
            <p>
              belirtilen şartlardan birinin bulunması hâlinde, ilgili kişinin
              açık rızası aranmaksızın aktarılabilir.
            </p>
            <p>
              (3) Kişisel verilerin aktarılmasına ilişkin diğer kanunlarda yer
              alan hükümler saklıdır.
            </p>
            <p>Kişisel verilerin yurt dışına aktarılması</p>
            <p>
              MADDE 9- (1) Kişisel veriler, ilgili kişinin açık rızası
              olmaksızın yurt dışına aktarılamaz.
            </p>
            <p>
              (2) Kişisel veriler, 5 inci maddenin ikinci fıkrası ile 6 ncı
              maddenin üçüncü fıkrasında belirtilen şartlardan birinin varlığı
              ve kişisel verinin aktarılacağı yabancı ülkede;
            </p>
            <p>a) Yeterli korumanın bulunması,</p>
            <p>
              b) Yeterli korumanın bulunmaması durumunda Türkiye’deki ve ilgili
              yabancı ülkedeki veri sorumlularının yeterli bir korumayı yazılı
              olarak taahhüt etmeleri ve Kurulun izninin bulunması,
            </p>
            <p>
              kaydıyla ilgili kişinin açık rızası aranmaksızın yurt dışına
              aktarılabilir.
            </p>
            <p>
              (3) Yeterli korumanın bulunduğu ülkeler Kurulca belirlenerek ilan
              edilir.
            </p>
            <p>
              (4) Kurul yabancı ülkede yeterli koruma bulunup bulunmadığına ve
              ikinci fıkranın (b) bendi uyarınca izin verilip verilmeyeceğine;
            </p>
            <p>a) Türkiye’nin taraf olduğu uluslararası sözleşmeleri,</p>
            <p>
              b) Kişisel veri talep eden ülke ile Türkiye arasında veri
              aktarımına ilişkin karşılıklılık durumunu,
            </p>
            <p>
              c) Her somut kişisel veri aktarımına ilişkin olarak, kişisel
              verinin niteliği ile işlenme amaç ve süresini,
            </p>
            <p>
              ç) Kişisel verinin aktarılacağı ülkenin konuyla ilgili mevzuatı ve
              uygulamasını,
            </p>
            <p>
              d) Kişisel verinin aktarılacağı ülkede bulunan veri sorumlusu
              tarafından taahhüt edilen önlemleri,
            </p>
            <p>
              değerlendirmek ve ihtiyaç duyması hâlinde, ilgili kurum ve
              kuruluşların görüşünü de almak suretiyle karar verir.
            </p>
            <p>
              (5) Kişisel veriler, uluslararası sözleşme hükümleri saklı kalmak
              üzere, Türkiye’nin veya ilgili kişinin menfaatinin ciddi bir
              şekilde zarar göreceği durumlarda, ancak ilgili kamu kurum veya
              kuruluşunun görüşü alınarak Kurulun izniyle yurt dışına
              aktarılabilir.
            </p>
            <p>
              (6) Kişisel verilerin yurt dışına aktarılmasına ilişkin diğer
              kanunlarda yer alan hükümler saklıdır.
            </p>
            <p>ÜÇÜNCÜ BÖLÜM</p>
            <p>
              Haklar ve Yükümlülükler Veri sorumlusunun aydınlatma yükümlülüğü
            </p>
            <p>
              MADDE 10- (1) Kişisel verilerin elde edilmesi sırasında veri
              sorumlusu veya yetkilendirdiği kişi, ilgili kişilere;
            </p>
            <p>a) Veri sorumlusunun ve varsa temsilcisinin kimliği,</p>
            <p>b) Kişisel verilerin hangi amaçla işleneceği,</p>
            <p>
              c) İşlenen kişisel verilerin kimlere ve hangi amaçla
              aktarılabileceği, ç) Kişisel veri toplamanın yöntemi ve hukuki
              sebebi,
            </p>
            <p>
              d) 11 inci maddede sayılan diğer hakları, konusunda bilgi vermekle
              yükümlüdür. İlgili kişinin hakları
            </p>
            <p>
              MADDE 11- (1) Herkes, veri sorumlusuna başvurarak kendisiyle
              ilgili;
            </p>
            <p>a) Kişisel veri işlenip işlenmediğini öğrenme,</p>
            <p>b) Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</p>
            <p>
              c) Kişisel verilerin işlenme amacını ve bunların amacına uygun
              kullanılıp kullanılmadığını öğrenme,
            </p>
            <p>
              ç) Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
              üçüncü kişileri bilme,
            </p>
            <p>
              d) Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
              bunların düzeltilmesini
            </p>
            <p>isteme,</p>
            <p>
              e) 7 nci maddede öngörülen şartlar çerçevesinde kişisel verilerin
              silinmesini veya yok edilmesini isteme,
            </p>
            <p>
              f) (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel
              verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
            </p>
            <p>
              g) İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
              analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun
              ortaya çıkmasına itiraz etme,
            </p>
            <p>
              ğ) Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
              zarara uğraması hâlinde zararın giderilmesini talep etme,
            </p>
            <p>haklarına sahiptir.</p>
            <p>
              Veri güvenliğine ilişkin yükümlülükler MADDE 12- (1) Veri
              sorumlusu;
            </p>
            <p>
              a) Kişisel verilerin hukuka aykırı olarak işlenmesini önlemek,
            </p>
            <p>
              b) Kişisel verilere hukuka aykırı olarak erişilmesini önlemek,
            </p>
            <p>c) Kişisel verilerin muhafazasını sağlamak,</p>
            <p>
              amacıyla uygun güvenlik düzeyini temin etmeye yönelik gerekli her
              türlü teknik ve idari tedbirleri almak zorundadır.
            </p>
            <p>
              (2) Veri sorumlusu, kişisel verilerin kendi adına başka bir gerçek
              veya tüzel kişi tarafından işlenmesi hâlinde, birinci fıkrada
              belirtilen tedbirlerin alınması hususunda bu kişilerle birlikte
              müştereken sorumludur.
            </p>
            <p>
              (3) Veri sorumlusu, kendi kurum veya kuruluşunda, bu Kanun
              hükümlerinin uygulanmasını sağlamak amacıyla gerekli denetimleri
              yapmak veya yaptırmak zorundadır.
            </p>
            <p>
              (4) Veri sorumluları ile veri işleyen kişiler, öğrendikleri
              kişisel verileri bu Kanun hükümlerine aykırı olarak başkasına
              açıklayamaz ve işleme amacı dışında kullanamazlar. Bu yükümlülük
              görevden ayrılmalarından sonra da devam eder.
            </p>
            <p>
              (5) İşlenen kişisel verilerin kanuni olmayan yollarla başkaları
              tarafından elde edilmesi hâlinde, veri sorumlusu bu durumu en kısa
              sürede ilgilisine ve Kurula bildirir. Kurul, gerekmesi hâlinde bu
              durumu, kendi internet sitesinde ya da uygun göreceği başka bir
              yöntemle ilan edebilir.
            </p>
            <p>DÖRDÜNCÜ BÖLÜM</p>
            <p>
              Başvuru, Şikâyet ve Veri Sorumluları Sicili Veri sorumlusuna
              başvuru
            </p>
            <p>
              MADDE 13- (1) İlgili kişi, bu Kanunun uygulanmasıyla ilgili
              taleplerini yazılı olarak veya Kurulun belirleyeceği diğer
              yöntemlerle veri sorumlusuna iletir.
            </p>
            <p>
              (2) Veri sorumlusu başvuruda yer alan talepleri, talebin
              niteliğine göre en kısa sürede ve en geç otuz gün içinde ücretsiz
              olarak sonuçlandırır. Ancak, işlemin ayrıca bir maliyeti
              gerektirmesi hâlinde, Kurulca belirlenen tarifedeki ücret
              alınabilir.
            </p>
            <p>
              (3) Veri sorumlusu talebi kabul eder veya gerekçesini açıklayarak
              reddeder ve cevabını ilgili kişiye yazılı olarak veya elektronik
              ortamda bildirir. Başvuruda yer alan talebin kabul edilmesi
              hâlinde veri sorumlusunca gereği yerine getirilir. Başvurunun veri
              sorumlusunun hatasından kaynaklanması hâlinde alınan ücret
              ilgiliye iade edilir.
            </p>
            <p>Kurula şikâyet</p>
            <p>
              MADDE 14- (1) Başvurunun reddedilmesi, verilen cevabın yetersiz
              bulunması veya süresinde başvuruya cevap verilmemesi hâllerinde;
              ilgili kişi, veri sorumlusunun cevabını öğrendiği tarihten
              itibaren otuz ve her hâlde başvuru tarihinden itibaren altmış gün
              içinde Kurula şikâyette bulunabilir.
            </p>
            <p>
              (2) 13 üncü madde uyarınca başvuru yolu tüketilmeden şikâyet
              yoluna başvurulamaz.
            </p>
            <p>
              (3) Kişilik hakları ihlal edilenlerin, genel hükümlere göre
              tazminat hakkı saklıdır.
            </p>
            <p>Şikâyet üzerine veya resen incelemenin usul ve esasları</p>
            <p>
              MADDE 15- (1) Kurul, şikâyet üzerine veya ihlal iddiasını
              öğrenmesi durumunda resen, görev alanına giren konularda gerekli
              incelemeyi yapar.
            </p>
            <p>
              (2) 1/11/1984 tarihli ve 3071 sayılı Dilekçe Hakkının
              Kullanılmasına Dair Kanunun 6 ncı maddesinde belirtilen şartları
              taşımayan ihbar veya şikâyetler incelemeye alınmaz.
            </p>
            <p>
              (3) Devlet sırrı niteliğindeki bilgi ve belgeler hariç; veri
              sorumlusu, Kurulun, inceleme konusuyla ilgili istemiş olduğu bilgi
              ve belgeleri on beş gün içinde göndermek ve gerektiğinde yerinde
              inceleme yapılmasına imkân sağlamak zorundadır.
            </p>
            <p>
              (4) Şikâyet üzerine Kurul, talebi inceleyerek ilgililere bir cevap
              verir. Şikâyet tarihinden itibaren altmış gün içinde cevap
              verilmezse talep reddedilmiş sayılır.
            </p>
            <p>
              (5) Şikâyet üzerine veya resen yapılan inceleme sonucunda, ihlalin
              varlığının anlaşılması hâlinde Kurul, tespit ettiği hukuka
              aykırılıkların veri sorumlusu tarafından giderilmesine karar
              vererek ilgililere tebliğ eder. Bu karar, tebliğden itibaren
              gecikmeksizin ve en geç otuz gün içinde yerine getirilir.
            </p>
            <p>
              (6) Şikâyet üzerine veya resen yapılan inceleme sonucunda, ihlalin
              yaygın olduğunun tespit edilmesi hâlinde Kurul, bu konuda ilke
              kararı alır ve bu kararı yayımlar. Kurul, ilke kararı almadan önce
              ihtiyaç duyması hâlinde, ilgili kurum ve kuruluşların görüşlerini
              de alabilir.
            </p>
            <p>
              (7) Kurul, telafisi güç veya imkânsız zararların doğması ve açıkça
              hukuka aykırılık olması hâlinde, veri işlenmesinin veya verinin
              yurt dışına aktarılmasının durdurulmasına karar verebilir.
            </p>
            <p>Veri Sorumluları Sicili</p>
            <p>
              MADDE 16- (1) Kurulun gözetiminde, Başkanlık tarafından kamuya
              açık olarak Veri Sorumluları Sicili tutulur.
            </p>
            <p>
              (2) Kişisel verileri işleyen gerçek ve tüzel kişiler, veri
              işlemeye başlamadan önce Veri Sorumluları Siciline kaydolmak
              zorundadır. Ancak, işlenen kişisel verinin niteliği, sayısı, veri
              işlemenin kanundan kaynaklanması veya üçüncü kişilere aktarılma
              durumu gibi Kurulca belirlenecek objektif kriterler göz önüne
              alınmak suretiyle, Kurul tarafından, Veri Sorumluları Siciline
              kayıt zorunluluğuna istisna getirilebilir.
            </p>
            <p>
              (3) Veri Sorumluları Siciline kayıt başvurusu aşağıdaki hususları
              içeren bir bildirimle
            </p>
            <p>yapılır:</p>
            <p>
              a) Veri sorumlusu ve varsa temsilcisinin kimlik ve adres
              bilgileri.
            </p>
            <p>b) Kişisel verilerin hangi amaçla işleneceği.</p>
            <p>
              c) Veri konusu kişi grubu ve grupları ile bu kişilere ait veri
              kategorileri hakkındaki açıklamalar.
            </p>
            <p>
              ç) Kişisel verilerin aktarılabileceği alıcı veya alıcı grupları.
            </p>
            <p>d) Yabancı ülkelere aktarımı öngörülen kişisel veriler.</p>
            <p>e) Kişisel veri güvenliğine ilişkin alınan tedbirler.</p>
            <p>
              f) Kişisel verilerin işlendikleri amaç için gerekli olan azami
              süre.
            </p>
            <p>
              (4) Üçüncü fıkra uyarınca verilen bilgilerde meydana gelen
              değişiklikler derhâl Başkanlığa bildirilir.
            </p>
            <p>
              (5) Veri Sorumluları Siciline ilişkin diğer usul ve esaslar
              yönetmelikle düzenlenir.
            </p>
            <p>BEŞİNCİ BÖLÜM</p>
            <p>Suçlar ve Kabahatler</p>
            <p>Suçlar</p>
            <p>
              MADDE 17- (1) Kişisel verilere ilişkin suçlar bakımından 26/9/2004
              tarihli ve 5237 sayılı Türk Ceza Kanununun 135 ila 140 ıncı madde
              hükümleri uygulanır.
            </p>
            <p>
              (2) Bu Kanunun 7 nci maddesi hükmüne aykırı olarak; kişisel
              verileri silmeyen veya anonim hâle getirmeyenler 5237 sayılı
              Kanunun 138 inci maddesine göre cezalandırılır.
            </p>
            <p>Kabahatler</p>
            <p>MADDE 18- (1) Bu Kanunun;</p>
            <p>
              a) 10 uncu maddesinde öngörülen aydınlatma yükümlülüğünü yerine
              getirmeyenler hakkında 5.000 Türk lirasından 100.000 Türk lirasına
              kadar,
            </p>
            <p>
              b) 12 nci maddesinde öngörülen veri güvenliğine ilişkin
              yükümlülükleri yerine getirmeyenler hakkında 15.000 Türk
              lirasından 1.000.000 Türk lirasına kadar,
            </p>
            <p>
              c) 15 inci maddesi uyarınca Kurul tarafından verilen kararları
              yerine getirmeyenler hakkında 25.000 Türk lirasından 1.000.000
              Türk lirasına kadar,
            </p>
            <p>
              ç) 16 ncı maddesinde öngörülen Veri Sorumluları Siciline kayıt ve
              bildirim yükümlülüğüne aykırı hareket edenler hakkında 20.000 Türk
              lirasından 1.000.000 Türk lirasına kadar,
            </p>
            <p>idari para cezası verilir.</p>
            <p>
              (2) Bu maddede öngörülen idari para cezaları veri sorumlusu olan
              gerçek kişiler ile özel hukuk tüzel kişileri hakkında uygulanır.
            </p>
            <p>
              (3) Birinci fıkrada sayılan eylemlerin kamu kurum ve kuruluşları
              ile kamu kurumu niteliğindeki meslek kuruluşları bünyesinde
              işlenmesi hâlinde, Kurulun yapacağı bildirim üzerine, ilgili kamu
              kurum ve kuruluşunda görev yapan memurlar ve diğer kamu
              görevlileri ile kamu kurumu niteliğindeki meslek kuruluşlarında
              görev yapanlar hakkında disiplin hükümlerine göre işlem yapılır ve
              sonucu Kurula bildirilir.
            </p>
            <p>ALTINCI BÖLÜM</p>
            <p>
              Kişisel Verileri Koruma Kurumu ve Teşkilat Kişisel Verileri Koruma
              Kurumu
            </p>
            <p>
              MADDE 19- (1) Bu Kanunla verilen görevleri yerine getirmek üzere,
              idari ve mali özerkliğe sahip ve kamu tüzel kişiliğini haiz
              Kişisel Verileri Koruma Kurumu kurulmuştur.
            </p>
            <p>
              (2) Kurum Cumhurbaşkanının görevlendireceği bakan ile ilişkilidir.
              (1)
            </p>
            <p>(3) Kurumun merkezi Ankara’dadır.</p>
            <p>
              (4) Kurum, Kurul ve Başkanlıktan oluşur. Kurumun karar organı
              Kuruldur.
            </p>
            <p>Kurumun görevleri</p>
            <p>MADDE 20- (1) Kurumun görevleri şunlardır:</p>
            <p>
              a) Görev alanı itibarıyla, uygulamaları ve mevzuattaki gelişmeleri
              takip etmek, değerlendirme ve önerilerde bulunmak, araştırma ve
              incelemeler yapmak veya yaptırmak.
            </p>
            <p>
              b) İhtiyaç duyulması hâlinde, görev alanına giren konularda kamu
              kurum ve kuruluşları, sivil toplum kuruluşları, meslek örgütleri
              veya üniversitelerle iş birliği yapmak.
            </p>
            <p>
              c) Kişisel verilerle ilgili uluslararası gelişmeleri izlemek ve
              değerlendirmek, görev alanına giren konularda uluslararası
              kuruluşlarla iş birliği yapmak, toplantılara katılmak.
            </p>
            <p>
              ç) Yıllık faaliyet raporunu Cumhurbaşkanlığına, Türkiye Büyük
              Millet Meclisi İnsan Haklarını İnceleme Komisyonuna (…) (2)
              sunmak. (2)
            </p>
            <p>d) Kanunlarla verilen diğer görevleri yerine getirmek.</p>
            <p>Kişisel Verileri Koruma Kurulu (3)</p>
            <p>
              MADDE 21- (1) Kurul, bu Kanunla ve diğer mevzuatla verilen görev
              ve yetkilerini kendi sorumluluğu altında, bağımsız olarak yerine
              getirir ve kullanır. Görev alanına giren konularla ilgili olarak
              hiçbir organ, makam, merci veya kişi, Kurula emir ve talimat
              veremez, tavsiye veya telkinde bulunamaz.
            </p>
            <p>
              (2) Kurul, dokuz üyeden oluşur. Kurulun beş üyesi Türkiye Büyük
              Millet Meclisi, dört üyesi Cumhurbaşkanı tarafından seçilir. (3)
            </p>
            <p>(3) Kurula üye olabilmek için aşağıdaki şartlar aranır:</p>
            <p>
              a) Kurumun görev alanındaki konularda bilgi ve deneyim sahibi
              olmak.
            </p>
            <p>
              b) 14/7/1965 tarihli ve 657 sayılı Devlet Memurları Kanununun 48
              inci maddesinin birinci fıkrasının
            </p>
            <p>
              (A) bendinin (1), (4), (5), (6) ve (7) numaralı alt bentlerinde
              belirtilen nitelikleri taşımak.
            </p>
            <p>c) Herhangi bir siyasi parti üyesi olmamak.</p>
            <p>
              ç) En az dört yıllık lisans düzeyinde yükseköğrenim görmüş olmak.
              d) (Mülga: 2/7/2018-KHK-703/163 md.)
            </p>
            <p>–––––––––––––––––</p>
            <p>
              (1) 2/7/2018 tarihli ve 703 sayılı KHK’nin 163 üncü maddesiyle, bu
              fıkrada yer alan “Başbakanlıkla” ibaresi “Cumhurbaşkanının
              görevlendireceği bakan ile” şeklinde değiştirilmiştir.
            </p>
            <p>
              (2) 2/7/2018 tarihli ve 703 sayılı KHK’nin 163 üncü maddesiyle, bu
              bentte yer alan “ ve Başbakanlığa” ibaresi yürürlükten
              kaldırılmıştır.
            </p>
            <p>
              (3) 2/7/2018 tarihli ve 703 sayılı KHK’nin 163 üncü maddesiyle, bu
              maddenin ikinci fıkrasında yer alan “iki üyesi Cumhurbaşkanı, iki
              üyesi Bakanlar Kurulu” ibaresi “dört üyesi Cumhurbaşkanı” şeklinde
              değiştirilmiştir.
            </p>
            <p>(4) (Mülga: 2/7/2018-KHK-703/163 md.)</p>
            <p>
              (5) Türkiye Büyük Millet Meclisi, Kurula üye seçimini aşağıdaki
              usulle yapar:
            </p>
            <p>
              a) Seçim için, siyasi parti gruplarının üye sayısı oranında
              belirlenecek üye sayısının ikişer katı aday gösterilir ve Kurul
              üyeleri bu adaylar arasından her siyasi parti grubuna düşen üye
              sayısı esas alınmak suretiyle Türkiye Büyük Millet Meclisi Genel
              Kurulunca seçilir. Ancak, siyasi parti gruplarında, Türkiye Büyük
              Millet Meclisinde yapılacak seçimlerde kime oy kullanılacağına
              dair görüşme yapılamaz ve karar alınamaz.
            </p>
            <p>
              b) Kurul üyelerinin seçimi, adayların belirlenerek ilanından sonra
              on gün içinde yapılır. Siyasi parti grupları tarafından gösterilen
              adaylar için ayrı ayrı listeler hâlinde birleşik oy pusulası
              düzenlenir. Adayların adlarının karşısındaki özel yer işaretlenmek
              suretiyle oy kullanılır. Siyasi parti gruplarının ikinci fıkraya
              göre belirlenen kontenjanlarından Kurula seçilecek üyelerin
              sayısından fazla verilen oylar geçersiz sayılır.
            </p>
            <p>
              c) Karar yeter sayısı olmak şartıyla seçimde en çok oyu alan boş
              üyelik sayısı kadar aday seçilmiş olur.
            </p>
            <p>
              ç) Üyelerin görev sürelerinin bitiminden iki ay önce; üyeliklerde
              herhangi bir sebeple boşalma olması hâlinde, boşalma tarihinden
              veya boşalma tarihinde Türkiye Büyük Millet Meclisi tatilde ise
              tatilin bitiminden itibaren bir ay içinde aynı usulle seçim
              yapılır. Bu seçimlerde, boşalan üyeliklerin siyasi parti
              gruplarına dağılımı, ilk seçimde siyasi parti grupları
              kontenjanından seçilen üye sayısı ve siyasi parti gruplarının
              hâlihazırdaki oranı dikkate alınmak suretiyle yapılır.
            </p>
            <p>
              (6) Cumhurbaşkanı (…) (1) tarafından seçilen üyelerden birinin
              görev süresinin bitiminden kırk beş gün önce veya herhangi bir
              sebeple görevin sona ermesi hâlinde durum, on beş gün içinde Kurum
              tarafından, Cumhurbaşkanlığına (…)(1) bildirilir. Üyelerin görev
              süresinin dolmasına bir ay kala yeni üye seçimi yapılır. Bu
              üyeliklerde, görev süresi dolmadan herhangi bir sebeple boşalma
              olması hâlinde ise bildirimden itibaren on beş gün içinde seçim
              yapılır. (1)
            </p>
            <p>
              (7) Kurul, üyeleri arasından Başkan ve İkinci Başkanı seçer.
              Kurulun Başkanı, Kurumun da başkanıdır.
            </p>
            <p>
              (8) Kurul üyelerinin görev süresi dört yıldır. Süresi biten üye
              yeniden seçilebilir. Görev süresi dolmadan herhangi bir sebeple
              görevi sona eren üyenin yerine seçilen kişi, yerine seçildiği
              üyenin kalan süresini tamamlar.
            </p>
            <p>
              (9) Seçilen üyeler Yargıtay Birinci Başkanlık Kurulu huzurunda
              “Görevimi Anayasaya ve kanunlara uygun olarak, tam bir
              tarafsızlık, dürüstlük, hakkaniyet ve adalet anlayışı içinde
              yerine getireceğime, namusum ve şerefim üzerine yemin ederim.”
              şeklinde yemin ederler. Yargıtaya yemin için yapılan başvuru acele
              işlerden sayılır.
            </p>
            <p>––––––––––––––––</p>
            <p>
              (1) 2/7/2018 tarihli ve 703 sayılı KHK’nin 163 üncü maddesiyle, bu
              fıkrada yer alan “veya Bakanlar Kurulu” ve “veya Bakanlar Kuruluna
              sunulmak üzere Başbakanlığa” ibareleri madde metninden
              çıkarılmıştır.
            </p>
            <p>
              (10) Kurul üyeleri özel bir kanuna dayanmadıkça, Kuruldaki resmî
              görevlerinin yürütülmesi dışında resmî veya özel hiçbir görev
              alamaz, dernek, vakıf, kooperatif ve benzeri yerlerde yöneticilik
              yapamaz, ticaretle uğraşamaz, serbest meslek faaliyetinde
              bulunamaz, hakemlik ve bilirkişilik yapamazlar. Ancak, Kurul
              üyeleri, asli görevlerini aksatmayacak şekilde bilimsel amaçlı
              yayın yapabilir, ders ve konferans verebilir ve bunlardan doğacak
              telif hakları ile ders ve konferans ücretlerini alabilirler.
            </p>
            <p>
              (11) Üyelerin görevleri sebebiyle işledikleri iddia edilen suçlara
              ilişkin soruşturmalar 2/12/1999 tarihli ve 4483 sayılı Memurlar ve
              Diğer Kamu Görevlilerinin Yargılanması Hakkında Kanuna göre
              yapılır ve bunlar hakkında soruşturma izni Cumhurbaşkanı
              tarafından verilir. (1)
            </p>
            <p>
              (12) Kurul üyeleri hakkında yapılacak disiplin soruşturması ve
              kovuşturmasında 657 sayılı Kanun hükümleri uygulanır.
            </p>
            <p>
              (13) Kurul üyelerinin süreleri dolmadan herhangi bir nedenle
              görevlerine son verilemez. Kurul üyelerinin;
            </p>
            <p>
              a) Seçilmek için gereken şartları taşımadıklarının sonradan
              anlaşılması,
            </p>
            <p>
              b) Görevleriyle ilgili olarak işledikleri suçlardan dolayı
              haklarında verilen mahkûmiyet kararının kesinleşmesi,
            </p>
            <p>
              c) Görevlerini yerine getiremeyeceklerinin sağlık kurulu raporuyla
              kesin olarak tespit edilmesi,
            </p>
            <p>
              ç) Görevlerine izinsiz, mazeretsiz ve kesintisiz olarak on beş gün
              ya da bir yılda toplam otuz gün süreyle devam etmediklerinin
              tespit edilmesi,
            </p>
            <p>
              d) Bir ay içinde izinsiz ve mazeretsiz olarak toplam üç, bir yıl
              içinde toplam on Kurul toplantısına katılmadıklarının tespit
              edilmesi,
            </p>
            <p>hâllerinde Kurul kararıyla üyelikleri sona erer.</p>
            <p>
              (14) Kurul üyeliğine seçilenlerin Kurulda görev yaptıkları sürece
              önceki görevleri ile olan ilişikleri kesilir. Kamu görevlisi iken
              üyeliğe seçilenler, memuriyete giriş şartlarını kaybetmemeleri
              kaydıyla, görev sürelerinin sona ermesi veya görevden ayrılma
              isteğinde bulunmaları ve otuz gün içinde eski kurumlarına
              başvurmaları durumunda atamaya yetkili makam tarafından bir ay
              içinde mükteseplerine uygun bir kadroya atanır. Atama
              gerçekleşinceye kadar, bunların almakta oldukları her türlü
              ödemelerin Kurum tarafından ödenmesine devam olunur. Bir kamu
              kurumunda çalışmayanlardan üyeliğe seçilip yukarıda belirtilen
              şekilde görevi sona erenlere herhangi bir görev veya işe
              başlayıncaya kadar, almakta oldukları her türlü ödemeler Kurum
              tarafından ödenmeye devam edilir ve bu şekilde üyeliği sona
              erenlere Kurum tarafından yapılacak ödeme üç ayı geçemez. Bunların
              Kurumda geçirdiği süreler, özlük ve diğer hakları açısından önceki
              kurum veya kuruluşlarında geçirilmiş sayılır.
            </p>
            <p>Kurulun görev ve yetkileri</p>
            <p>MADDE 22- (1) Kurulun görev ve yetkileri şunlardır:</p>
            <p>
              a) Kişisel verilerin, temel hak ve özgürlüklere uygun şekilde
              işlenmesini sağlamak.
            </p>
            <p>
              b) Kişisel verilerle ilgili haklarının ihlal edildiğini ileri
              sürenlerin şikâyetlerini karara bağlamak.
            </p>
            <p>––––––––––––––––</p>
            <p>
              (1) 2/7/2018 tarihli ve 703 sayılı KHK’nin 163 üncü maddesiyle, bu
              fıkrada yer alan “Başbakan” ibaresi “Cumhurbaşkanı” şeklinde
              değiştirilmiştir.
            </p>
            <p>
              c) Şikâyet üzerine veya ihlal iddiasını öğrenmesi durumunda resen
              görev alanına giren konularda kişisel verilerin kanunlara uygun
              olarak işlenip işlenmediğini incelemek ve gerektiğinde bu konuda
              geçici önlemler almak.
            </p>
            <p>
              ç) Özel nitelikli kişisel verilerin işlenmesi için aranan yeterli
              önlemleri belirlemek.
            </p>
            <p>d) Veri Sorumluları Sicilinin tutulmasını sağlamak.</p>
            <p>
              e) Kurulun görev alanı ile Kurumun işleyişine ilişkin konularda
              gerekli düzenleyici işlemleri yapmak.
            </p>
            <p>
              f) Veri güvenliğine ilişkin yükümlülükleri belirlemek amacıyla
              düzenleyici işlem yapmak.
            </p>
            <p>
              g) Veri sorumlusunun ve temsilcisinin görev, yetki ve
              sorumluluklarına ilişkin düzenleyici işlem yapmak.
            </p>
            <p>ğ) Bu Kanunda öngörülen idari yaptırımlara karar vermek.</p>
            <p>
              h) Diğer kurum ve kuruluşlarca hazırlanan ve kişisel verilere
              ilişkin hüküm içeren mevzuat taslakları hakkında görüş bildirmek.
            </p>
            <p>
              ı) Kurumun; stratejik planını karara bağlamak, amaç ve
              hedeflerini, hizmet kalite standartlarını ve performans
              kriterlerini belirlemek.
            </p>
            <p>
              i) Kurumun stratejik planı ile amaç ve hedeflerine uygun olarak
              hazırlanan bütçe teklifini görüşmek ve karara bağlamak.
            </p>
            <p>
              j) Kurumun performansı, mali durumu, yıllık faaliyetleri ve
              ihtiyaç duyulan konular hakkında hazırlanan rapor taslaklarını
              onaylamak ve yayımlamak.
            </p>
            <p>
              k) Taşınmaz alımı, satımı ve kiralanması konularındaki önerileri
              görüşüp karara bağlamak.
            </p>
            <p>l) Kanunlarla verilen diğer görevleri yerine getirmek.</p>
            <p>Kurulun çalışma esasları</p>
            <p>
              MADDE 23- (1) Kurulun toplantı günlerini ve gündemini Başkan
              belirler. Başkan gereken hâllerde Kurulu olağanüstü toplantıya
              çağırabilir.
            </p>
            <p>
              (2) Kurul, başkan dâhil en az altı üye ile toplanır ve üye tam
              sayısının salt çoğunluğuyla karar alır. Kurul üyeleri çekimser oy
              kullanamaz.
            </p>
            <p>
              (3) Kurul üyeleri; kendilerini, üçüncü dereceye kadar kan ve
              ikinci dereceye kadar kayın hısımlarını, evlatlıklarını ve
              aralarındaki evlilik bağı kalkmış olsa bile eşlerini ilgilendiren
              konularla ilgili toplantı ve oylamaya katılamaz.
            </p>
            <p>
              (4) Kurul üyeleri çalışmaları sırasında ilgililere ve üçüncü
              kişilere ait öğrendikleri sırları bu konuda kanunen yetkili
              kılınan mercilerden başkasına açıklayamazlar ve kendi yararlarına
              kullanamazlar. Bu yükümlülük görevden ayrılmalarından sonra da
              devam eder.
            </p>
            <p>
              (5) Kurulda görüşülen işler tutanağa bağlanır. Kararlar ve varsa
              karşı oy gerekçeleri karar tarihinden itibaren en geç on beş gün
              içinde yazılır. Kurul, gerekli gördüğü kararları kamuoyuna
              duyurur.
            </p>
            <p>
              (6) Aksi kararlaştırılmadıkça, Kurul toplantılarındaki görüşmeler
              gizlidir.
            </p>
            <p>
              (7) Kurulun çalışma usul ve esasları ile kararların yazımı ve
              diğer hususlar yönetmelikle düzenlenir.
            </p>
            <p>Başkan</p>
            <p>
              MADDE 24- (1) Başkan, Kurul ve Kurumun başkanı sıfatıyla Kurumun
              en üst amiri olup Kurum hizmetlerini mevzuata, Kurumun amaç ve
              politikalarına, stratejik planına, performans ölçütlerine ve
              hizmet kalite standartlarına uygun olarak düzenler, yürütür ve
              hizmet birimleri arasında koordinasyonu sağlar.
            </p>
            <p>
              (2) Başkan, Kurumun genel yönetim ve temsilinden sorumludur. Bu
              sorumluluk, Kurum çalışmalarının düzenlenmesi, yürütülmesi,
              denetlenmesi, değerlendirilmesi ve gerektiğinde kamuoyuna
              duyurulması görev ve yetkilerini kapsar.
            </p>
            <p>(3) Başkanın görevleri şunlardır:</p>
            <p>a) Kurul toplantılarını idare etmek.</p>
            <p>
              b) Kurul kararlarının tebliğini ve Kurulca gerekli görülenlerin
              kamuoyuna duyurulmasını sağlamak ve uygulanmalarını izlemek.
            </p>
            <p>
              c) Başkan Yardımcısını, daire başkanlarını ve Kurum personelini
              atamak. ç) Hizmet birimlerinden gelen önerilere son şeklini
              vererek Kurula sunmak.
            </p>
            <p>
              d) Stratejik planın uygulanmasını sağlamak, hizmet kalite
              standartları doğrultusunda insan kaynakları ve çalışma
              politikalarını oluşturmak.
            </p>
            <p>
              e) Belirlenen stratejilere, yıllık amaç ve hedeflere uygun olarak
              Kurumun yıllık bütçesi ile mali tablolarını hazırlamak.
            </p>
            <p>
              f) Kurul ve hizmet birimlerinin uyumlu, verimli, disiplinli ve
              düzenli bir biçimde çalışması amacıyla koordinasyonu sağlamak.
            </p>
            <p>g) Kurumun diğer kuruluşlarla ilişkilerini yürütmek.</p>
            <p>
              ğ) Kurum Başkanı adına imzaya yetkili personelin görev ve yetki
              alanını belirlemek.
            </p>
            <p>
              h) Kurumun yönetim ve işleyişine ilişkin diğer görevleri yerine
              getirmek.
            </p>
            <p>
              (4) Kurum Başkanının yokluğunda İkinci Başkan, Başkana vekalet
              eder.
            </p>
            <p>Başkanlığın oluşumu ve görevleri</p>
            <p>
              MADDE 25- (1) Başkanlık; Başkan Yardımcısı ve hizmet birimlerinden
              oluşur. Başkanlık, dördüncü fıkrada sayılan görevleri daire
              başkanlıkları şeklinde teşkilatlanan hizmet birimleri aracılığıyla
              yerine getirir. Daire başkanlıklarının sayısı yediyi geçemez.
            </p>
            <p>
              (2) Başkan tarafından, Kuruma ilişkin görevlerinde yardımcı olmak
              üzere bir Başkan Yardımcısı atanır.
            </p>
            <p>
              (3) Başkan Yardımcısı ve daire başkanları; en az dört yıllık
              yükseköğretim kurumu mezunu, on yıl süreyle kamu hizmetinde
              bulunan kişiler arasından Başkan tarafından atanır.
            </p>
            <p>(4) Başkanlığın görevleri şunlardır:</p>
            <p>a) Veri Sorumluları Sicilini tutmak.</p>
            <p>
              b) Kurumun ve Kurulun büro ve sekretarya işlemlerini yürütmek.
            </p>
            <p>
              c) Kurumun taraf olduğu davalar ile icra takiplerinde avukatlar
              vasıtasıyla Kurumu temsil etmek, davaları takip etmek veya
              ettirmek, hukuk hizmetlerini yürütmek.
            </p>
            <p>
              ç) Kurul üyeleri ile Kurumda görev yapanların özlük işlemlerini
              yürütmek.
            </p>
            <p>
              d) Kanunlarla mali hizmet ve strateji geliştirme birimlerine
              verilen görevleri yapmak.
            </p>
            <p>
              e) Kurumun iş ve işlemlerinin yürütülmesi amacıyla bilişim
              sisteminin kurulmasını ve kullanılmasını sağlamak.
            </p>
            <p>
              f) Kurulun yıllık faaliyetleri hakkında veya ihtiyaç duyulan
              konularda rapor taslaklarını hazırlamak ve Kurula sunmak.
            </p>
            <p>g) Kurumun stratejik plan taslağını hazırlamak.</p>
            <p>
              ğ) Kurumun personel politikasını belirlemek, personelin kariyer ve
              eğitim planlarını hazırlamak ve uygulamak.
            </p>
            <p>
              h) Personelin atama, nakil, disiplin, performans, terfi, emeklilik
              ve benzeri işlemlerini yürütmek.
            </p>
            <p>
              ı) Personelin uyacağı etik kuralları belirlemek ve gerekli eğitimi
              vermek.
            </p>
            <p>
              i) 10/12/2003 tarihli ve 5018 sayılı Kamu Malî Yönetimi ve Kontrol
              Kanunu çerçevesinde Kurumun ihtiyacı olan her türlü satın alma,
              kiralama, bakım, onarım, yapım, arşiv, sağlık, sosyal ve benzeri
              hizmetleri yürütmek.
            </p>
            <p>j) Kuruma ait taşınır ve taşınmazların kayıtlarını tutmak.</p>
            <p>
              k) Kurul veya Başkan tarafından verilen diğer görevleri yapmak.
            </p>
            <p>
              (5) Hizmet birimleri ile bu birimlerin çalışma usul ve esasları,
              bu Kanunda belirtilen faaliyet alanı, görev ve yetkilere uygun
              olarak Kurumun teklifi üzerine Cumhurbaşkanınca yürürlüğe konulan
              yönetmelikle belirlenir. (1)
            </p>
            <p>Kişisel Verileri Koruma Uzmanı ve uzman yardımcıları</p>
            <p>
              MADDE 26- (1) Kurumda, Kişisel Verileri Koruma Uzmanı ve Kişisel
              Verileri Koruma Uzman Yardımcısı istihdam edilebilir. Bunlardan
              657 sayılı Kanunun ek 41 inci maddesi çerçevesinde Kişisel
              Verileri Koruma Uzmanı kadrosuna atananlara bir defaya mahsus
              olmak üzere bir derece yükseltilmesi uygulanır.
            </p>
            <p>Personele ve özlük haklarına ilişkin hükümler</p>
            <p>
              MADDE 27- (1) Kurum personeli, bu Kanunla düzenlenen hususlar
              dışında 657 sayılı Kanuna tabidir.
            </p>
            <p>
              (2) Kurul Başkan ve üyeleri ile Kurum personeline 27/6/1989
              tarihli ve 375 sayılı Kanun Hükmünde Kararnamenin ek 11 inci
              maddesi uyarınca belirlenmiş emsali personele mali ve sosyal
              haklar kapsamında yapılan ödemeler aynı usul ve esaslar
              çerçevesinde ödenir. Emsali personele yapılan ödemelerden vergi ve
              diğer yasal kesintilere tabi olmayanlar bu Kanuna göre de vergi ve
              diğer kesintilere tabi olmaz.
            </p>
            <p>
              (3) Kurul Başkan ve üyeleri ile Kurum personeli 31/5/2006 tarihli
              ve 5510 sayılı Sosyal Sigortalar ve Genel Sağlık Sigortası
              Kanununun 4 üncü maddesinin birinci fıkrasının (c) bendi
              hükümlerine tabidir. Kurul Başkan ve üyeleri ile Kurum personeli
              emeklilik hakları bakımından da emsali olarak belirlenen personel
              ile denk kabul edilir. 5510 sayılı Kanunun 4 üncü maddesinin
            </p>
            <p>–––––––––––––––––</p>
            <p>
              (1) 2/7/2018 tarihli ve 703 sayılı KHK’nin 163 üncü maddesiyle, bu
              fıkrada yer alan “Bakanlar Kurulu kararıyla” ibaresi
              “Cumhurbaşkanınca” şeklinde değiştirilmiştir.
            </p>
            <p>
              birinci fıkrasının (c) bendi kapsamında sigortalı iken Kurul
              Başkanı ve üyeliklerine atananlardan bu görevleri sona erenler
              veya bu görevlerinden ayrılma isteğinde bulunanların bu görevlerde
              geçen hizmet süreleri kazanılmış hak aylık, derece ve
              kademelerinin tespitinde dikkate alınır. Bunlardan bu görevleri
              sırasında 5510 sayılı Kanunun geçici 4 üncü maddesi kapsamına
              girenlerin bu görevlerde geçen süreleri makam tazminatı ile temsil
              tazminatı ödenmesi gereken süre olarak değerlendirilir. Kamu kurum
              ve kuruluşlarında 5510 sayılı Kanunun 4 üncü maddesinin birinci
              fıkrasının (a) bendi kapsamında sigortalı iken Kurul Başkanı ve
              üyeliklerine atananların, önceki kurum ve kuruluşları ile
              ilişiklerinin kesilmesi kendilerine kıdem tazminatı veya iş sonu
              tazminatı ödenmesini gerektirmez. Bu durumda olanların kıdem
              tazminatı veya iş sonu tazminatı ödenmesi gereken hizmet süreleri,
              Kurul Başkanı ile Kurul üyeliği olarak geçen hizmet süreleri ile
              birleştirilir ve emeklilik ikramiyesi ödenecek süre olarak
              değerlendirilir.
            </p>
            <p>
              (4) Merkezi yönetim kapsamındaki kamu idarelerinde, sosyal
              güvenlik kurumlarında, mahallî idarelerde, mahallî idarelere bağlı
              idarelerde, mahallî idare birliklerinde, döner sermayeli
              kuruluşlarda, kanunlarla kurulan fonlarda, kamu tüzel kişiliğini
              haiz kuruluşlarda, sermayesinin yüzde ellisinden fazlası kamuya
              ait kuruluşlarda, iktisadi devlet teşekkülleri ve kamu iktisadi
              kuruluşları ile bunlara bağlı ortaklıklar ve müesseselerde görevli
              memurlar ile diğer kamu görevlileri kurumlarının muvafakati,
              hâkimler ve savcılar ise kendilerinin muvafakati ile aylık,
              ödenek, her türlü zam ve tazminatlar ile diğer mali ve sosyal hak
              ve yardımları kurumlarınca ödenmek kaydıyla geçici olarak Kurumda
              görevlendirilebilir. Kurumun bu konudaki talepleri, ilgili kurum
              ve kuruluşlarca öncelikle sonuçlandırılır. Bu şekilde
              görevlendirilen personel, kurumlarından aylıklı izinli sayılır. Bu
              personelin izinli oldukları sürece memuriyetleri ile ilgileri ve
              özlük hakları devam ettiği gibi, bu süreler yükselme ve
              emekliliklerinde de hesaba katılır ve yükselmeleri başkaca bir
              işleme gerek duyulmadan süresinde yapılır. Bu madde kapsamında
              görevlendirilenlerin, Kurumda geçirdikleri süreler, kendi
              kurumlarında geçirilmiş sayılır. Bu şekilde görevlendirilenlerin
              sayısı Kişisel Verileri Koruma Uzmanı ve Kişisel Verileri Koruma
              Uzman Yardımcısı toplam kadro sayısının yüzde onunu aşamaz ve
              görevlendirme süresi iki yılı geçemez. Ancak ihtiyaç hâlinde bu
              süre bir yıllık dönemler hâlinde uzatılabilir. (1)
            </p>
            <p>
              (5) Kurumda istihdam edilecek personele ilişkin kadro unvan ve
              sayıları ekli (I) sayılı cetvelde gösterilmiştir. Toplam kadro
              sayısını geçmemek üzere 13/12/1983 tarihli ve 190 sayılı Genel
              Kadro ve Usulü Hakkında Kanun Hükmünde Kararnamenin eki
              cetvellerde yer alan kadro unvanlarıyla sınırlı olmak kaydıyla
              unvan ve derece değişikliği yapma, yeni unvan ekleme ve boş
              kadroların iptali Kurul kararıyla yapılır.
            </p>
            <p>YEDİNCİ BÖLÜM</p>
            <p>Çeşitli Hükümler</p>
            <p>İstisnalar</p>
            <p>
              MADDE 28- (1) Bu Kanun hükümleri aşağıdaki hâllerde uygulanmaz:
            </p>
            <p>
              a) Kişisel verilerin, üçüncü kişilere verilmemek ve veri
              güvenliğine ilişkin yükümlülüklere uyulmak kaydıyla gerçek kişiler
              tarafından tamamen kendisiyle veya aynı konutta yaşayan aile
              fertleriyle ilgili faaliyetler kapsamında işlenmesi.
            </p>
            <p>
              (1) 28/11/2017 tarihli ve 7061 sayılı Kanunun 119 uncu maddesiyle
              bu fıkrada yer alan “diğer kamu
            </p>
            <p>
              görevlileri kurumlarının muvafakati” ibaresinden sonra gelmek
              üzere “, hâkimler ve savcılar ise kendilerinin muvafakati” ibaresi
              eklenmiştir.
            </p>
            <p>
              b) Kişisel verilerin resmi istatistik ile anonim hâle getirilmek
              suretiyle araştırma, planlama ve istatistik gibi amaçlarla
              işlenmesi.
            </p>
            <p>
              c) Kişisel verilerin millî savunmayı, millî güvenliği, kamu
              güvenliğini, kamu düzenini, ekonomik güvenliği, özel hayatın
              gizliliğini veya kişilik haklarını ihlal etmemek ya da suç teşkil
              etmemek kaydıyla, sanat, tarih, edebiyat veya bilimsel amaçlarla
              ya da ifade özgürlüğü kapsamında işlenmesi.
            </p>
            <p>
              ç) Kişisel verilerin millî savunmayı, millî güvenliği, kamu
              güvenliğini, kamu düzenini veya ekonomik güvenliği sağlamaya
              yönelik olarak kanunla görev ve yetki verilmiş kamu kurum ve
              kuruluşları tarafından yürütülen önleyici, koruyucu ve istihbari
              faaliyetler kapsamında işlenmesi.
            </p>
            <p>
              d) Kişisel verilerin soruşturma, kovuşturma, yargılama veya infaz
              işlemlerine ilişkin olarak yargı makamları veya infaz mercileri
              tarafından işlenmesi.
            </p>
            <p>
              (2) Bu Kanunun amacına ve temel ilkelerine uygun ve orantılı olmak
              kaydıyla veri sorumlusunun aydınlatma yükümlülüğünü düzenleyen 10
              uncu, zararın giderilmesini talep etme hakkı hariç, ilgili kişinin
              haklarını düzenleyen 11 inci ve Veri Sorumluları Siciline kayıt
              yükümlülüğünü düzenleyen 16 ncı maddeleri aşağıdaki hâllerde
              uygulanmaz:
            </p>
            <p>
              a) Kişisel veri işlemenin suç işlenmesinin önlenmesi veya suç
              soruşturması için gerekli
            </p>
            <p>olması.</p>
            <p>
              b) İlgili kişinin kendisi tarafından alenileştirilmiş kişisel
              verilerin işlenmesi.
            </p>
            <p>
              c) Kişisel veri işlemenin kanunun verdiği yetkiye dayanılarak
              görevli ve yetkili kamu
            </p>
            <p>
              kurum ve kuruluşları ile kamu kurumu niteliğindeki meslek
              kuruluşlarınca, denetleme veya düzenleme görevlerinin yürütülmesi
              ile disiplin soruşturma veya kovuşturması için gerekli olması.
            </p>
            <p>
              ç) Kişisel veri işlemenin bütçe, vergi ve mali konulara ilişkin
              olarak Devletin ekonomik ve mali çıkarlarının korunması için
              gerekli olması.
            </p>
            <p>Kurumun bütçesi ve gelirleri</p>
            <p>
              MADDE 29- (1) Kurumun bütçesi, 5018 sayılı Kanunda belirlenen usul
              ve esaslara göre hazırlanır ve kabul edilir.
            </p>
            <p>(2) Kurumun gelirleri şunlardır:</p>
            <p>a) Genel bütçeden yapılacak hazine yardımları.</p>
            <p>b) Kuruma ait taşınır ve taşınmazlardan elde edilen gelirler.</p>
            <p>c) Alınan bağış ve yardımlar.</p>
            <p>ç) Gelirlerinin değerlendirilmesinden elde edilen gelirler.</p>
            <p>d) Diğer gelirler.</p>
            <p>Değiştirilen ve eklenen hükümler</p>
            <p>
              MADDE 30- (1) (10/12/2003 tarihli ve 5018 sayılı Kanun ile ilgili
              olup yerine işlenmiştir.)
            </p>
            <p>
              (2) ila (5) &#8211;(26/9/2004 tarihli ve 5237 sayılı Kanun ile
              ilgili olup yerine işlenmiştir.)
            </p>
            <p>
              (6) (7/5/1987 tarihli ve 3359 sayılı Sağlık Hizmetleri Temel
              Kanunu ile ilgili olup yerine işlenmiştir.)
            </p>
            <p>12316</p>
            <p>
              (7) (11/10/2011 tarihli ve 663 sayılı Sağlık Bakanlığı ve Bağlı
              Kuruluşlarının Teşkilat ve Görevleri Hakkında Kanun Hükmünde
              Kararname ile ilgili olup yerine işlenmiştir.)
            </p>
            <p>Yönetmelik</p>
            <p>
              MADDE 31- (1) Bu Kanunun uygulanmasına ilişkin yönetmelikler Kurum
              tarafından yürürlüğe konulur.
            </p>
            <p>Geçiş hükümleri</p>
            <p>
              GEÇİCİ MADDE 1- (1) Bu Kanunun yayımı tarihinden itibaren altı ay
              içinde 21 inci maddede öngörülen usule göre Kurul üyeleri seçilir
              ve Başkanlık teşkilatı oluşturulur.
            </p>
            <p>
              (2) Veri sorumluları, Kurul tarafından belirlenen ve ilan edilen
              süre içinde Veri Sorumluları Siciline kayıt yaptırmak zorundadır.
            </p>
            <p>
              (3) Bu Kanunun yayımı tarihinden önce işlenmiş olan kişisel
              veriler, yayımı tarihinden itibaren iki yıl içinde bu Kanun
              hükümlerine uygun hâle getirilir. Bu Kanun hükümlerine aykırı
              olduğu tespit edilen kişisel veriler derhâl silinir, yok edilir
              veya anonim hâle getirilir. Ancak bu Kanunun yayımı tarihinden
              önce hukuka uygun olarak alınmış rızalar, bir yıl içinde aksine
              bir irade beyanında bulunulmaması hâlinde, bu Kanuna uygun kabul
              edilir.
            </p>
            <p>
              (4) Bu Kanunda öngörülen yönetmelikler bu Kanunun yayımı
              tarihinden itibaren bir yıl içinde yürürlüğe konulur.
            </p>
            <p>
              (5) Bu Kanunun yayımı tarihinden itibaren bir yıl içinde, kamu
              kurum ve kuruluşlarında bu Kanunun uygulanmasıyla ilgili
              koordinasyonu sağlamak üzere üst düzey bir yönetici belirlenerek
              Başkanlığa bildirilir.
            </p>
            <p>
              (6) İlk seçilen Başkan, İkinci Başkan ve kura ile belirlenen iki
              üye altı yıl; diğer beş üye ise dört yıl görev yapar.
            </p>
            <p>(7) Kuruma bütçe tahsis edilene kadar;</p>
            <p>a) Kurumun giderleri Başbakanlık bütçesinden karşılanır.</p>
            <p>
              b) Kurumun hizmetlerini yerine getirmesi amacıyla bina, araç,
              gereç, mefruşat ve donanım gibi gerekli tüm destek hizmetleri
              Başbakanlıkça sağlanır.
            </p>
            <p>
              (8) Kurumun hizmet birimleri faaliyete geçinceye kadar sekretarya
              hizmetleri Başbakanlık tarafından yerine getirilir.
            </p>
            <p>GEÇİCİ MADDE 2- (Ek:28/11/2017-7061/120 md.)</p>
            <p>
              (1) En az dört yıllık lisans öğrenimi veren siyasal bilgiler,
              iktisadi ve idari bilimler, iktisat, hukuk ve işletme
              fakültelerinden, mühendislik fakültelerinin elektronik,
              elektrik-elektronik, elektronik ve haberleşme, bilgisayar, bilişim
              sistemleri mühendisliği bölümlerinden ya da bunlara denkliği
              Yükseköğretim Kurulu tarafından kabul edilen yurt içi ve yurt
              dışındaki yükseköğrenim kurumlarından mezun olanlardan; mesleğe
              özel yarışma sınavı ile girilen ve belirli süreli meslek içi
              eğitimden ve özel bir yeterlik sınavından sonra 657 sayılı Kanunun
              36 ncı maddesinin “Ortak Hükümler” başlıklı bölümünün (A)
              fıkrasının (11) numaralı bendinde belirtilen unvanlara ilişkin
              kurumların merkez teşkilatlarına ait kadrolara atanmış ve bu
              kadrolarda aylıksız izin süreleri hariç en az iki yıl bulunmuş
              olanlar ile öğretim üyesi kadrolarında bulunanlar, Yabancı Dil
              Bilgisi Seviye Tespit Sınavından en az yetmiş puan almış olmak ve
              atama tarihi itibarıyla kırk yaşından gün almamış olmak kaydıyla,
              bu maddenin yürürlüğe girdiği tarihten itibaren bir yıl içinde
              Kişisel Verileri Koruma Uzmanı olarak atanabilirler. Bu şekilde
              atanacakların sayısı on beşi geçemez.             
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default Register
