import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "../styles/landingpagesss.css";

function LandingPageSSS() {
  const [openSections, setOpenSections] = useState(Array(8).fill(false));
  const sectionRefs = useRef(
    Array.from({ length: 8 }, () => React.createRef())
  );
  const contentRefs = useRef(
    Array.from({ length: 8 }, () => React.createRef())
  );

  const toggleOpen = (index) => {
    setOpenSections((prevOpenSections) =>
      prevOpenSections.map((isOpen, i) => (i === index ? !isOpen : isOpen))
    );
  };

  const handleClickOutside = (event) => {
    sectionRefs.current.forEach((sectionRef, index) => {
      if (sectionRef.current && !sectionRef.current.contains(event.target)) {
        setOpenSections((prevOpenSections) =>
          prevOpenSections.map((isOpen, i) => (i === index ? false : isOpen))
        );
      }
    });
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    openSections.forEach((isOpen, index) => {
      if (isOpen) {
        contentRefs.current[index].current.style.maxHeight = `1000px`;
      } else {
        contentRefs.current[index].current.style.maxHeight = "0px";
      }
    });
  }, [openSections]);

  return (
    <div className="landingPageSSSContainer">
      <div className="landingPageSSSInner">
        <h5 className="landingPageSSSTitle">
          Sıkça{" "}
          <span className="landingPageSSSTitleColored">Sorulan Sorular</span>
        </h5>
        <div className="landingPageSSSContent">
          {[
            "Nobody Club nedir?",
            "Üyeliğime neler dahil?",
            "İçeriklere ne kadar süre erişeceğim?",
            "Kayıt ücretini taksitle ödeyebilir miyim?",
            "Eğitim içerikleri canlı mı yoksa kayıtlı videolar şeklinde mi?",
            "Kurs boyunca sorularım olursa ne yapacağım?",
            "18 yaşından küçükler katılabilir mi?",
            "İşimden istifa etmem gerekir mi?",
          ].map((question, index) => (
            <div
              key={index}
              ref={sectionRefs.current[index]}
              className={`expandable-section ${
                openSections[index] ? "open" : ""
              }`}
            >
              <div className="summary-title" onClick={() => toggleOpen(index)}>
                <span className="summary-title-text">{question}</span>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className={`chevron-icon ${
                    openSections[index] ? "rotate" : ""
                  }`}
                />
              </div>
              <div className="content" ref={contentRefs.current[index]}>
                {index === 0 &&
                  "NoBody Club hayatınızı arzu ettiğiniz noktaya ulaştırmanızı sağlayacak dijital ve geleneksel çözümleri sizinle paylaşan bir kazananlar kulübüdür."}
                {index === 1 &&
                  "NoBody Club ile 3 temel programımıza ve her ay güncellenecek yeni içeriklere ve programlara erişebileceksiniz. Sorularınızı 7/24 sorabileceğiniz bir kişisel koça sahip olacaksınız ve düzenli olarak yapılacak canlı yayınlara katılabileceksiniz."}
                {index === 2 &&
                  "NoBody Club’a erişim yıllık olarak gerçekleşmektedir. 97$ ödeme yaptıktan sonra 1 yıl içerisindeki mevcut içeriklere ve yeni çıkacak programlara ulaşabileceksiniz."}
                {index === 3 &&
                  "Evet, kredi kartınıza bağlı olarak 3 – 6 veya 12 ay seçenekleri ile taksitli bir şekilde buradan ödeyebilirsiniz."}
                {index === 4 &&
                  "NoBody Club hibrit sistem şeklindedir. Onlarca kayıtlı video, döküman ve çalışma dosyalarına ulaşabileceğiniz gibi canlı yayınlar ve 7/24 soru sorma gibi imkanlarınız da bulunmaktadır."}
                {index === 5 &&
                  "NoBody Club’a erişim yıllık olarak gerçekleşmektedir. 97$ ödeme yaptıktan sonra 1 yıl içerisindeki mevcut içeriklere ve yeni çıkacak programlara ulaşabileceksiniz."}
                {index === 6 &&
                  "NoBody Club her yaş grubunu bünyesinde barındıran ve sadece başarıyı hedefleyenler için tasarlanmış bir kazananlar kulübüdür. Tabii yine de 18 yaş altıysanız ebeveynlerinize danışmanızı tavsiye ederiz."}
                {index === 7 &&
                  "İşinizden istifa etmeniz elbette ki gerekmez. NoBody Club iş dışı vakitlerinizi en faydalı şekilde geçirip, mevcut işinizden elde ettiğiniz gelirden daha fazla gelir elde etmenizi amaçlamaktadır. Eğer işinizden memnun değilseniz de çalışkan ve sabırlı bir öğrenci olun ki onun da sırası gelsin."}
              </div>
            </div>
          ))}
        </div>
        <p className="landingPageSSSQuest">
          Daha fazla bilgiye mi ihtiyacınız var?
        </p>
        <button className="landingPageSSSButton">
          <FontAwesomeIcon icon={faWhatsapp} className="SSSWhatsappIcon" />{" "}
          Mesaj At
        </button>
      </div>
    </div>
  );
}

export default LandingPageSSS;
