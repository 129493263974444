import React from "react";
import "../styles/notfound.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();

  const goToLandingPage = () => {
    navigate("/");
  };
  return (
    <div className="notFoundContainer">
      <div className="notFoundInner">
        <div className="notFoundHeader">
          <h1 className="notFound404">404</h1>
          <p className="notFoundText">Üzgünüz, aradığın sayfayı bulamadık.</p>
        </div>
        <p className="notFoundCTA" onClick={goToLandingPage}>
          Açılış Sayfasına Git
          <FontAwesomeIcon className="notFoundCTAI" icon={faChevronRight} />
        </p>
      </div>
    </div>
  );
}

export default NotFound;
