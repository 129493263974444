import React from "react";
import "../styles/landingpagedontdo.css";

function LandingPageDontDo() {
  return (
    <div className="landingPageDontDoContainer">
      <div className="landingPageDontDoInner">
        <h6 className="landingPageDontDoHeader">YA DA HİÇBİR ŞEY YAPMA.</h6>
        <p className="landingPageDontDoDescription">
          Git saçma tv programlarını izle. Maaşlı işinden kovulmamak için
          patronuna yalvarmaya devam et.
        </p>
      </div>
    </div>
  );
}

export default LandingPageDontDo;
