import React, { useState, useEffect } from "react";
import "../styles/login.css";
import "../styles/loginResponsive.css";

import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../config/config";

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const goToForgotPassword = () => {
    navigate("/forgot-password-mail");
  };

  const goToRegister = () => {
    navigate("/register");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataa = Cookies.get("userData");

        if (!dataa) {
          return navigate("/login");
        }

        const ckdata = JSON.parse(dataa);

        const response = await fetch(`${config.API_URL}session`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            stringsession: ckdata.stringsession,
            username: ckdata.username,
          }),
        });

        const result = await response.json();

        if (result.status === "True") {
          navigate("/welcome");
        }
      } catch (err) {
        console.error("Fetch error:", err);
      }
    };

    fetchData();
  }, [navigate]);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async () => {
    const response = await fetch(`${config.API_URL}login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });

    const data = await response.json();

    if (data.status === "True") {
      Cookies.set("userData", JSON.stringify(data), { expires: 7 });
      navigate("/welcome", { state: { loginSuccess: true } });
    } else if (username === "") {
      toast.error("Lütfen kullanıcı adınızı giriniz.", {
        position: "top-right",
        autoClose: 5000,
      });
    } else if (password === "") {
      toast.error("Lütfen şifrenizi giriniz.", {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      if (data.errorcode === "authfailed") {
        toast.error(data.error || "Giriş başarısız!", {
          position: "top-right",
          autoClose: 5000,
        });
      } else if (data.errorcode === "subscriptionended") {
        toast.error(data.error || "Abonelik süresi doldu!", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="loginContent">
      <ToastContainer />
      <div className="loginBox">
        <div className="loginLeft">
          <div className="loginForm">
            <div className="loginHeaderArea fade-in-left-500">
              <h1 className="loginHeader">Giriş yap.</h1>
              <h3 className="loginSubtitle">NoBody Club'a devam et.</h3>
            </div>
            <div className="loginFormArea">
              <div className="loginUsernameArea fade-in-left-1000">
                <div className="loginUsername">Kullanıcı Adı</div>
                <input
                  className="loginUsernameInput"
                  value={username}
                  onChange={handleUsernameChange}
                  onKeyDown={handleKeyDown}
                  autoFocus
                />
              </div>
              <div className="loginPassArea fade-in-left-1000">
                <div className="loginPass">Şifre</div>
                <input
                  className="loginPassInput"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  onKeyDown={handleKeyDown}
                />
                <div onClick={goToForgotPassword} className="loginForgotPass">
                  Şifremi Unuttum
                </div>
              </div>

              <div className="loginButtonArea fade-in-left-1500">
                <input
                  type="button"
                  className="loginButton"
                  value="Devam Et"
                  onClick={handleLogin}
                />
              </div>
              <div className="loginRegister fade-in-left-1500">
                <div className="loginRegisterText">
                  <span className="loginQuest">Hala kulübe katılmadın mı?</span>
                  <br />
                  <span className="loginSub" onClick={goToRegister}>
                    Hemen üye ol!
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="loginRight">
          <div className="loginRightInner">
            <div className="loginRightUp fade-in-right-1000">
              <img
                src={`${process.env.PUBLIC_URL}/paint.png`}
                alt="Nobody Club Logo"
                className="loginLogo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
