import React from 'react'
import '../styles/landingpageprogram.css'

function LandingPageProgram() {
  const programData = [
    {
      title: 'Top 100',
      subtitle: '',
      description:
        'İş dünyasının temellerini ve ticaretin püf noktalarını öğreneceksiniz. Yıllarınızı boşa harcamadan her geçen gün büyüyen bir şirkete mi sahip olmak istiyorsunuz? O zaman bu eğitim tam size göre.',
      videoUrl: 'https://www.youtube.com/embed/BJCBDzGbJ48?si=9QPuqfKO-HWB32aq',
      backgroundImage: `${process.env.PUBLIC_URL}/top-100-arka.png`
    },
    {
      title: '"SAT" Komandosu',
      subtitle: 'Fonksiyonel Satış Sistemi',
      description:
        'Satış… Kelimelerin büyülü dünyası. Satışçının kafa yapısı, müşterinin kafa yapısı ve satış teknikleri konularında uzmanlaşıp satışlarınızı katlayarak büyütmek istiyorsanız bu eğitime şimdi başlayın. Milyonlarca dolarlık satışı telefonla ve yüz yüze gerçekleştiren, dünyanın bu işteki en iyisi olan Samet Doğan içeride seni bekliyor.',
      videoUrl: 'https://www.youtube.com/embed/qwO82OvxVPM?si=sF_VgHMnAKif9fuP',
      backgroundImage: `${process.env.PUBLIC_URL}/sat-arka.png`
    },
    {
      title: "Kişi'SELL Marka",
      subtitle: 'Product Yourself',
      description:
        'Marka evreni yeni starlarını arıyor. Bilgini ve tecrübeni pazarlanabilir bir ürüne dönüştürerek, arayan değil aranan birisi olmak ve sektörünü domine ederek akıllara kazımak istiyorsan güçlü bir kişisel markaya ihtiyacın var. Aradığın bütün bilgiler ise bu eğitimin içinde.',
      videoUrl: 'https://www.youtube.com/embed/h_KnTc8cUDE?si=pwGfnV2SCgQ8JBkt',
      backgroundImage: `${process.env.PUBLIC_URL}/kisisell-arka.png`
    }
  ]

  return (
    <div className='landingPageProgramContainer'>
      {programData.map((program, index) => {
        const words = program.title.split(' ')
        const firstWord = words.shift()
        const remainingWords = words.join(' ')

        return (
          <div
            key={index}
            className='landingPageProgram'
            style={{ backgroundImage: `url(${program.backgroundImage})` }}
          >
            <h2 className='landingPageProgramHeader'>
              <span className='firstWord'>{firstWord}&nbsp;</span>
              {remainingWords && (
                <span className='remainingWords'>{remainingWords}</span>
              )}
            </h2>
            <p className='landingPageProgramItemSubtitle'>{program.subtitle}</p>
            <p className='landingPageProgramDescription'>
              {program.description}
            </p>

            <iframe
              className='landingPageHeaderVideo'
              src={program.videoUrl}
              width='640'
              height='360'
              title='Video Tanıtım'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              referrerpolicy='strict-origin-when-cross-origin'
              allowfullscreen
            ></iframe>
          </div>
        )
      })}
    </div>
  )
}

export default LandingPageProgram
