import React from "react";

import "../styles/landingpagestudent.css";

function LandingPageStudent() {
  return (
    <div className="landingPageStudentContainer">
      <div className="landingPageStudentInner">
        <h3 className="landingPageStudentTitle">
          Öğrenci{" "}
          <span className="landingPageStudentTitleColored">Yorumları</span>
        </h3>
        <div className="landingPageStudentArea">
          <div className="landingPageStudentColumn">
            <img
              alt="NoBody Club Türkiye Öğrenci Yorumu"
              src={`${process.env.PUBLIC_URL}/IMG_4508.jpg`}
              className="landingPageStudentPhoto"
            />
            <img
              alt="NoBody Club Türkiye Öğrenci Yorumu"
              src={`${process.env.PUBLIC_URL}/IMG_4521.jpg`}
              className="landingPageStudentPhoto"
            />
            <img
              alt="NoBody Club Türkiye Öğrenci Yorumu"
              src={`${process.env.PUBLIC_URL}/IMG_4512.jpg`}
              className="landingPageStudentPhoto"
            />
            <img
              alt="NoBody Club Türkiye Öğrenci Yorumu"
              src={`${process.env.PUBLIC_URL}/IMG_4517.jpg`}
              className="landingPageStudentPhoto"
            />
          </div>
          <div className="landingPageStudentColumn">
            <img
              alt="NoBody Club Türkiye Öğrenci Yorumu"
              src={`${process.env.PUBLIC_URL}/IMG_4518.jpg`}
              className="landingPageStudentPhoto"
            />
            <img
              alt="NoBody Club Türkiye Öğrenci Yorumu"
              src={`${process.env.PUBLIC_URL}/IMG_4515.jpg`}
              className="landingPageStudentPhoto"
            />
            <img
              alt="NoBody Club Türkiye Öğrenci Yorumu"
              src={`${process.env.PUBLIC_URL}/IMG_4513.jpg`}
              className="landingPageStudentPhoto"
            />
          </div>
          <div className="landingPageStudentColumn">
            <img
              alt="NoBody Club Türkiye Öğrenci Yorumu"
              src={`${process.env.PUBLIC_URL}/IMG_4514.jpg`}
              className="landingPageStudentPhoto"
            />
            <img
              alt="NoBody Club Türkiye Öğrenci Yorumu"
              src={`${process.env.PUBLIC_URL}/IMG_4519.jpg`}
              className="landingPageStudentPhoto"
            />
            <img
              alt="NoBody Club Türkiye Öğrenci Yorumu"
              src={`${process.env.PUBLIC_URL}/IMG_4523.jpg`}
              className="landingPageStudentPhoto"
            />
          </div>
          <div className="landingPageStudentColumn">
            <img
              alt="NoBody Club Türkiye Öğrenci Yorumu"
              src={`${process.env.PUBLIC_URL}/IMG_4522.jpg`}
              className="landingPageStudentPhoto"
            />
            <img
              alt="NoBody Club Türkiye Öğrenci Yorumu"
              src={`${process.env.PUBLIC_URL}/IMG_4511.jpg`}
              className="landingPageStudentPhoto"
            />
            <img
              alt="NoBody Club Türkiye Öğrenci Yorumu"
              src={`${process.env.PUBLIC_URL}/IMG_4520.jpg`}
              className="landingPageStudentPhoto"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPageStudent;
