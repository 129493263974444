import React, { useState, useRef, useEffect } from "react";
import "../styles/videolariduzenle.css";
import "../styles/users.css";
import Adminaside from "./adminaside";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { slugify } from "../../../utils/slugify";
import Cookies from "js-cookie";
import config from "../../../config/config";
import Loader from "../../../components/loader";

function VideolariDuzenle() {
  const { slug, title } = useParams();
  const [tutorialData, setTutorialData] = useState([]);
  const [openVideoPopup, setOpenVideoPopup] = useState(null);
  const popupRefs = useRef([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [eduid, setEduid] = useState(null);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const uploadPopupRef = useRef(null);
  const [loading, setLoading] = useState(true);

  const [videoTitle, setVideoTitle] = useState("");
  const [videoURL, setVideoURL] = useState("");
  const [videoLength, setVideoLength] = useState("");

  const handleTogglePopup = (index) => {
    if (openVideoPopup === index) {
      setOpenVideoPopup(null);
    } else {
      setOpenVideoPopup(index);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Set the document title dynamically
    document.title = `${config.PRODUCT_NAME} | Videoları Düzenle`;
  }, [title]);

  const fetchData = async () => {
    const dataa = Cookies.get("userData");
    const ckdata = dataa ? JSON.parse(dataa) : {};

    const sessionToken = ckdata.stringsession;
    const username = ckdata.username;
    if (!sessionToken || !username) {
      alert("Kullanıcı bilgileri bulunamadı. Lütfen tekrar giriş yapın.");
      setLoading(false);
      return;
    }

    const response = await fetch(`${config.API_URL}listedu`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stringsession: sessionToken,
        username: username,
      }),
    });

    const data = await response.json();

    if (data.status === "True") {
      const matchedTutorial = data.data.find(
        (tutorial) => slugify(tutorial.eduname) === slug
      );
      if (matchedTutorial) {
        setTutorialData(matchedTutorial.videodata || []);
        setEduid(matchedTutorial.eduid);
        setLoading(false);
      } else {
        alert("Eğitim bulunamadı.");
        setLoading(false);
      }
    } else {
      alert(`Hata: ${data.error}`);
      setLoading(false);
    }
  };

  const handleClickOutside = (event) => {
    if (popupRefs.current) {
      let outsideClick = true;
      popupRefs.current.forEach((ref) => {
        if (ref && ref.contains(event.target)) {
          outsideClick = false;
        }
      });
      if (
        uploadPopupRef.current &&
        uploadPopupRef.current.contains(event.target)
      ) {
        outsideClick = false;
      }
      if (outsideClick) {
        setOpenVideoPopup(null);
        setShowUploadPopup(false);
      }
    }
  };

  const handleDelVid = async (videoid, event) => {
    event.stopPropagation();
    const dataa = Cookies.get("userData");
    const ckdata = dataa ? JSON.parse(dataa) : {};

    const sessionToken = ckdata.stringsession;
    const username = ckdata.username;
    if (!sessionToken || !username) {
      alert("Kullanıcı bilgileri bulunamadı. Lütfen tekrar giriş yapın.");
      return;
    }

    const response = await fetch(`${config.API_URL}deletevideo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stringsession: sessionToken,
        username: username,
        eduid: eduid,
        videoid: videoid,
      }),
    });

    const result = await response.json();
    if (result.status === "True") {
      setTutorialData(
        tutorialData.filter((video) => video.videoid !== videoid)
      );
      alert("Video silindi.");
    } else {
      alert(result.error);
    }
  };

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
  };

  const closeModal = () => {
    setSelectedVideo(null);
  };

  const closeModal1 = () => {
    setShowUploadPopup(false);
  };

  const handleModalClick = (e) => {
    if (e.target.classList.contains("usersModal")) {
      closeModal();
    }
  };

  const handleModalClick1 = (e) => {
    if (e.target.classList.contains("usersModal1")) {
      closeModal1();
    }
  };

  const handleUploadVideo = async () => {
    const dataa = Cookies.get("userData");
    const ckdata = dataa ? JSON.parse(dataa) : {};

    const sessionToken = ckdata.stringsession;
    const username = ckdata.username;
    if (!sessionToken || !username) {
      alert("Kullanıcı bilgileri bulunamadı. Lütfen tekrar giriş yapın.");
      return;
    }

    const response = await fetch(`${config.API_URL}createvideo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stringsession: sessionToken,
        username: username,
        eduid: eduid,
        videourl: videoURL,
      }),
    });

    const result = await response.json();
    if (result.status === "True") {
      window.location.reload();
      alert("Video yüklendi.");
      setShowUploadPopup(false);
    } else {
      alert(result.error);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openVideoPopup, showUploadPopup]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleUploadVideo();
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="adminContainer">
      <Adminaside />
      <div className="egitimDuzenleContainer">
        <div className="egitimDuzenleInner">
          <div className="videoDuzenleHeaderContainer">
            <h1 className="egitimDuzenleHeader videoDuzenleHeader">
              Videoları Düzenle
            </h1>
            <button
              className="headerButton"
              onClick={() => setShowUploadPopup(true)}
            >
              Video Yükle
            </button>
          </div>
          <div className="egitimListContainer">
            <div className="egitimListHeader">
              <div className="egitimListHeaderContainer">
                <div className="egitimListHeaderInner">
                  <div className="videoListTitle">Video Adı</div>
                  <div className="videoListTitle">Süre</div>
                  <div className="videoListTitle">İşlemler</div>
                </div>
              </div>
              <div className="egitimList videoList">
                {tutorialData.length === 0 ? (
                  <div className="videoDuzenleNoVideosMessage">
                    Bu eğitim setinde bir video görünmüyor. Yüklemek için sağ
                    üstteki butona tıkla!
                  </div>
                ) : (
                  tutorialData.map((video, index) => (
                    <div
                      key={index}
                      className="egitimItemContainer videoItemContainer"
                      onClick={() => handleVideoClick(video)}
                    >
                      <div className="egitimItemInner">
                        <div className="videoItemHeader">{video.title}</div>
                        <div className="videoItemDuration">{video.length}</div>
                        <div className="videoItemButtons">
                          <button
                            className="egitimItemButtonThird"
                            onClick={(event) =>
                              handleDelVid(video.videoid, event)
                            }
                          >
                            Sil
                          </button>
                          <div
                            ref={(el) => (popupRefs.current[index] = el)}
                            className="egitimInnerIconContainer"
                          >
                            <FontAwesomeIcon
                              icon={faEllipsisV}
                              className="egitimInnerIcon"
                              onClick={() => handleTogglePopup(index)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedVideo && (
        <div className="usersModal" onClick={handleModalClick}>
          <div className="usersModalContent">
            <div className="usersModalHeader">
              <h2>Video Bilgileri</h2>
              <FontAwesomeIcon
                icon={faTimes}
                className="usersModalClose"
                onClick={closeModal}
              />
            </div>
            <div className="modalInner">
              <div className="videoModalSectionLeft">
                <div className="modalParameter">
                  <iframe
                    src={`${selectedVideo.url}`}
                    allowFullScreen={true}
                    frameBorder={0}
                    title={`${selectedVideo.title}`}
                    className="videoDuzenleVideoContainer"
                  ></iframe>
                </div>
                <div className="modalParameter">
                  Video Başlığı:{" "}
                  <span className="modalValue capitalize">
                    {selectedVideo.title}
                  </span>
                </div>
                <div className="modalParameter">
                  Süre:{" "}
                  <span className="modalValue capitalize">
                    {selectedVideo.length}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showUploadPopup && (
        <div className="videoDuzenleModal" onClick={handleModalClick1}>
          <div className="videoDuzenleModalContent" ref={uploadPopupRef}>
            <div className="uploadVideoInner">
              <input
                autoFocus
                type="text"
                className="uploadVideoInput"
                placeholder="Video URL"
                value={videoURL}
                onChange={(e) => setVideoURL(e.target.value)}
                onKeyDown={handleKeyDown}
              />

              <button className="uploadVideoButton" onClick={handleUploadVideo}>
                Yükle
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VideolariDuzenle;
