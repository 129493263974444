import React, { useState } from "react";
import "../styles/forgotpasswordmail.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../config/config";
function ForgotPasswordMail() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const goToLogin = () => {
    navigate("/login");
  };

  const handleLogin = async () => {
    if (!email) {
      toast.error("Lütfen e-posta adresinizi giriniz.", {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }

    try {
      const response = await fetch(`${config.API_URL}forgotpassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (data.status === "True") {
        toast.success(data.message, {
          position: "top-right",
          autoClose: 5000,
        });
        navigate("/forgot-password-code", { state: { email } });
      } else {
        toast.error(data.error || data.message, {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      toast.error("Bir hata oluştu", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };
  return (
    <div className="forgotPasswordContent">
      <ToastContainer />
      <div className="forgotPasswordBox">
        <div className="forgotPasswordLeft">
          <div className="forgotPasswordForm">
            <div className="forgotPasswordFormArea">
              <div className="forgotPasswordUsernameArea fade-in-left-500">
                <div className="forgotPasswordUsername">E-Posta</div>
                <input
                  className="forgotPasswordUsernameInput"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={handleKeyDown}
                  autoFocus
                />
              </div>
              <div className="forgotPasswordButtonArea fade-in-left-1000">
                <input
                  type="button"
                  className="forgotPasswordButton"
                  value="Devam Et"
                  onClick={handleLogin}
                />
              </div>
              <div className="forgotPasswordRegister fade-in-left-1000">
                <div className="forgotPasswordRegisterText">
                  <div onClick={goToLogin} className="loginForgotPass">
                    Giriş Yap'a dön
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordMail;
