import React, { useState, useRef, useEffect } from "react";
import Adminaside from "./adminaside";
import "../styles/adminhome.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faBookmark,
  faEllipsisV,
  faComments,
} from "@fortawesome/free-solid-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { slugify } from "../../../utils/slugify";
import Cookies from "js-cookie";
import config from "../../../config/config";
import Loader from "../../../components/loader";

function AdminHome() {
  const [tutorialData, setTutorialData] = useState([]);
  const [openPopup, setOpenPopup] = useState(null);
  const popupRefs = useRef([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [userCount, setUserCount] = useState(0);
  const [educationCount, setEducationCount] = useState(0);
  const [videoCount, setVideoCount] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const deleteedu = async (eduid) => {
    const dataa = Cookies.get("userData");

    if (!dataa) {
      console.log("No userData cookie found");
      return navigate("/login");
    }

    const ckdata = JSON.parse(dataa);

    const response = await fetch(`${config.API_URL}deleteedu`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stringsession: ckdata.stringsession,
        username: ckdata.username,
        eduid: eduid,
      }),
    });

    const result = await response.json();
    console.log(result);
    if (result.status === "True") {
      window.location.reload();
      alert(result.message);
    } else {
      alert(result.error);
    }
  };

  const fetchData = async () => {
    const dataa = Cookies.get("userData");
    const ckdata = dataa ? JSON.parse(dataa) : {};

    const sessionToken = ckdata.stringsession;
    const username = ckdata.username;
    if (!sessionToken || !username) {
      alert("Kullanıcı bilgileri bulunamadı. Lütfen tekrar giriş yapın.");
      setLoading(false);
      return;
    }

    const response = await fetch(`${config.API_URL}listedu`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stringsession: sessionToken,
        username: username,
      }),
    });

    const data = await response.json();

    if (data.status === "True") {
      setTutorialData(data.data);
      setLoading(false);
    } else {
      alert(`Hata: ${data.error}`);
      setLoading(false);
    }
  };

  const fetchStatistics = async () => {
    const dataa = Cookies.get("userData");
    const ckdata = dataa ? JSON.parse(dataa) : {};

    const sessionToken = ckdata.stringsession;
    const username = ckdata.username;
    if (!sessionToken || !username) {
      alert("Kullanıcı bilgileri bulunamadı. Lütfen tekrar giriş yapın.");
      return;
    }

    const response = await fetch(`${config.API_URL}statistics`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stringsession: sessionToken,
        username: username,
      }),
    });

    const data = await response.json();

    if (data.status === "True") {
      setUserCount(data.user_count);
      setEducationCount(data.education_count);
      setVideoCount(data.video_count);
      setCommentCount(data.comment_count);
    } else {
      alert(`Hata: ${data.error}`);
    }
  };

  useEffect(() => {
    fetchData();
    fetchStatistics();
  }, [location]);

  const handleTogglePopup = (index) => {
    if (openPopup === index) {
      setOpenPopup(null);
    } else {
      setOpenPopup(index);
    }
  };

  const handleClickOutside = (event) => {
    if (popupRefs.current) {
      let outsideClick = true;
      popupRefs.current.forEach((ref) => {
        if (ref && ref.contains(event.target)) {
          outsideClick = false;
        }
      });
      if (outsideClick) {
        setOpenPopup(null);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleEditSet = (tutorial) => {
    const slug = slugify(tutorial.eduname);
    navigate(`/admin/egitim-duzenle/egitim-setini-duzenle/${slug}`, {
      state: { tutorial },
    });
  };

  const handleEditVideos = (tutorial) => {
    const slug = slugify(tutorial.eduname);
    navigate(`/admin/egitim-duzenle/videolari-duzenle/${slug}`, {
      state: { tutorial },
    });
  };

  const goToEditTut = () => {
    navigate("/admin/egitim-duzenle");
  };

  const goToUsers = () => {
    navigate("/admin/users");
  };

  return (
    <>
      {loading && <Loader />}
      <div className="adminContainer">
        <Adminaside />
        <div className="adminHomeContainer">
          <div className="adminHomeInner">
            <h1 className="adminHomeHeader">Ana Sayfa</h1>
            <div className="adminHomeLeftRight">
              <div className="adminHomeLeftSide">
                <div className="adminHomeLeftHeaderArea">
                  <h1 className="adminHomeLeftHeader">Eğitimler</h1>
                  <button className="homeItemButton" onClick={goToEditTut}>
                    Eğitimleri Düzenle
                  </button>
                </div>
                <div className="adminHomeLeftInner">
                  {tutorialData.length === 0 ? (
                    <div className="videoDuzenleNoVideosMessage">
                      Sitende bir eğitim seti görünmüyor. Eğitim oluşturmak için
                      menüdeki artı ikonuna tıkla!
                    </div>
                  ) : (
                    tutorialData.map((tutorial, index) => (
                      <div key={index} className="homeItemContainer">
                        <div className="homeItemInner">
                          <div className="homeItemHeader">
                            {tutorial.eduname}
                          </div>
                          <div className="homeItemButtons">
                            <div
                              ref={(el) => (popupRefs.current[index] = el)}
                              className="homeInnerIconContainer"
                            >
                              <FontAwesomeIcon
                                icon={faEllipsisV}
                                className="homeInnerIcon"
                                onClick={() => handleTogglePopup(index)}
                              />
                              <div
                                className={`popupMenu ${
                                  openPopup === index ? "open" : ""
                                }`}
                              >
                                <div
                                  className="popupMenuItem"
                                  onClick={() => handleEditSet(tutorial)}
                                >
                                  Eğitim Setini Düzenle
                                </div>
                                <div
                                  className="popupMenuItem"
                                  onClick={() => handleEditVideos(tutorial)}
                                >
                                  Videoları Düzenle
                                </div>
                                <div
                                  onClick={() => deleteedu(tutorial.eduid)}
                                  className="popupMenuItem"
                                >
                                  Sil
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className="adminHomeRightSide">
                <div className="adminHomeIstatistikContainer">
                  <div className="adminHomeIstatistikBox" onClick={goToUsers}>
                    <div className="adminHomeIstatistikInner">
                      <FontAwesomeIcon
                        icon={faUser}
                        className="adminHomeIstatistikIcon"
                      />
                      <div className="adminHomeIstatistik">
                        <div className="adminHomeIstatistikHeader">
                          Kulüp Üye Adedi
                        </div>
                        <div className="adminHomeIstatistikValue">
                          {userCount}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="adminHomeIstatistikBox" onClick={goToEditTut}>
                    <div className="adminHomeIstatistikInner">
                      <FontAwesomeIcon
                        icon={faBookmark}
                        className="adminHomeIstatistikIcon"
                      />
                      <div className="adminHomeIstatistik">
                        <div className="adminHomeIstatistikHeader">
                          Kurs Adedi
                        </div>
                        <div className="adminHomeIstatistikValue">
                          {educationCount}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="adminHomeIstatistikBox">
                    <div className="adminHomeIstatistikInner">
                      <FontAwesomeIcon
                        icon={faYoutube}
                        className="adminHomeIstatistikIcon"
                      />
                      <div className="adminHomeIstatistik">
                        <div className="adminHomeIstatistikHeader">
                          Video Adedi
                        </div>
                        <div className="adminHomeIstatistikValue">
                          {videoCount}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="adminHomeIstatistikBox">
                    <div className="adminHomeIstatistikInner">
                      <FontAwesomeIcon
                        icon={faComments}
                        className="adminHomeIstatistikIcon"
                      />
                      <div className="adminHomeIstatistik">
                        <div className="adminHomeIstatistikHeader">
                          Yorum Adedi
                        </div>
                        <div className="adminHomeIstatistikValue">
                          {commentCount}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminHome;
