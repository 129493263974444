import Tutorials from "./components/tutorials.js";
import WelcomeNavbar from "../welcome/components/welcomeNavbar.js";
import FooterPanel from "../../components/footerPanel.js";
import Copyright from "../../components/copyright.js";

function TutorialsPage() {
  return (
    <>
      <WelcomeNavbar />
      <Tutorials />
      <FooterPanel />
      <Copyright />
    </>
  );
}

export default TutorialsPage;
