import React from "react";
import "../styles/contact.css";

function Contact() {
  return (
    <div className="contactContainer">
      <div className="contacInner">sa</div>
    </div>
  );
}

export default Contact;
