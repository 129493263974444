import React from "react";
import "./tutorialcard.css";

function TutorialCard({ image, title, onClick }) {
  return (
    <div className="tutorialCardContainer">
      <div className="tutorialCardInner">
        <div className="cardImageArea">
          <img src={image} alt={title} className="cardImage" />
        </div>
        <h3 className="cardTitle">{title}</h3>
        <button className="cardButton" onClick={onClick}>
          Eğitimi İzle
        </button>
      </div>
    </div>
  );
}

export default TutorialCard;
