import React, { useState, useRef, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import "../styles/watchvideo.css";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faEye,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { slugify } from "../../../utils/slugify";
import config from "../../../config/config";

function WatchVideo() {
  const { videoname } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [video, setVideo] = useState({});
  const [videodata, setVideodata] = useState([]);
  const [eduid, setEduid] = useState("");
  const [isContentAccordionOpen, setIsContentAccordionOpen] = useState(true);
  const [popupStates, setPopupStates] = useState([]);
  const [isCommentsAccordionOpen, setIsCommentsAccordionOpen] = useState(true);
  const [newComment, setNewComment] = useState("");
  const textareaRef = useRef(null);
  const [commentData, setCommentData] = useState([]);
  const [activePopupIndex, setActivePopupIndex] = useState(null);
  const [cookiedata, setCookiedata] = useState({});
  const [isAdmin, setIsAdmin] = useState(false); // State for admin status
  const [currentUsername, setCurrentUsername] = useState("");
  const [categoriesOpen, setCategoriesOpen] = useState({
    categoryOne: false,
    categoryTwo: false,
    categoryThree: false,
  });

    const toggleCategory = (category) => {
    setCategoriesOpen((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  useEffect(() => {
    if (
      location.state?.video &&
      location.state?.videodata &&
      location.state?.eduid
    ) {
      setVideo(location.state.video);
      setVideodata(location.state.videodata);
      setEduid(location.state.eduid);
    }
  }, [location.state]);

  useEffect(() => {
    const fetchData = async () => {
      const dataa = Cookies.get("userData");
      const ckdata = JSON.parse(dataa);
      setCookiedata(ckdata);

      const sessionToken = ckdata.stringsession;
      const username = ckdata.username;
      setIsAdmin(ckdata.role === "admin"); // Set admin status
      setCurrentUsername(username);

      const response = await fetch(`${config.API_URL}listedu`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          stringsession: sessionToken,
          username: username,
        }),
      });

      const result = await response.json();

      if (result.status === "True") {
        const alldata = result.data.find((eduinfo) => eduinfo.eduid === eduid);
        if (alldata) {
          const videoItem = alldata.videodata.find(
            (videod) => videod.videoid === video.videoid
          );
          if (videoItem) {
            setCommentData(videoItem.comment || []);
          }
        }
      } else {
        alert(result.error);
      }
    };

    fetchData();
  }, [eduid, video.videoid]);

  const toggleContentAccordion = () => {
    setIsContentAccordionOpen(!isContentAccordionOpen);
  };

  const toggleCommentsAccordion = () => {
    setIsCommentsAccordionOpen(!isCommentsAccordionOpen);
  };

  const handleVideoClick = (video) => {
    const videoName = slugify(video.title);
    navigate(`/watchvideo/${videoName}`, {
      state: { video, videodata, eduid },
    });
  };

  const togglePopup = (index) => {
    setActivePopupIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const deletecomment = async (commentid) => {
    const response = await fetch(`${config.API_URL}deletevideocomment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stringsession: cookiedata.stringsession,
        username: cookiedata.username,
        eduid: eduid,
        videoid: video.videoid,
        commentid: commentid,
      }),
    });

    const result = await response.json();
    if (result.status === "True") {
      setCommentData((prevComments) =>
        prevComments.filter((comment) => comment.commentid !== commentid)
      );

      window.location.reload();
    } else {
      alert(result.error);
    }
  };

  const addComment = async () => {
    const dataa = Cookies.get("userData");
    const ckdata = JSON.parse(dataa);

    const sessionToken = ckdata.stringsession;
    const username = ckdata.username;

    const response = await fetch(`${config.API_URL}commentvideo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stringsession: sessionToken,
        username: username,
        eduid: eduid,
        videoid: video.videoid,
        comment: newComment,
      }),
    });

    const result = await response.json();

    if (result.status === "True") {
      setCommentData((prevComments) => [
        ...prevComments,
        {
          name: username,
          date: new Date().toLocaleDateString("tr-TR"),
          comment: newComment,
          commentid: Math.floor(Math.random() * 1000000),
        },
      ]);
      setNewComment("");

      window.location.reload();
    } else {
      alert(result.error);
    }
  };

  const handleInput = () => {
    const textarea = textareaRef.current;
    const initialTextareaHeight = 110; // Initial height of the textarea
    const initialDownInnerHeight = 110; // Initial height of the downInner
    const initialContainerHeight = 250; // Initial height of the containers

    textarea.style.height = `${initialTextareaHeight}px`; // Reset to initial height

    if (textarea.scrollHeight > initialTextareaHeight) {
      textarea.style.height = `${textarea.scrollHeight}px`; // Adjust height based on content

      const extraHeight = textarea.scrollHeight - initialTextareaHeight;

      const newDownInnerHeight = initialDownInnerHeight + extraHeight;
      const newContainerHeight = initialContainerHeight + extraHeight;

      document.querySelector(
        ".watchVideoLeaveCommentDownInner"
      ).style.height = `${newDownInnerHeight}px`;
      document.querySelector(
        ".watchVideoLeaveCommentAreaInner"
      ).style.height = `${newContainerHeight}px`;
      document.querySelector(
        ".watchVideoLeaveCommentArea"
      ).style.height = `${newContainerHeight}px`;
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".popup")) {
        setPopupStates(popupStates.map(() => false)); // Close all popups
        setActivePopupIndex(null); // Close the active popup
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [popupStates]);

  if (!video || Object.keys(video).length === 0) {
    return <div>Video bulunamadı.</div>;
  }

  return (
    <div className="watchVideoContainer">
      <div className="watchVideoInner">
        <div className="watchVideoGridContainer">
          <div className="watchVideoGridItem videoPlayerContent">
            <iframe
              width="560"
              height="315"
              src={`${video.url}`}
              allowFullScreen={true}
              frameBorder={0}
              title="Eğitim Videosu"
            ></iframe>
            <h1>{video.title}</h1>
          </div>
          {eduid === 534946 ?(<div className="watchVideoGridItem">
            <div className="watchVideoContentArea">
              {videodata.length === 0 ? (
                <p className="noVideosMessage">Henüz bir video yüklenmemiş.</p>
              ) : (
                <div
                  className="watchVideoContentHeader"
                  onClick={toggleContentAccordion}
                >
                  <div className="watchVideoContentHeaderInner">
                    <div className="tutorialContentHeaderTitle">Videolar</div>
                    <div
                      className={`watchVideoContentHeaderIcon ${
                        isContentAccordionOpen ? "rotate" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                  </div>
                </div>
              )}
              <ul
                className={`watchVideoContentList ${
                  isContentAccordionOpen && videodata.length > 0 ? "expand" : ""
                }`}
              >
               <div
                          className="categoryWatch"
                          onClick={() => toggleCategory('categoryOne')}
                        >
                          <div className="categoryWatchInner">
                          <div>Satışçının Kafa Yapısı</div>
                          <div className={`categoryIcon ${categoriesOpen.categoryOne ? "rotate" : ""}`}>
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                          </div>
                        </div>
                        <ul
                          className={`categoryContent ${
                            categoriesOpen.categoryOne ? "expand" : ""
                          }`}
                        >
                          {videodata.slice(0, 13).map((item, index) => (
                                <li
                                className="tutorialContentListItem"
                                key={index}
                                onClick={() => handleVideoClick(item)}
                              >
                                <div className="watchVideoContentInner">
                                  <div className="watchVideoContentLeft">{item.title}</div>
                                  <div className="watchVideoContentRight">
                                    {item.length}
                                  </div>
                                </div>
                              </li>
                          ))}
                        </ul>

                        <div
                          className="categoryWatch"
                          onClick={() => toggleCategory('categoryTwo')}
                        >
                          <div className="categoryWatchInner">
                          <div>Müşterinin Kafa Yapısı</div>
                          <div className={`categoryIcon ${categoriesOpen.categoryTwo ? "rotate" : ""}`}>
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                          </div>
                        </div>
                        <ul
                          className={`categoryContent ${
                            categoriesOpen.categoryTwo ? "expand" : ""
                          }`}
                        >
                          {videodata.slice(13, 28).map((item, index) => (
                                 <li
                    className="tutorialContentListItem"
                    key={index}
                    onClick={() => handleVideoClick(item)}
                  >
                    <div className="watchVideoContentInner">
                      <div className="watchVideoContentLeft">{item.title}</div>
                      <div className="watchVideoContentRight">
                        {item.length}
                      </div>
                    </div>
                  </li>
                          ))}
                        </ul>

                        <div
                          className="categoryWatch"
                          onClick={() => toggleCategory('categoryThree')}
                        >
                          <div className="categoryWatchInner">
                          <div>Satış Teknikleri</div>
                          <div className={`categoryIcon ${categoriesOpen.categoryThree ? "rotate" : ""}`}>
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                          </div>
                        </div>
                        <ul
                          className={`categoryContent ${
                            categoriesOpen.categoryThree ? "expand" : ""
                          }`}
                        >
                          {videodata.slice(28, 37).map((item, index) => (
                              <li
                                className="tutorialContentListItem"
                                key={index}
                                onClick={() => handleVideoClick(item)}
                              >
                                <div className="watchVideoContentInner">
                                  <div className="watchVideoContentLeft">{item.title}</div>
                                  <div className="watchVideoContentRight">
                                    {item.length}
                                  </div>
                                </div>
                            </li>
                          ))}
                        </ul>
              </ul>
            </div>
          </div>) : (
            <div className="watchVideoGridItem">
            <div className="watchVideoContentArea">
              {videodata.length === 0 ? (
                <p className="noVideosMessage">Henüz bir video yüklenmemiş.</p>
              ) : (
                <div
                  className="watchVideoContentHeader"
                  onClick={toggleContentAccordion}
                >
                  <div className="watchVideoContentHeaderInner">
                    <div className="tutorialContentHeaderTitle">Videolar</div>
                    <div
                      className={`watchVideoContentHeaderIcon ${
                        isContentAccordionOpen ? "rotate" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                  </div>
                </div>
              )}
              <ul
                className={`watchVideoContentList ${
                  isContentAccordionOpen && videodata.length > 0 ? "expand" : ""
                }`}
              >
                {videodata.map((item, index) => (
                  <li
                    className="tutorialContentListItem"
                    key={index}
                    onClick={() => handleVideoClick(item)}
                  >
                    <div className="watchVideoContentInner">
                      <div className="watchVideoContentLeft">{item.title}</div>
                      <div className="watchVideoContentRight">
                        {item.length}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>)}
          
          <div className="watchVideoGridItem watchVideoCommentGrid">
            <div className="watchVideoCommentArea">
              {commentData.length === 0 ? (
                <p className="noCommentsMessage">Henüz bir yorum yok.</p>
              ) : (
                <>
                  <div
                    className="watchVideoContentHeader"
                    onClick={toggleCommentsAccordion}
                  >
                    <div className="watchVideoCommentHeaderInner">
                      <div className="tutorialContentHeaderTitle">Yorumlar</div>
                      <div
                        className={`tutorialContentHeaderIcon ${
                          isCommentsAccordionOpen ? "rotate" : ""
                        }`}
                      >
                        <FontAwesomeIcon icon={faChevronRight} />
                      </div>
                    </div>
                  </div>
                  <ul
                    className={`watchVideoContentList ${
                      isCommentsAccordionOpen && commentData.length > 0
                        ? "expand"
                        : ""
                    }`}
                  >
                    {commentData.map((comment, index) => (
                      <li className="watchVideoCommentItem" key={index}>
                        <div className="watchVideoCommentListInner">
                          <img
                            className="watchVideoCommentPhoto"
                            src={`${process.env.PUBLIC_URL}/assets/NoBodyClub/nobodyclubsquare.jpg`}
                            alt={comment.name}
                          />
                          <div className="watchVideoCommentContent">
                            <div className="watchVideoCommentHeader">
                              <span className="watchVideoCommentName">
                                {comment.name}
                              </span>
                              <span className="watchVideoCommentDate">
                                {comment.date}
                              </span>
                            </div>
                            <div className="commentAltGroup">
                              <p className="watchVideoCommentText">
                                {comment.comment}
                              </p>

                              {(cookiedata.role === "admin" ||
                                comment.username === cookiedata.username) && (
                                <FontAwesomeIcon
                                  icon={faEllipsisV}
                                  className="commentAltGroupIcon"
                                  onClick={() => togglePopup(index)}
                                />
                              )}
                              {activePopupIndex === index &&
                                (cookiedata.role === "admin" ||
                                  comment.username === cookiedata.username) && (
                                  <div
                                    onClick={() =>
                                      deletecomment(comment.commentid)
                                    }
                                    className="popup"
                                  >
                                    <span>Sil</span>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>

          <div className="watchVideoGridItem watchVideoLeaveCommentContainer">
            <div className="watchVideoLeaveCommentInner">
              <div className="watchVideoLeaveCommentArea">
                <div className="watchVideoLeaveCommentAreaInner">
                  <div className="watchVideoLeaveCommentUpArea">
                    <h6 className="watchVideoLeaveCommentHeader">
                      Bir Yorum Bırak!
                    </h6>
                  </div>
                  <div className="watchVideoLeaveCommentDownArea">
                    <div className="watchVideoLeaveCommentDownInner">
                      <textarea
                        onInput={handleInput}
                        ref={textareaRef}
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        className="watchVideoCommentInput"
                        placeholder="Yorum Yaz!"
                      />
                      <span onClick={addComment}>Gönder</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WatchVideo;
