import config from "../config/config";

const pageTitles = {
  "/": `${config.PRODUCT_NAME} | Online Eğitimde Yeni Bir Devrim`,
  "/login": `${config.PRODUCT_NAME} | Giriş Yap`,
  "/register": `${config.PRODUCT_NAME} | Kayıt Ol`,
  "/forgot-password-mail": `${config.PRODUCT_NAME} | Şifremi Unuttum`,
  "/forgot-password-code": `${config.PRODUCT_NAME} | Şifremi Unuttum`,
  "/forgot-password-newpassword": `${config.PRODUCT_NAME} | Şifremi Unuttum`,
  "/welcome": `${config.PRODUCT_NAME} | Hoş Geldiniz`,
  "/profile": `${config.PRODUCT_NAME} | Profil`,
  "/tutorials": `${config.PRODUCT_NAME} | Eğitimler`,
  "/contact": `${config.PRODUCT_NAME} | İletişim`,
  "/admin": `${config.PRODUCT_NAME} | Panele Giriş`,
  "/admin/home": `${config.PRODUCT_NAME} | Yönetici Ana Sayfa`,
  "/admin/Home": `${config.PRODUCT_NAME} | Yönetici Ana Sayfa`,
  "/admin/egitim-olustur": `${config.PRODUCT_NAME} | Eğitim Oluştur`,
  "/admin/egitim-duzenle": `${config.PRODUCT_NAME} | Eğitim Düzenle`,
  "/admin/egitim-duzenle/egitim-setini-duzenle": `${config.PRODUCT_NAME} | Eğitim Setini Düzenle`,
  "/admin/egitim-duzenle/videolari-duzenle": `${config.PRODUCT_NAME} | Videoları Düzenle`,
  "/admin/users": `${config.PRODUCT_NAME} | Kullanıcılar`,
  "/watchvideo": `${config.PRODUCT_NAME} | Video İzle`,
  "*": `${config.PRODUCT_NAME} | Sayfa Bulunamadı`,
};

export default pageTitles;
