import React from "react";
import "./popuploader.css";

const PopupLoader = () => {
  return (
    <div className="popup-loader-overlay">
      <div className="popup-loader-container">
        <div className="popup-spinner"></div>
      </div>
    </div>
  );
};

export default PopupLoader;
