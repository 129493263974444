import React from "react";
import "./footer.css";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
  };

  const goToContact = () => {
    navigate("/contact");
  };

  const goToRegister = () => {
    navigate("/register");
  };

  return (
    <footer className="footerContainer">
      <div className="footerInner">
        <div className="footerLogo footerArea">
          <img
            src={`${process.env.PUBLIC_URL}/assets/NoBodyClub/nobodyclub.png`}
            alt="NoBody Club Logo"
            className="footerImg"
            onClick={goToHome}
          />
          <div className="footerSubtitle">
            Bu websitesi&nbsp;
            <span className="footerBerat">
              <a
                href="https://theberathan.com"
                className="footerBerat"
                target="_blank"
                rel="noreferrer"
              >
                theberathan&nbsp;
              </a>
            </span>
            tarafından tasarlanmıştır.
          </div>
        </div>
        <div className="footerInfo footerArea">
          <div className="footerInfoInner">
            <div className="footerInfoTitle">Bilgilendirme</div>
            <ul className="footerInfoList">
              <li className="footerInfoListItem" onClick={goToRegister}>
                Kulübe Katıl
              </li>
              <li className="footerInfoListItem">Bizimle İletişime Geç</li>
              <li className="footerInfoListItem">Gizlilik ve KVKK Metni</li>
              <li className="footerInfoListItem">
                Sıkça Sorulan Sorular (SSS)
              </li>
              <li className="footerInfoListItem">support@nobodyclub.com</li>
            </ul>
          </div>
        </div>
        <div className="footerInfo footerArea">
          <div className="footerInfoInner">
            <div className="footerInfoTitle">Sosyal</div>
            <ul className="footerInfoList">
              <li className="footerInfoListItem">
                <a
                  className="footerInfoListLink"
                  href="https://www.instagram.com/nobodyclub_"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </li>
              <li className="footerInfoListItem">Telegram</li>
              <li className="footerInfoListItem">NoBody Club Hakkında</li>
              <li className="footerInfoListItem">WhatsApp Destek Hattı</li>
              <li className="footerInfoListItem">Samet Doğan Hakkında</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
