import React, { useState, useEffect } from "react";
import "../styles/welcomeNavbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import config from "../../../config/config";

function WelcomeNavbar() {
  const [menuActive, setMenuActive] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = Cookies.get("userData");

        if (!data) return navigate("/login");
        const ckdata = data ? JSON.parse(data) : {};

        const response = await fetch(`${config.API_URL}session`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            stringsession: ckdata.stringsession,
            username: ckdata.username,
          }),
        });

        const result = await response.json();

        if (result.status == "False") {
          const data = Cookies.remove("userData");
          navigate("/login");
        }
        if (result.role == "admin") setIsAdmin(true);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  const toggleMenu = () => {
    setMenuActive((prev) => !prev);
  };

  const goToHome = () => {
    navigate("/");
  };

  const goToWelcome = () => {
    navigate("/welcome");
  };

  const goToProfile = () => {
    navigate("/profile");
  };

  const goToTutorials = () => {
    navigate("/tutorials");
  };

  const goToAdmin = () => {
    navigate("/admin");
  };
  const logout = () => {
    Cookies.remove("userData");
    navigate("/");
  };

  useEffect(() => {
    const handleClose = (event) => {
      if (
        !event.target.closest(".navbarMobile") &&
        !event.target.closest("#icon")
      ) {
        setMenuActive(false);
      }
    };

    if (menuActive) {
      document.addEventListener("click", handleClose);
    } else {
      document.removeEventListener("click", handleClose);
    }

    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, [menuActive]);

  return (
    <>
      <nav className="navbarContainer" id="navbar">
        <div className="navbarInner">
          <div className="navbarLogoBanner">
            <div className="navbarLogo">
              <img
                src={`${process.env.PUBLIC_URL}/assets/NoBodyClub/nobodyclub.png`}
                className="navLogo"
                onClick={goToHome}
                alt="NoBody Club Logo"
              />
            </div>
          </div>
          <div className="navbarContent">
            <ul className="navbarList">
              <li className="navbarListItem">
                <div onClick={goToWelcome} className="navbarLink">
                  Ana sayfa
                </div>
              </li>
              <li className="navbarListItem">
                <div onClick={goToProfile} className="navbarLink">
                  Profilim
                </div>
              </li>
              <li className="navbarListItem">
                <div onClick={goToTutorials} className="navbarLink">
                  Eğitimler
                </div>
              </li>
              {isAdmin && (
                <li className="navbarListItem">
                  <div onClick={goToAdmin} className="navbarLink">
                    Admin
                  </div>
                </li>
              )}
              <li className="navbarListItem" onClick={logout}>
                <div className="navbarLink last">Çıkış Yap</div>
              </li>
            </ul>

            <FontAwesomeIcon
              icon={faBars}
              size="xl"
              className="icon"
              id="icon"
              onClick={toggleMenu}
            />
          </div>
        </div>
      </nav>
      <nav className={`navbarMobile ${menuActive ? "active" : ""}`}>
        <div className="navbarMobileContainer" id="mobileNavbar">
          <div className="navbarMobileContent">
            <ul className="navbarMobileList">
              <li className="navbarMobileListItem">
                <div className="navbarMobileLink" onClick={goToWelcome}>
                  Ana sayfa
                </div>
              </li>
              <li className="navbarMobileListItem">
                <div className="navbarMobileLink" onClick={goToProfile}>
                  Profilim
                </div>
              </li>
              <li className="navbarMobileListItem">
                <div className="navbarMobileLink" onClick={goToTutorials}>
                  Eğitimler
                </div>
              </li>
              {isAdmin && (
                <li className="navbarMobileListItem">
                  <div className="navbarMobileLink" onClick={goToAdmin}>
                    Admin
                  </div>
                </li>
              )}
              <li className="navbarMobileListItem">
                <div className="navbarMobileLink mobileLast" onClick={logout}>
                  Çıkış Yap
                </div>
              </li>
            </ul>
          </div>
          <FontAwesomeIcon
            icon={faXmark}
            size="2xl"
            className="mobileIcon"
            onClick={toggleMenu}
          />
        </div>
      </nav>
    </>
  );
}

export default WelcomeNavbar;
