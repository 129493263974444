import React, { useState, useEffect } from "react";
import "../styles/egitimolustur.css";
import Adminaside from "./adminaside";
import Cookies from "js-cookie";
import config from "../../../config/config";
import PopupLoader from "../../../components/popuploader";

function EgitimOlustur() {
  const [firstPhotoPreview, setFirstPhotoPreview] = useState(null);
  const [secondPhotoPreview, setSecondPhotoPreview] = useState(null);
  const [firstPhotoFile, setFirstPhotoFile] = useState(null);
  const [secondPhotoFile, setSecondPhotoFile] = useState(null);
  const [educationName, setEducationName] = useState("");
  const [educationDescription, setEducationDescription] = useState("");
  const [sessionToken, setSessionToken] = useState("");
  const [username, setUsername] = useState("");
  const [loaderVisible, setLoaderVisible] = useState(false);

  useEffect(() => {
    // Çerezlerden verileri çek
    const data = Cookies.get("userData");
    const ckdata = data ? JSON.parse(data) : {};
    setSessionToken(ckdata.stringsession);
    setUsername(ckdata.username);
  }, []);

  const handleDrop = (event, setPreview, setFile) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
        setFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileChange = (event, setPreview, setFile) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
        setFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async () => {
    setLoaderVisible(true);
    const formData = new FormData();
    formData.append("stringsession", sessionToken);
    formData.append("username", username);
    formData.append("eduname", educationName);
    formData.append("description", educationDescription);
    formData.append("firstphoto", firstPhotoFile);
    formData.append("secondphoto", secondPhotoFile);

    try {
      const response = await fetch(`${config.API_URL}createedu`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (data.status === "True") {
        setLoaderVisible(false);
        alert("Eğitim başarıyla oluşturuldu!");
        window.location.reload();
      } else {
        setLoaderVisible(false);
        alert(`Hata: ${data.error}`);
      }
    } catch (error) {
      setLoaderVisible(false);
      alert("Bir hata oluştu. Lütfen tekrar deneyin.", error);
    }
  };

  return (
    <div className="adminContainer">
      <Adminaside />
      {loaderVisible && <PopupLoader />}
      <div className="egitimolusturContainer">
        <div className="egitimolusturInner">
          <h1 className="egitimolusturHeader">Eğitim Oluştur</h1>
          <div className="formTextInputs">
            <div className="formGroup">
              <h1>Eğitim Başlığı</h1>
              <input
                type="text"
                id="eduname"
                name="eduname"
                value={educationName}
                onChange={(e) => setEducationName(e.target.value)}
              />
            </div>
            <div className="formGroup">
              <h2>Eğitim Açıklaması</h2>
              <input
                type="text"
                id="description"
                name="description"
                value={educationDescription}
                onChange={(e) => setEducationDescription(e.target.value)}
              />
            </div>
          </div>
          <div className="formFileInputs">
            <div className="formGroup">
              <h3>Eğitim Küçük Resmi</h3>
              <div
                className="dropZone"
                onDrop={(e) =>
                  handleDrop(e, setFirstPhotoPreview, setFirstPhotoFile)
                }
                onDragOver={(e) => e.preventDefault()}
              >
                {firstPhotoPreview ? (
                  <img
                    src={firstPhotoPreview}
                    alt="First Photo"
                    className="uploadedImage"
                  />
                ) : (
                  <p>Sürükle & Bırak veya Dosya Seçmek İçin Tıkla</p>
                )}
                <input
                  type="file"
                  id="firstphoto"
                  name="firstphoto"
                  accept="image/*"
                  onChange={(e) =>
                    handleFileChange(e, setFirstPhotoPreview, setFirstPhotoFile)
                  }
                />
              </div>
            </div>
            <div className="formGroup">
              <h4>Eğitim Banner Resmi</h4>
              <div
                className="dropZone"
                onDrop={(e) =>
                  handleDrop(e, setSecondPhotoPreview, setSecondPhotoFile)
                }
                onDragOver={(e) => e.preventDefault()}
              >
                {secondPhotoPreview ? (
                  <img
                    src={secondPhotoPreview}
                    alt="Second Photo"
                    className="uploadedImage"
                  />
                ) : (
                  <p>Sürükle & Bırak veya Dosya Seçmek İçin Tıkla</p>
                )}
                <input
                  type="file"
                  id="secondphoto"
                  name="secondphoto"
                  accept="image/*"
                  onChange={(e) =>
                    handleFileChange(
                      e,
                      setSecondPhotoPreview,
                      setSecondPhotoFile
                    )
                  }
                />
              </div>
            </div>
          </div>
          <button className="adminUploadButton" onClick={handleUpload}>
            Eğitimi Yükle
          </button>
        </div>
      </div>
    </div>
  );
}

export default EgitimOlustur;
