import React from "react";
import "../styles/landingpageproperty.css";
import config from "../../../config/config";

function LandingPageProperty() {
  return (
    <div className="landingPagePropertyContainer">
      <div className="landingPagePropertyInner">
        <h4 className="landingPagePropertyTitle">
          Türkiye'de&nbsp;
          <span className="landingPagePropertyTitleColored">
            Sadece {config.PRODUCT_NAME}'ta&nbsp;
          </span>
          Olan Bazı Özellikler
        </h4>
        <div className="landingPagePropertyItemsContainer">
          <div className="landingPagePropertyItemContainer">
            <div className="landingPagePropertyImageArea">
              <img
                alt="NoBody Club Koçluk Görüşmesi"
                src={`${process.env.PUBLIC_URL}/kocluk-gorusme.png`}
                className="landingPagePropertyImage"
              />
            </div>

            <div className="landingPagePropertyItemText">
              <h6 className="landingPagePropertyItemTitle">
                Koçluk Görüşmeleri
              </h6>
              <p className="landingPagePropertyItemDescription">
                Gerçek milyonerlere erişin ve sektörün devlerinden tüm
                sorularınıza yanıtlarınız alın. Aylık koçluk görüşmeleri sizin
                zirveye çıkmanız için en kestirme yöntem.
              </p>
            </div>
          </div>
          <div className="landingPagePropertyItemContainer">
            <div className="landingPagePropertyImageArea">
              <img
                alt="NoBody Club Koçluk Görüşmesi"
                src={`${process.env.PUBLIC_URL}/ayricalikli-topluluk.png`}
                className="landingPagePropertyImage"
              />
            </div>

            <div className="landingPagePropertyItemText">
              <h6 className="landingPagePropertyItemTitle">
                Ayrıcalıklı Topluluk
              </h6>
              <p className="landingPagePropertyItemDescription">
                Sizinle aynı yolculukta olan kulübümüzün seçkin üyeleri sizi
                daima daha yukarı itecek. Kulübün ilk kuralı sadece başarıyı
                hedeflemektir.
              </p>
            </div>
          </div>
          <div className="landingPagePropertyItemContainer">
            <div className="landingPagePropertyImageArea">
              <img
                alt="NoBody Club Koçluk Görüşmesi"
                src={`${process.env.PUBLIC_URL}/birebir-gorusme.png`}
                className="landingPagePropertyImage"
              />
            </div>

            <div className="landingPagePropertyItemText">
              <h6 className="landingPagePropertyItemTitle">
                1:1 Görüşme ve 7/24 Soru-Cevap
              </h6>
              <p className="landingPagePropertyItemDescription">
                Aklınıza takılan her soruyu 7/24 sorabileceksiniz. Çünkü sizin
                kişisel koçunuz tam olarak bunu sağlıyor. İşte Türkiye’ de bir
                ilk!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPageProperty;
