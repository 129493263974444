import React from "react";
import "../styles/landingpagewhathave.css";

function LandingPageWhatHave() {
  return (
    <div className="landingPageWhatHaveContainer">
      <div className="landingPageWhatHaveInner">
        <div className="landingPageWhatHaveLine">
          <img
            className="landingPageWhatHaveLinePhoto"
            src={`${process.env.PUBLIC_URL}/cizgi.png`}
          />
        </div>
        <h1 className="landingPageWhatHaveTitle">
          No<span className="landingPageWhatHaveTitleColored">Body Club</span>
          'ta Neler Var?
        </h1>
        <div className="landingPageWhatHaveItem first">
          <div className="landingPageWhatHaveItemText">
            <div className="landingPageWhatHaveItemTitle">
              Sürekli Büyüyen Program Portföyü
            </div>
            <p className="landingPageWhatHaveItemDescription">
              Size satış, ticaretin temel kuralları, kişisel marka inşaası ve
              çok daha fazlasını öğreten, sürekli genişleyen program portföyüne
              erişin.
            </p>
          </div>
          <div className="landingPageWhatHavePhotoArea">
            <img
              className="landingPageWhatHavePhoto"
              src={`${process.env.PUBLIC_URL}/program-portfoy.png`}
              alt="Program Portföy"
            />
          </div>
        </div>
        <div className="landingPageWhatHaveItem second">
          <div className="landingPageWhatHavePhotoArea second">
            <img
              className="landingPageWhatHavePhoto"
              src={`${process.env.PUBLIC_URL}/whatsapp-telegram.png`}
              alt="Özel Toplulluk"
            />
          </div>
          <div className="landingPageWhatHaveItemText">
            <div className="landingPageWhatHaveItemTitle">Özel Topluluk</div>
            <p className="landingPageWhatHaveItemDescription">
              Sizinle benzer düşüncelerde olan, gözünü zirveye dikmiş ve bedel
              ödemekten çekinmeyen Türkiye’nin en seçkin insanları ile tanışma
              fırsatı yakalayın.
            </p>
          </div>
        </div>
        <div className="landingPageWhatHaveItem">
          <div className="landingPageWhatHaveItemText">
            <div className="landingPageWhatHaveItemTitle">
              Sektör Uzmanları ve Gerçek Milyonerlerden Koçluk
            </div>
            <p className="landingPageWhatHaveItemDescription">
              NoBody Club’ta gerçek uzmanlara 7/24 sorularınızı sorup düzenli
              olarak yapılan canlı yayınlar sayesinde gelirinizi en üst düzeye
              çıkaracak temel becerilerde ustalaşın.
            </p>
          </div>
          <div className="landingPageWhatHavePhotoArea">
            <img
              className="landingPageWhatHavePhoto"
              src={`${process.env.PUBLIC_URL}/kocluk.png`}
              alt="Gerçek Koçluk"
            />
          </div>
        </div>

        <div className="landingPageProgramTitleInner">
          <h1 className="landingPageProgramTitle">
            Erişebileceğiniz{" "}
            <span className="landingPageProgramTitleColored">Programlar</span>
          </h1>
          <h6 className="landingPageProgramSubtitle">
            Size öğrettiklerini uygulayarak{" "}
            <span className="landingPageProgramSubtitleColored">
              milyonlarca lira{" "}
            </span>
            kazanan gerçek iş insanlarından öğrenin!
          </h6>
        </div>
      </div>
    </div>
  );
}

export default LandingPageWhatHave;
