import React from 'react'
import { useNavigate } from 'react-router-dom'
import '../styles/admin.css'

function Admin() {
  const navigate = useNavigate()

  const goToAdminPanel = () => {
    navigate('/admin/Home')
  }

  return (
    <div className='adminPageContainer'>
      <div className='adminPageInner'>
        <h1 className='welcomeAdminPageHeader'>
          <span className='adminPageHeaderColored'>Admin Panele&nbsp;</span>
          <span>Hoşgeldiniz</span>
        </h1>
        <h5 className='welcomeAdminPageSubtitle'>
          Panele giriş yap. Bütün fonksiyonlara erişim sahibi ol.
        </h5>
        <button onClick={goToAdminPanel} className='welcomeAdminPageButton'>
          Panele Giriş Yap
        </button>
      </div>
    </div>
  )
}

export default Admin
